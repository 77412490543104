import React from "react";

function EduItem(props) {
    let { title, description, link } = props || {};

    return (
        <div className="edu-item-container">
            <div className="edu-item-title">{title}</div>
            <div className="edu-item-description">{description}</div>
            <div className="edu-item-link"><a href={link} target="_blank" rel="noopener noreferrer">{link}</a></div>
        </div>
    );
}

export default EduItem;