const cs = {
    "Test": {
        "Lang": "Test jazyka"
    },
    "Logout": {
        "Link": "Odhlásit se"
    },
    "Login": {
        "Link": "Přihlásit",
        "LoginAction": "Načítání..."
    },
    "Settings": {
        "Link": "Nastavení",
        "Title": "Nastavení"
    },
    "Overview": {
        "Link": "Přehled",
        "Activity": {
            "Title": "Aktivity",
            "Tooltip": "Při nevyplnění bude použit kalorický výdej dle nastaveného způsobu života."
        },
        "Menu": {
            "Title": "Jídelníček"
        }
    },
    "Reports": {
        "Link": "Výživový poradce",
        "Title": "Výživový poradce",
        "Text": "Ve zvoleném období není zadáno žádné jídlo.",
        "Main": {
            "Title": "Podíl hlavních složek stravy",
            "Fat": "Tuky",
            "Carbohydrate": "Sacharidy",
            "Protein": "Bílkoviny",
            "Standard": "norma"
        },
        "All": {
            "Title": "Celkový přehled",
            "Energy": "Energie",
            "Fat": "Tuky",
            "Carbohydrate": "Sacharidy",
            "Protein": "Bílkoviny",
            "Fiber": "Vláknina",
            "Sodium": "Sůl",
            "Sfat": "Nasycené tuky",
            "Fluids": "Tekutiny",
            "Alcohol": "Alkohol",
            "Cholesterol": "Cholesterol",
            "Fruit": "Ovoce a zelenina",
            "Milk": "Mléčné produkty",
            "Fish": "Ryby",
            "Meal": "Počet jídel",
            "Mean": "Průměrný denní příjem",
            "Standard": "Denní norma",
            "Rate": "% denní normy"
        },
        "Summary": {
            "Title": "Hodnocení jídelníčku",
            "Info": "Jídelníček lze hodnotit ze tří aspektů: kvantitativního, kvalitativního a po stránce pravidelnosti (četnosti) stravování.",
            "Quantity": {
                "Title": "Po stránce kvantitativní",
                "Energy": {
                    "Prefix": "Průměrný <strong>energetický příjem</strong> Vašeho jídelníčku je {value}",
                    "0": ", což je méně, než je Váš doporučený kalorický příjem. S takovým kalorickým příjmem by mělo docházet k postupnému hubnutí, pokud tomu tak není, je někde chyba. Ta může být v nevhodné skladbě jídelníčku, ve špatném načasování či v nedostatečné pravidelnosti jednotlivých jídel, chyba může být samozřejmě i zápisu jednotlivých položek, nebo v nastavení parametrů pro výpočet doporučeného energetického výdeje.",
                    "1": ", což znamená, že Váš kalorický příjem víceméně odpovídá Vašemu doporučenému kalorickému příjmu. S takovým příjmem by měla být Vaše tělesná hmotnost bez větších výkyvů a prakticky vyrovnaná. Pokud tomu tak není, je někde chyba. Ta může být v nevhodné skladbě jídelníčku, ve špatném načasování či v nedostatečné pravidelnosti jednotlivých jídel, chyba může být samozřejmě i zápisu jednotlivých položek, nebo v nastavení parametrů pro výpočet doporučeného kalorického výdeje.",
                    "2": ", což je významně více, než je Váš doporučený kalorický příjem. S takovým kalorickým příjmem by měla být Vaše tělesná hmotnost postupně narůstat. Abyste tomu zabránili, bude zapotřebí snížit kalorický příjem nebo zvýšit energetický výdej."
                }
            },
            "Quality": {
                "Title": "Po stránce kvalitativní",
                "Fat": {
                    "Prefix": "Váš průměrný příjem <strong>tuků</strong> tvoří {value} z celkového kalorického příjmu",
                    "0": ", což je méně, než je doporučovaný příjem tuků. To samozřejmě ještě neznamená, že jíte špatně. Tradiční japonská strava obsahuje také méně než 20% tuků z celkového kalorického příjmu a je velmi zdravá, záleží na celkové skladbě a vyváženosti jednotlivých živin. Jedinci s nízkým příjmem tuků ve stravě mohou mít nicméně dlouhodobě nízký příjem vitaminů E a B12, případně i jiných vitaminů rozpustných v tucích, jako jsou vitaminy A, D a K, či některých důležitých stopových prvků, jako je například zinek. Váš příjem těchto látek (s výjimkou vitaminů K a zinku, které nebyly analyzovány) je uveden níže.",
                    "1": ", je tedy zcela v normě a podle všech odborných doporučení. Podstatné je ovšem také složení konzumovaných tuků, tedy kolik procent přijímáte ve formě tuků nasycených a nenasycených. Složení Vámi konzumovaných tuků je diskutováno níže.",
                    "2": ", což znamená, že Váš příjem tuků je hraniční. Pokud by většinu tuků z Vašeho dietního příjmu tvořily tuky rostlinné či rybí, vůbec nic se neděje, koneckonců tuky tvoří v typické Středozemní dietě až 40% z celkového kalorického příjmu. Složení Vámi konzumovaných tuků je diskutováno níže.",
                    "3": ", což znamená, že Váš příjem tuků je příliš vysoký. Čistě teoreticky, pokud by většinu tuků z Vašeho dietního příjmu tvořily tuky rostlinné či rybí, není takto vysoký příjem tuků na škodu (kromě nebezpečí vysoké kalorické hodnoty) - koneckonců tuky tvoří v typické Středozemní dietě až 40% z celkového kalorického příjmu. V České republice je tato možnost naprosto výjimečná. Složení Vámi konzumovaných tuků je diskutováno níže.",
                    "Summary": "Ke snížení příjmu tuků konzumujte sýry do 30% tuku v sušině, doporučujeme cottage, mozzarellu, tvarůžky, jogurty do 3% tuku, kefíry, acidofilní mléko, zakysanou smetanu (nízkotučnou) v menším množství jako doplněk. S výhodou je konzumovat mléčné výrobky s probiotickými kulturami. Omezte klasické máslo, nahraďte ho rostlinnými margariny, nejlépe s nízkým obsahem tuků (obsahují často jen 40% kalorií oproti běžnému máslu). Zařaďte do jídelníčku v menší míře také nepražené a nesolené oříšky (mandle, vlašské ořechy, kešu aj.) a semínka (lněná, konopná, slunečnicová, dýňová aj.).  Pokuste se nahradit vepřové a hovězí maso masem drůbežím a rybami, zkuste cca 2x týdně zavést „bezmasý den“. Používejte kvalitní rostlinné oleje s obsahem omega-3 mastných kyselin (např. lněný, či konopný olej), extra virgin olivové oleje, ale třeba i řepkový olej. Jako tepelnou úpravu používejte vaření, dušení, opékání bez tuku, grilování a pečení bez tuku. Kvalitní oleje přidávejte až do hotového jídla a dále tepelně neupravujte. Ryby by ve Vašem jídelníčku měly být zastoupeny alespoň 2x do týdne."
                },
                "Carbohydrat": {
                    "Prefix": "Váš průměrný příjem <strong>sacharidů</strong> tvoří {value} z celkového kalorického příjmu",
                    "0": ", což není dobře. Komplexní sacharidy (nikoli jednoduché, sladké cukry) by měly tvořit více než polovinu Vašeho kalorického příjmu. Nízký příjem komplexních sacharidů většinou souvisí s vysokým příjmem tuků a nízkým příjmem potravin a jídel rostlinného původu. Často bývá i nízký příjem vlákniny, minerálních látek, stopových prvků, některých vitaminů a dalších mikronutrientů. Příjem komplexních sacharidů lze zvýšit konzumací celozrnného pečiva a chleba (místo pečiva bílého), ovesných vloček, cereálií, neloupané rýže, semolinových a celozrnných těstovin, různých alternativních druhů obilovin (vločky, jáhly, pohanka, kuskus, bulgur, quinoa…), luštěnin a samozřejmě maximální konzumací zeleniny a ovoce. Důležité je se pokusit snížit příjem jednoduchých cukrů (pokud jejich konzumace není spojena s fyzickým výkonem), nedoslazovat nápoje, nepít slazené limonády, nekonzumovat zbytečně sladkosti, sušenky, dorty, čokolády a podobné pochutiny.",
                    "1": ", což je správně. Komplexní sacharidy (nikoli jednoduché cukry) mají tvořit více než polovinu Vašeho energetického příjmu. Důležitý je právě poměr komplexních a jednoduchých sacharidů. Důležité je vědět o nebezpečí jednoduchých cukrů (pokud jejich konzumace není spojena s fyzickým výkonem), nedoslazovat nápoje, nepít slazené limonády, nekonzumovat zbytečně sladkosti, sušenky, dorty, čokolády a podobné pochutiny."
                },
                "Protein": {
                    "Prefix": "Váš průměrný příjem <strong>bílkovin</strong> tvoří {value} z celkového kalorického příjmu",
                    "0": ", tedy méně, než je doporučováno. Je-li příjem bílkovin příliš nízký, mohou tělu dlouhodobě chybět některé důležité látky obsažené v potravinách bohatých na bílkoviny, jako jsou například vitaminy B, železo, zinek, vápník, nebo některé tzv. esenciální (pro člověka nezbytné) aminokyseliny, jejichž nedostatek může negativně ovlivňovat řadu funkcí.",
                    "1": ", což odpovídá doporučovaným hodnotám.",
                    "2": ", což je více, než je doporučeno. Takto vysoký příjem bývá většinou ruku v ruce s vysokým příjmem tuků, a to zejména živočišných. Vysoký příjem bílkovin tělo neumí využít a nadbytečných bílkovin se musí zbavovat. Tím dochází k zatěžování jater, ledvin i oběhového systému. Obecně je doporučováno zařazovat více rostlinné bílkoviny, a to ve formě luštěnin, obilovin a alternativních zdrojů – tofu, sójové maso a výrobky ze sóji (pomazánky, paštiky). Dbejte tedy na správný výběr zdroje bílkoviny – omezte uzeniny (jsou příliš tučné a solené) a vepřové i hovězí maso, preferujte spíše drůbež, ryby, zvěřinu. Vhodná je dušená šunka s vysokým obsahem bílkovin a sušená šunka (pršut)."
                },
                "Fiber": {
                    "Prefix": "Váš průměrný příjem <strong>vlákniny</strong> je {value} za den",
                    "0": ", což je méně než doporučovaných 25 g vlákniny za den. Nízký příjem vlákniny je asociován s vysokými hladinami cholesterolu v krvi, kardiovaskulárními onemocněními, některými typy nádorových nemocí, zejména rakoviny tlustého střeva. Vláknina je obsažena v buněčných stěnách rostlinných buněk, proto nejvíce vlákniny nacházíme v ovoci a zelenině (nejlépe v syrovém stavu) a také celozrnném pečivu a cereáliích. Hodně vlákniny obsahuje neloupaná rýže, ořechy (určitě to však není rada jíst solené arašídy), semena, luštěniny, ovesné vločky. Nutno také podotknout, že maso ani mléčné produkty žádnou vlákninu neobsahují. Naopak, nejvíce vlákniny obsahují pšeničné otruby (až 45 g/100 g), lněná semínka (38 g/100 g), sója (18 g/100 g) a celozrnné pečivo (8-10 g/100 g).",
                    "1": ", což odpovídá doporučovaným hodnotám. Chválíme Vás!"
                },
                "Sodium": {
                    "Prefix": "Váš průměrný příjem <strong>soli</strong> je {value}, což odpovídá {sodium} <strong>sodíku</strong> za den",
                    "0": ", což je méně, než je doporučovaný maximální příjem. Chválíme Vás!",
                    "1": ", což je více, než je doporučovaný maximální příjem. Příjem sodíku, tedy zejména kuchyňské soli, je v přímé úměře s riziky nemocí srdce a cév, s vysokým krevním tlakem a metabolickým syndromem. Většinu sodíku konzumujeme v potravinách nakoupených v samoobsluhách – okolo 65%, je proto nutné vybírat potraviny s nízkým obsahem sodíku. Nejvíce je ho v pečivu, mase, pizzách a těstovinách, sýrech, polévkách (zejména instantních) a různých fast-food pokrmech (sendviče, hamburgery…). Je třeba podotknout, že tato norma je velmi přísná, nezohledňuje celkový kalorický příjem (norma je stejná pro 50 kilovou ženu i pro 100 kilového muže) ani zvýšené ztráty soli pocením (nabývá na významu u sportovců)."
                },
                "SatFat": {
                    "Prefix": "Váš příjem <strong>nasycených tuků</strong> je {value} z celkového kalorického příjmu",
                    "0": ", což je výborné. Chválíme Vás!",
                    "1": ", což je více, než je doporučováno lékařskými autoritami. Vysoký příjem nasycených tuků je spojen s vysokým příjmem tuků obecně, zejména pak s vysokým příjmem tuků živočišných. Čím vyšší příjem je nasycených tuků, tím vyšší jsou rizika nemocí srdce a cév a mnohých dalších onemocnění, včetně onemocnění nádorových. Řešen, jak příjem nasycených tuků snížit, jsou uvedena v hodnocení poměru živočišných a rostlinných tuků."
                },
                "Water": {
                    "Prefix": "Váš průměrný příjem <strong>tekutin</strong> je {value} za den",
                    "0": ", což není dobré. Dlouhodobý nízký příjem tekutin může poškozovat ledviny, bývá sdružen s vyšším rizikem močových infekcí, často se objevuje zácpa, mohou být poruchy pozornosti, paměti, zhoršuje se fyzická výkonnost.",
                    "1": ", což je dobré. Toto doporučení berte jen jako orientační, záleží velmi na klimatických podmínkách a fyzické aktivitě. Tekutiny by měly být přijímány ve formě (neperlivé) neochucené vody, neslazených čajů, káva bez cukru (může být s mlékem, ne však plnotučným). Nevhodné jsou slazené minerálky, energetické nápoje, sladké limonády, džusy, které obsahují velké množství jednoduchých sacharidů (pokud však jejich konzumace není spojena s intenzivnější sportovní aktivitou). Z alkoholických nápojů je nejvhodnější bílé nebo červené víno, vinný střik, ovšem opět pozor i na to, že alkohol je energeticky velmi bohatý a také pozor na doporučená maximální množství alkoholu za den (viz příjem alkoholu)."
                },
                "Alcohol": {
                    "Prefix": "Váš průměrný příjem <strong>alkoholu</strong> je {value} za den",
                    "0": ", což je tolerovatelný příjem. Existují dokonce údaje, které říkají, že pravidelná, mírná konzumace alkoholu (odpovídající zhruba 2 deci vína či jednomu velkému pivu) má dokonce ochranný účinek na kardiovaskulární aparát. Je třeba si nicméně uvědomit, že alkohol má poměrně vysokou kalorickou hodnotu (jedno 12° pivo představuje cca 1 000 kJ využitelné energie). Také je třeba zdůraznit, že nárazové pití alkoholu (víkendoví pijáci, v angličtině označovaní jako binge drinkers) je také nezdravé, ač průměrná konzumace alkoholu přepočtená do celého týdne nemusí být nadměrná.",
                    "1": ", což je nezdravé. Nadměrná konzumace alkoholu má řadu nepříznivých dopadů na lidské tělo, poškozením jater počínaje přes poruchy mozkové činnosti, kardiovaskulární onemocnění, ale i zvýšení rizik vzniku nádorových onemocnění. Je třeba také zdůraznit, že alkohol má poměrně vysokou kalorickou hodnotu (jedno 12° pivo představuje cca 1 000 kJ využitelné energie) a při velké konzumaci alkoholu může přísun kalorií z tohoto zdroje enormně narušovat energetickou bilanci a vést k přibývání na váze."
                },
                "Fruit": {
                    "Prefix": "Váš příjem <strong>ovoce a zeleniny</strong> je {value} za den (1 kus = 80 g)",
                    "0": ", což je málo. Pět kusů ovoce nebo zeleniny za den je minimum doporučované odbornými autoritami, zejména k pokrytí požadovaného příjmu vlákniny, minerálních látek, stopových prvků, některých vitaminů a dalších mikronutrientů. Dostatečným příjmem vlákniny se samozřejmě pozitivně mění i většina dalších posuzovaných parametrů: příjem tuků i sacharidů, poměr rostlinných i živočišných tuků, příjem nasycených tuků i cholesterolu…",
                    "1": ", což je výborné. Chválíme Vás!"
                },
                "Milk": {
                    "Prefix": "Váš příjem <strong>mléčných produktů</strong> je {value} za den (1 kus = 250 ml mléka / 40 g sýra / 200 g jogurtu)",
                    "0": ", což je málo. Mléko a mléčné výrobky jsou zejména důležitým zdrojem vápníku. Konzumovány by měly být mléčné výrobky s nižším obsahem tuků (např. jogurty do 3% tuku, maximálně polotučné mléko, sýry s nízkým obsahem tuku v sušině (do 30%).",
                    "1": ", což je správně. Mléko a mléčné výrobky jsou zejména důležitým zdrojem vápníku."
                },
                "Fish": {
                    "Prefix": "Váš příjem <strong>ryb a rybích jídel</strong> je {value} za týden (1 kus = 100 g)",
                    "0": ", což je málo. Ryby jsou důležitým zdrojem zejména polynenasycených mastných kyselin (rybí tuk), které jsou zdravé a pro člověka velmi prospěšné. Jejich konzumací lze nahradit, alespoň částečně, příjem červeného masa, a omezit tak příjem nasycených tuků a cholesterolu.",
                    "1": ", což je správně. Ryby jsou důležitým zdrojem zejména polynenasycených mastných kyselin (rybí tuk), které jsou zdravé a pro člověka velmi prospěšné."
                }
            },
            "Regular": {
                "Title": "Pravidelnost stravování",
                "Text": "Jíst byste měli pravidelně, alespoň 5x denně. Nevynechávejte snídaně, dopolední ani odpolední svačiny, které by měly být tvořeny zejména ovocem nebo zeleninou v kombinaci s mléčným výrobkem nebo cereální tyčinkou/knackerbrotem tak, aby mohla být splněna podmínka konzumace alespoň 5 kusů ovoce nebo zeleniny za den a 2 mléčných výrobků za den. Pravidelnost stravování a konzumace jídla alespoň 5x denně je stejně důležitá, jako jsou kalorická hodnota a složení Vašeho jídelníčku."
            }
        },
        "Loading": "Data se načítají...",
        "Period": {
            "Label": "Hodnocené období",
            "Between": "až"
        },
        "User": "Uživatel",
        "Export": "Stáhnout report",
        "ExportExt": "Stáhnout rozšířený report",
        "ExportSport": "Stáhnout sportovní report",
        "Ext": "rozšířený",
        "Sport": "sportovní",
        "Report": "report",
        "Daily": "Stáhnout denní přehled",
        "DailyReport": "denní přehled"
    },
    "DataList": {
        "Time": "Doba",
        "Energy": "Energie",
        "Amount": "Množství",
        "Add": "Přidat",
        "AddActivity": "Zadat aktivitu",
        "AddFood": "Zadat jídlo",
        "Text": "Text",
        "TextPlaceholder": "Zadej text",
        "Activity": "Aktivita"
    },
    "Btn": {
        "Save": "Uložit",
        "Cancel": "Zrušit",
        "Delete": "Smazat",
        "Close": "Zavřít"
    },
    "EnergyProgressBar": {
        "Lack": "Nedostatek",
        "Surplus": "Přebytek",
        "TotalInput": "Celkový příjem energie",
        "TotalOutput": "Celkový výdej energie",
        "TodayInput": "Dosavadní příjem energie",
        "TodayOutput": "Dosavadní výdej energie",
        "BasalOutput": "Z toho bazální metabolický výdej",
        "LifeStyleOutput": "Z toho výdej nastaveným způsobem života",
        "TipNote": "Oranžová ryska uprostřed určuje hodnotu rovnovážné energetické bilance.",
        "PlanTipDown": "Zelená ryska určuje bilanci -2 770 kJ za celý den, pro maximální doporučené hubnutí 0,5 kg za týden. Vlastní plán si můžete zvolit v nastavení.",
        "PlanTipUp": "Zelená ryska určuje bilanci {value} kJ za celý den, pro dosažení cílové váhy k požadovanému datu. Pro korekci plánu prosím pravidelně aktualizujte svoji váhu v nastavení.",
        "Title": "Energetická bilance"
    },
    "EnergyOutput": {
        "Title": "Aktivita",
        "TimeFrom": "Čas zahájení",
        "Duration": "Doba [min]",
        "Category": "Kategorie",
        "Energy": "Energie",
        "Err": {
            "ActivityRequired": "Název je povinný.",
            "DurationRequired": "Doba je povinná."
        },
        "Activity": "Název",
        "DeleteConfirm": "Opravdu chcete smazat vybranou aktivitu?",
        "DeleteSuccess": "Aktivita byla úspěšně smazána."
    },
    "EnergyInput": {
        "Title": "Jídlo",
        "FoodItem": "Název",
        "FoodType": "Část dne",
        "FoodCategory": "Kategorie",
        "Favorite": "Oblíbené",
        "Other": "Ostatní",
        "Amount": "Množství",
        "Energy": "Energie",
        "Err": {
            "AmountRequired": "Množství je povinné.",
            "FoodRequired": "Název je povinný."
        },
        "DeleteConfirm": "Opravdu chcete smazat vybrané jídlo?",
        "DeleteSuccess": "Jídlo bylo úspěšně smazáno.",
        "HealthPostfix": "jídlo"
    },
    "FoodCategory": {
        "All": "Všechny",
        "Favorite": "Oblíbené",
        "Other": "Ostatní"
    },
    "FoodType": {
        "Placeholder": "Neuvedeno"
    },
    "Err": {
        "System": "Neočekávaná chyba. Kontaktujte prosím správce systému.",
        "NoWeight": "Nejspíš nemáte nastavenou váhu v profilu uživatele."
    },
    "ActivityCategory": {
        "All": "Všechny"
    },
    "DateNavigator": {
        "Today": "Dnes",
        "TodayPrefix": "Dnes v "
    },
    "ElementGraph": {
        "Fat": "Tuky",
        "Carbohydrate": "Sacharidy",
        "Protein": "Bílkoviny",
        "Fiber": "Vláknina",
        "Sodium": "Sůl",
        "SatFat": "Nasycené tuky",
        "FatTip": "Celkem přijato: {value} g ({rate}%)\r\nCelkový denní příjem energie z tuků by měl tvořit maximálně {gh}% z celkového kalorického příjmu.",
        "CarbohydrateTip": "Celkem přijato: {value} g ({rate}%)\r\nCelkový denní příjem energie ze sacharidů by měl tvořit {gl} - {gh}% z celkového kalorického příjmu.",
        "ProteinTip": "Celkem přijato: {value} g ({rate}%)\r\nCelkový denní příjem energie z bílkovin by měl tvořit {gl} - {gh}% z celkového kalorického příjmu.",
        "FiberTip": "Celkem přijato: {value} g ({rate}%)\r\nCelkový denní příjem vlákniny by měl tvořit minimálně 25 g denně.",
        "SodiumTip": "Celkem přijato: {value} mg ({rate}%)\r\nCelkový denní příjem sodíku by měl tvořit maximálně 2300 mg denně.",
        "SatFatTip": "Celkem přijato: {value} g ({rate}%)\r\nCelkový denní příjem nasycených mastných kyselin by měl tvořit maximálně 7% z celkového kalorického příjmu.",
        "RateTitle": "Poměr živin",
        "AmountTitle": "Množství živin"
    },
    "Profile": {
        "Loading": "Načítání profilu uživatele...",
        "Link": "Profil",
        "Title": "Profil",
        "Save": "Uložit",
        "Username": "Jméno",
        "Phone": "Tel. číslo",
        "InvalidPhone": "Tel. číslo není validní telefonní číslo.",
        "Updated": "Váš profil byl upraven",
        "Weight": "Váha [kg]",
        "WeightTooltip": "Informace o Vás slouží k výraznému zpřesnění výpočtu energetického výdeje.",
        "Height": "Výška [cm]",
        "Year": "Rok narození",
        "Gender": {
            "Label": "Pohlaví",
            "1": "Muž",
            "2": "Žena"
        },
        "UpdateError": "Neočekávaná chyba při pokusu o aktualizaci profilu uživatele.",
        "Plan": {
            "Label": "Můj plán",
            "Tooltip": "Maximální doporučené hubnutí či přibírání je 0,5 kg za týden. Hranice této energetické bilance bude zobrazena v přehledu.",
            "1": "Zhubnout",
            "2": "Přibrat"
        },
        "LifeStyle": {
            "Label": "Způsob života",
            "NoneTooltip": "Nedefinován - výdej energie bude počítán pouze z bazálního metabolismu a ručně zadaných aktivit",
            "1": "Sedavý",
            "SedentaryTooltip": "Sedavý - sedavé zaměstnání nebo méně než 1 hodina sportu za týden",
            "2": "Mírně aktivní",
            "LowActiveTooltip": "Mírně aktivní - občasný pohyb v zaměstnání nebo 1 až 3,5 hod sportu za týden",
            "3": "Aktivní",
            "ActiveTooltip": "Aktivní - aktivní pohyb v zaměstnání nebo 3,5 až 7 hod sportu za týden",
            "4": "Velmi aktivní",
            "VeryActiveTooltip": "Velmi aktivní - namáhavé zaměstnání nebo více než 7 hodin sportu za týden"
        },
        "Basal": {
            "Label": "Bazální metabolismus",
            "1": "Vypočítat dle zadaných údajů",
            "2": "Použít naměřenou hodnotu"
        },
        "BasalTooltip": "Neboli bazální metabolický výdej (BMR) je energie vydaná pouze fungováním životně důležitých orgánů, např. srdce, plíce, mozek.",
        "BasalValue": "Hodnota bazálního metabolismu [kJ]",
        "Err": {
            "WeightInvalid": "Neplatná hodnota",
            "HeightInvalid": "Neplatná hodnota",
            "BirthInvalid": "Neplatná hodnota",
            "BasalRequired": "Hodnota je povinná",
            "BasalInvalid": "Neplatná hodnota"
        },
        "Email": "E-mail"
    },
    "Edu": {
        "Link": "Vzdělávání",
        "Title": "Doporučené stránky",
        "Sportv": {
            "Title": "Sportvital-Pro",
            "Description": "Komplexní zdroj informací o zdravé výživě, sportu a zdravém životním stylu."
        },
        "Mze": {
            "Title": "Ministerstvo zemědělství – potraviny",
            "Description": "Webový portál Ministerstva zemědělství České republiky týkající se kvality potravin."
        },
        "Bezpo": {
            "Title": "Informační centrum bezpečnosti potravin",
            "Description": ""
        },
        "Cojis": {
            "Title": "Víš, co jíš?",
            "Description": "Informace o výživě a onemocněních z potravin."
        },
        "Nutrib": {
            "Title": "Nutribaze.cz",
            "Description": "Databáze složení potravin České republiky."
        },
        "Spolpv": {
            "Title": "Společnost pro výživu",
            "Description": ""
        },
        "Usda": {
            "Title": "USDA FoodData Central",
            "Description": "Online databáze potravin s kompletním složením poskytovaná americkým Ministerstvem zemědělství."
        },
        "Pubmed": {
            "Title": "Pubmed",
            "Description": "Databáze americké Národní lékařské knihovny s více než 30 milionů odborných článku z oblasti biomedicíny."
        },
        "Recomend": {
            "Title": "Doporučené dokumenty"
        },
        "FoodFeature": {
            "Title": "Potraviny splňující vlastnosti",
            "Feature": "Vlastnosti",
            "Download": "Stáhnout seznam"
        },
        "MenuDesign": {
            "Title": "Návrh jídelníčku",
            "Feature": "Vlastnosti",
            "Btn": "Stáhnout seznam",
            "Suitable": "Pro režim",
            "ExcludeFeature": "Nežádoucí vlastnosti"
        }
    },
    "Logo": {
        "Text": "Projekt SW aplikace Food4Fit byl podpořen programem Podpora NNO v působnosti MZe ČR"
    },
    "Selectbox": {
        "NoOptions": "Žádné možnosti",
        "NotFound": "Nenalezeno"
    },
    "Plan": {
        "Link": "Plánování",
        "Title": "Plánování",
        "LastWeightDate": "Datum poslední aktualizace váhy",
        "CurrentWeight": "Aktuální váha [kg]",
        "PlanWeight": "Cílová váha [kg]",
        "PlanDate": "Požadovaný datum dosažení cílové váhy",
        "EnergyBalance": "Doporučená hodnota denní energetické bilance pro dosažení cílové váhy",
        "Err": {
            "EnergyBadMinus": "Cílová váha a požadovaný datum dosažení neodpovídají pravidlům hubnutí. Pro maximální doporučené hubnutí 0,5 kg za týden je hranice denní energetické bilance -2 770 kJ.",
            "CurWeightRequired": "Aktuální váha je povinná.",
            "PlanWeightRequired": "Cílová váha je povinná.",
            "PlanDateRequired": "Datum cílové váhy je povinný.",
            "InvalidDate": "Požadovaný datum dosažení cílové váhy není platný."
        },
        "Save": "Přepočítat k dnešnímu dni"
    },
    "Password": {
        "Link": "Heslo",
        "Title": "Heslo",
        "TitleNew": "Nové heslo",
        "TitleChange": "Změna hesla",
        "CurrentPwd": "Současné heslo",
        "NewPwd": "Nové heslo",
        "ConfirmPwd": "Potvrdit nové heslo",
        "Btn": "Změnit heslo",
        "BtnSet": "Nastavit heslo",
        "Err": {
            "PasswordMismatch": "Neplatné současné heslo.",
            "CurrentPwdRequired": "Současné heslo je povinné.",
            "NewPwdRequired": "Nové heslo je povinné.",
            "NewPwdInvalid": "Nové heslo musí být dlouhé nejmíň {min} a maximálně {max} znaků.",
            "ConfirmPwdInvalid": "Nové heslo a potvrzení nového hesla se neshodují.",
            "InvalidPwd": "Neplatné nové heslo.",
            "Load": "Nepovedlo se načíst informace o hesle."
        },
        "Changed": "Vaše heslo bylo změněno.",
        "Set": "Heslo bylo nastaveno.",
        "Loading": "Načítají se data...",
        "NewInfo": "Pro tuto stránku nemáte místní uživatelské jméno / heslo. Přidejte místní účet, abyste se mohli přihlásit bez externího přihlášení."
    },
    "ExtLogin": {
        "Link": "Externí účty",
        "Title": "Externí účty",
        "Another": "Přidat další účet k přihlášení.",
        "Remove": "Odebrat",
        "Removed": "Externí účet byl odebrán.",
        "Added": "Externí účet byl přidán.",
        "Err": {
            "DuplicateUserName": "Uživatelské jméno {username} je již použito.",
            "LoadLogins": "Nepovedlo se načíst seznam externích účtů.",
            "RemoveFail": "Odebrání externího účtu se nepodařilo."
        },
        "NoInfo": "Chyba při načítání externích přihlašovacích údajů.",
        "Add": "Přidat"
    },
    "Personal": {
        "Link": "Správa údajů",
        "Title": "Správa údajů",
        "Text1": "Váš účet obsahuje osobní údaje, které jste nám poskytli. Tato stránka vám umožňuje tato data odstranit.",
        "Text2": "Smazáním těchto údajů trvale odstraníte váš účet, který nelze obnovit.",
        "Download": "Stáhnout",
        "Delete": "Smazat",
        "DeleteSuccess": "Účet byl úspěšně smazán."
    },
    "Gender": {
        "MaleShort": "M",
        "FemaleShort": "Ž"
    },
    "Unit": {
        "Pcs": "ks",
        "Week": "týden"
    },
    "Admin": {
        "Link": "Administrace",
        "Title": "Administrace"
    },
    "ActivityAdmin": {
        "Link": "Aktivity",
        "Title": "Aktivity",
        "Name": "Název",
        "Deleted": "Smazáno",
        "ActivityDetail": "Aktivita",
        "Energy50": "Energie 50kg [kcal/min]",
        "Energy71": "Energie 71kg [kcal/min]",
        "Energy92": "Energie 92kg [kcal/min]"
    },
    "FoodAdmin": {
        "Link": "Jídla",
        "Title": "Jídla",
        "Name": "Název",
        "Category": "Kategorie",
        "Energy": "Energie [kJ/100 g]",
        "Health": "Index zdraví",
        "FoodDetail": "Jídlo",
        "Unit": "Jednotka",
        "Note": "Poznámka",
        "Params": "Složení",
        "Feature": "Vlastnosti",
        "Export": "Export jídel",
        "Suitable": "Vhodné při režimu",
        "FoodType": "Druh jídla"
    },
    "RecipeAdmin": {
        "Link": "Recepty",
        "Title": "Recepty",
        "Name": "Název",
        "Duration": "Doba přípravy",
        "Difficulty": "Náročnost",
        "RecipeDetail": "Recept",
        "Servings": "Počet porcí",
        "Energy": "Energie",
        "Weight": "Váha",
        "Preparation": "Postup přípravy",
        "Items": "Ingredience",
        "Photo": "Fotografie"
    },
    "RuleAdmin": {
        "Link": "Normy",
        "Title": "Normy"
    },
    "UserAdmin": {
        "Link": "Uživatelé",
        "Title": "Uživatelé",
        "Email": "E-mail",
        "Role": "Role",
        "RoleUser": "Uživatel",
        "RoleAdmin": "Admin",
        "UserDetail": "Uživatel",
        "Count": "Počet: {shared} / {count}",
        "Name": "Jméno"
    },
    "Global": {
        "No": "Ne",
        "Yes": "Ano"
    },
    "HealthIndex": {
        "0": "Normální",
        "1": "Nezdravé",
        "2": "Zdravé"
    },
    "Difficulty": {
        "0": "Neznámá",
        "1": "Velmi jednoduché",
        "2": "Jednoduché",
        "3": "Normální",
        "4": "Složité",
        "5": "Velmi složité"
    },
    "Share": {
        "Title": "Sdílení přístupu",
        "Link": "Sdílení přístupu",
        "Text": "Pokud chcete například svému výživovému poradci umožnit přístup k Vašemu uživatelskému účtu a zadaným datům, zadejte prosím níže e-mail, pomocí kterého je registrován v aplikaci Food4Fit.",
        "Email": "E-mail uživatele",
        "SaveSuccess": "Nastavení uloženo."
    },
    "ReportAdmin": {
        "Link": "Reporty",
        "Name": "Název",
        "Title": "Textace"
    },
    "ReportType": {
        "0": "Základní",
        "1": "Rozšířený",
        "2": "Sportovní"
    },
    "Feature": {
        "Title": "Číselník vlastností",
        "RemoveConfirm": "Opravdu chcete smazat vlastnost? Dojde také k odstranění vlastnosti ze všech jídel.",
        "Composition": "Klíčové složení"
    },
    "Select": {
        "Placeholder": "Vyberte..."
    },
    "CopyPostfix": " - kopie",
    "Suitable": {
        "Title": "Číselník režimů",
        "RemoveConfirm": "Opravdu chcete smazat režim? Dojde také k odstranění režimu ze všech jídel."
    },
    "FoodTypes": {
        "o": "Ostatní",
        "b": "Snídaně",
        "s": "Svačina",
        "l": "Hlavní chod",
        "d": "Pozdní večeře"
    }
}

export default cs;