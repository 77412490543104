import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AppContext } from '../../contexts/AppContext';

import './dayOverview.scss';


class DayOverview extends Component {

    static propTypes = {
        data: PropTypes.array,
        onClick: PropTypes.func
    }

    getItems(data) {
        data = data || [];
        let result = [
            { time: '08:00', large: true },
            { time: '10:00', large: false },
            { time: '12:00', large: true },
            { time: '15:00', large: false },
            { time: '18:00', large: true }
        ];
        result = result.map((o, i) => ({ ...o, ...(data[i] || { value: false }) }));
        return result;
    }

    render() {
        let { language } = this.context;
        let { data } = this.props;

        let items = this.getItems(data);

        return (
            <div className="day-overview-container f4f-panel">
                <div className="label-container">
                    {items.map((o, i) => (
                        <div key={i} className="label-item">
                            <i className="fa fa-clock-o" aria-hidden="true"></i>
                            <div className="label">{o.time}</div>
                        </div>
                    ))}
                </div>
                <div className="line-container">
                    <div className="line"></div>
                    {items.map((o, i) => (
                        <div key={i} className={classnames('label-item', { large: o.large, ok: o.value })}>
                            <div
                                className="label-item-content"
                                onClick={e => { if (this.props.onClick) this.props.onClick(e, i + 1); }}
                            >
                                <div className="label-item-value">
                                    <div className="value">
                                        <i className={classnames('fa', { 'fa-exclamation': !o.value, 'fa-check': o.value })} aria-hidden="true"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="energy-line-container">
                    {items.map((o, i) => (
                        <div key={i} className="energy-item">
                            {!!o.energy &&
                                <div className="value">{`${Math.round(o.energy).toLocaleString(language)} kJ`}</div>
                            }
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

DayOverview.contextType = AppContext;

export default DayOverview;