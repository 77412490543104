import * as toastr from 'toastr';
import React, { Component } from 'react';
import { Button, Form, FormGroup, Input, Label } from 'reactstrap';

import api from '../../utils/api';
import { isApiError } from '../../utils/error';
import { AppContext } from '../../contexts/AppContext';


export default class Share extends Component {

    static contextType = AppContext;

    constructor() {
        super();

        this.state = {
            data: {},
            email: '',
            errors: {}
        }

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadSettings();
    }

    async loadSettings() {
        let { translate } = this.context;

        try {
            let response = await api.getShareSettings();
            let data = response.data || {};
            this.applyData(data);
        } catch (error) {
            if (!isApiError(error)) {
                console.log(error);
            }
            toastr.error(translate('Err.System'));
        }
    }

    applyData(data) {
        let { email } = data;

        this.setState({
            data,
            email: email || ''
        });
    }

    validate() {
        //let { email } = this.state;

        let errors = {};

        let result = !Object.keys(errors).length;

        this.setState({ errors });
        return result;
    }

    validateIfError() {
        if (this.state.errors) {
            this.validate();
        }
    }

    async save(e) {
        e.preventDefault();
        if (this.validate()) {
            let { email } = this.state;
            let { translate } = this.context;

            try {
                let requestData = {
                    email: email || null
                };
                await api.setShareSettings(requestData);
                toastr.success(translate('Share.SaveSuccess'));
            } catch (error) {
                if (!isApiError(error)) {
                    console.log(error);
                }
                toastr.error(translate('Err.System'));
            }
        }
    }

    render() {
        let { translate } = this.context;
        let { email } = this.state;

        return (
            <div className="share-settings-container">
                <div className="panel-header">{translate('Share.Title')}</div>
                <Form onSubmit={this.save}>
                    <p>{translate('Share.Text')}</p>
                    <FormGroup>
                        <Label>{translate('Share.Email')}</Label>
                        <Input
                            value={email}
                            onChange={e => this.setState({ email: e.target.value }, () => this.validateIfError())}
                            autoFocus
                        />
                    </FormGroup>
                    <div className="buttons">
                        <Button color="primary" className="wide" onClick={this.save}>{translate('Btn.Save')}</Button>
                    </div>
                </Form>
            </div>
        );
    }
}
