export function defaultStyle(state = {}) {
    let result = {
        control: (cur, st) => {
            let result = { ...cur };
            if (state.isInvalid) {
                result.borderColor = '#dc3545';
                result['&:hover'] = {
                    borderColor: '#dc3545'
                }
                if (st.isFocused) {
                    result.boxShadow = '0 0 0 0.2rem #dc354540';
                }
            } else if (st.isFocused) {
                result.borderColor = '#557319';
                result.boxShadow = '0 0 0 0.2rem #55731980';

                result['&:hover'] = {
                    borderColor: '#557319'
                }
            }
            return result;
        }
    };
    return result;
}

export const categoryStyle = {
    ...defaultStyle(),
    placeholder: (cur) => ({
        ...cur,
        color: '#6c757d'
    })
}

export const transparentStyle = {
    control: (cur, st) => {
        let result = {
            ...cur,
            borderWidth: '0px',
            backgroundColor: 'transparent',
            fontSize: '16px',
            fontWeight: '600',
            cursor: 'pointer',
            boxShadow: 'none',
            minHeight: 'unset'
        };
        return result;
    }
}