import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Link, Route } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';

import Plan from './Plan';
import { AppContext } from '../../contexts/AppContext';

import './settings.scss';
import Profile from './Profile';
import Password from './Password';
import Personal from './Personal';
import External from './External';
import Share from './Share';


export const PlanPath = '/settings/plan';
export const ProfilePath = '/settings/profile';
export const PasswordPath = '/settings/password';
export const PersonalPath = '/settings/personal';
export const ExternalPath = '/settings/external';
export const SharePath = '/settings/share';

class Settings extends Component {

    static propsTypes = {
        selectedShare: PropTypes.object
    }

    render() {
        let { translate } = this.context;
        let { selectedShare } = this.props;
        let { pathname } = window.location;

        let showNotShared = !selectedShare || !selectedShare.id;

        return (
            <div className="section-container settings-container">
                <div className="section-menu">
                    <div className="panel-header">{translate('Settings.Title')}</div>
                    <ul className="nav nav-pills flex-column">
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === ProfilePath })}
                                to={ProfilePath}
                            >{translate('Profile.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === PlanPath })}
                                to={PlanPath}
                            >{translate('Plan.Link')}</NavLink>
                        </NavItem>
                        {showNotShared &&
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className={classnames({ active: pathname === PasswordPath })}
                                    to={PasswordPath}
                                >{translate('Password.Link')}</NavLink>
                            </NavItem>
                        }
                        {showNotShared &&
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className={classnames({ active: pathname === SharePath })}
                                    to={SharePath}
                                >{translate('Share.Link')}</NavLink>
                            </NavItem>
                        }
                        {showNotShared &&
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className={classnames({ active: pathname === ExternalPath })}
                                    to={ExternalPath}
                                >{translate('ExtLogin.Link')}</NavLink>
                            </NavItem>
                        }
                        {showNotShared &&
                            <NavItem>
                                <NavLink
                                    tag={Link}
                                    className={classnames({ active: pathname === PersonalPath })}
                                    to={PersonalPath}
                                >{translate('Personal.Link')}</NavLink>
                            </NavItem>
                        }
                    </ul>
                </div>
                <div className="section-content">
                    <Route path={PlanPath}>
                        <Plan selectedShare={selectedShare} />
                    </Route>
                    <Route path={ProfilePath}>
                        <Profile selectedShare={selectedShare} />
                    </Route>
                    <Route path={PasswordPath} component={Password} />
                    <Route path={SharePath} component={Share} />
                    <Route path={PersonalPath} component={Personal} />
                    <Route path={ExternalPath} component={External} />
                </div>
            </div >
        );
    }
}

Settings.contextType = AppContext;

export default Settings;