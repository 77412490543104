import * as toastr from 'toastr';
import React, { Component } from 'react';

import api from '../../utils/api';
import SimpleTab from '../SimpleTab/SimpleTab';
import { AppContext } from '../../contexts/AppContext';
import ReportDetail from './ReportDetail';


export default class ReportAdmin extends Component {

    static contextType = AppContext;

    constructor() {
        super();
        this.state = {
            names: [],
            detail: null
        }

        this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        this.loadNames();
    }

    async loadNames() {
        try {
            let response = await api.getReportTextList();
            let names = (response.data || []);
            this.setState({ names });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    onEdit(item) {
        let detail = { ...item, ts: new Date().getTime() };
        this.setState({ detail });
    }

    render() {
        let { translate } = this.context;
        let { names, detail } = this.state;

        let showDetail = !!detail;

        return (
            <div className="report-admin">
                <div className="panel-header">{translate('RecipeAdmin.Title')}</div>
                <SimpleTab
                    cols={[
                        { key: 'name', name: translate('ReportAdmin.Name'), style: { width: '100%' } }
                    ]}
                    data={names}
                    onEdit={this.onEdit}
                />

                {showDetail &&
                    <ReportDetail
                        key={detail.ts}
                        data={detail}
                        onClose={() => this.setState({ detail: null })}
                        onSubmit={() => {
                            this.loadNames()
                            this.setState({ detail: null });
                        }}
                    />
                }
            </div>
        );
    }
}
