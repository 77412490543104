import * as toastr from 'toastr';
import api from '../../utils/api';
import React, { Component } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Button, Form } from 'reactstrap';
import { ExternalPath } from './Settings';
import { ERR_EXT_LOGIN_NOT_REMOVED, isError } from '../../utils/error';


class External extends Component {

    constructor() {
        super();

        this.state = {
            data: null,
            loaded: false
        }
    }

    componentDidMount() {
        this.loadExternals();
    }

    async loadExternals() {
        let { translate } = this.context;

        try {
            let response = await api.getExternalLogins();
            this.setState({ loaded: true, data: response.data });
        } catch (error) {
            console.log(error);
            toastr.error(translate('ExtLogin.Err.LoadLogins'));
        }
    }

    async remove(login) {
        let { translate } = this.context;
        let { id, key } = login;

        try {
            let data = {
                id,
                key
            };
            await api.removeExternalLogin(data);
            toastr.success(translate('ExtLogin.Removed'));
            this.loadExternals();
        } catch (error) {
            if (isError(error, ERR_EXT_LOGIN_NOT_REMOVED)) {
                toastr.warning(translate('ExtLogin.Err.RemoveFail'));
            } else {
                console.log(error);
                toastr.error(translate('Err.System'));
            }
        }
    }

    render() {
        let { translate } = this.context;
        let { data, loaded } = this.state;

        let { hasPassword, logins } = data || {};
        let canRemove = hasPassword || (logins || []).filter(o => o.key).length > 1;

        return (
            <div className="external-settings">
                <div className="panel-header">{translate('ExtLogin.Title')}</div>
                {loaded && (logins || []).map((login, i) => {
                    return (
                        <div key={i} className="external-login">
                            <div className="external-login-name">{login.name}</div>
                            <div className="external-login-action">
                                {login.key ? (
                                    <Button color="danger" onClick={() => this.remove(login)} disabled={!canRemove}>{translate('ExtLogin.Remove')}</Button>
                                ) : (
                                        <Form method="get" action={`/Identity/Account/Manage/ExternalLogins`}>
                                            <input type="hidden" name="handler" value="LinkLoginDirect" />
                                            <input type="hidden" name="provider" value={login.id} />
                                            <input type="hidden" name="returnUrl" value={ExternalPath} />
                                            < Button color="primary">{translate('ExtLogin.Add')}</Button>
                                        </Form>
                                    )}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }
}

External.contextType = AppContext;

export default External;
