import classNames from 'classnames';
import _ from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import * as toastr from 'toastr';

import { AppContext } from '../../contexts/AppContext';
import apiService from '../../utils/api';
import { toOption } from '../../utils/options';
import { transparentStyle } from '../../utils/style';


const MenuDesign = ({ featureOptions }) => {

    const { translate } = useContext(AppContext);

    const [features, setFeatures] = useState([]);
    const [linkDisabled, setLinkDisabled] = useState(true);
    const [suitableOptions, setSuitableOptions] = useState([]);
    const [suitables, setSuitables] = useState([]);
    const [excludeFeatures, setExcludeFeatures] = useState([]);
    const [notExcludedFeatureOptions, setNotExcludedFeatureOptions] = useState([]);

    useEffect(() => {
        loadSuitables();
    }, []);

    useEffect(() => {
        setLinkDisabled(!suitables || !suitables.length);
    }, [suitables]);

    useEffect(() => {
        let excluded = (excludeFeatures || []).map(o => o.value);
        let options = (featureOptions || []).filter(o => !excluded.includes(o.value));
        setNotExcludedFeatureOptions(options);
        let values = (features || []).filter(o => !excluded.includes(o.value));
        setFeatures(values);
    }, [featureOptions, excludeFeatures]);

    const loadSuitables = async () => {
        try {
            let response = await apiService.getSuitables();
            let options = (response.data || []).map(toOption);
            setSuitableOptions(options);
        } catch (error) {
            console.log(error);
        }
    }

    const download = async () => {
        if (linkDisabled)
            return;

        try {
            console.log('params', { suitables, features, excludeFeatures });
            let params = {
                suitable: suitables.map(o => o.value),
                feature: features.map(o => o.value),
                excludeFeature: excludeFeatures.map(o => o.value)
            };
            let response = await apiService.loadMenuDesign(params);

            var type = response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response.data], { type });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = `${translate('Edu.MenuDesign.Title')} ${suitables.map(o => o.label).join(', ')}.xlsx`;
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    return (
        <>
            <div className="panel-header">{translate('Edu.MenuDesign.Title')}</div>
            <div className="edu-items">
                <div className="input-container">
                    <div className="input-label">{translate('Edu.MenuDesign.Suitable')}:</div>
                    <Select
                        className="select"
                        classNamePrefix="share-select"
                        options={suitableOptions}
                        value={suitables}
                        onChange={setSuitables}
                        styles={transparentStyle}
                        isClearable={false}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isMulti
                        placeholder={translate('Select.Placeholder')}
                    />
                </div>
                <div className="input-container">
                    <div className="input-label">{translate('Edu.MenuDesign.Feature')}:</div>
                    <Select
                        className="select"
                        classNamePrefix="share-select"
                        options={notExcludedFeatureOptions}
                        value={features}
                        onChange={setFeatures}
                        styles={transparentStyle}
                        isClearable={false}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isMulti
                        placeholder={' '}
                    />
                </div>
                <div className="input-container">
                    <div className="input-label">{translate('Edu.MenuDesign.ExcludeFeature')}:</div>
                    <Select
                        className="select"
                        classNamePrefix="share-select"
                        options={featureOptions}
                        value={excludeFeatures}
                        onChange={setExcludeFeatures}
                        styles={transparentStyle}
                        isClearable={false}
                        components={{
                            IndicatorSeparator: () => null
                        }}
                        isMulti
                        placeholder={' '}
                    />
                </div>
                <div className="export-report">
                    <div
                        className={classNames('report-btn', { disabled: linkDisabled })}
                        onClick={download}
                    >{translate('Edu.MenuDesign.Btn')}</div>
                </div>
            </div>
        </>
    );
}

export default MenuDesign;