import * as toastr from 'toastr';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';

import api from '../../utils/api';
import { AppContext } from '../../contexts/AppContext';

import './suitableModal.scss';
import * as _ from 'lodash';


const SuitableModal = ({ languages, onClose, onSubmit }) => {

    const [suitables, setSuitables] = useState([]);
    const { translate } = useContext(AppContext);

    useEffect(() => { loadSuitables(); }, []);

    const loadSuitables = async () => {
        try {
            let response = await api.getSuitableList();
            setSuitables(response.data || []);
        } catch (error) {
            console.log(error);
        }
    }

    const toggle = () => {
        if (onClose) {
            onClose();
        }
    }

    const save = async () => {
        try {
            let data = suitables.filter(suitable => !!suitable.id || !!(suitable.translations || []).filter(translation => !!translation.name).length);
            let response = await api.updateSuitableList(data);
            if (onSubmit) {
                onSubmit(response.data);
            }
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    return (
        <Modal isOpen={true} toggle={toggle} autoFocus={false} className="suitable-modal">
            <ModalHeader toggle={toggle}>{translate('Suitable.Title')}</ModalHeader>
            <ModalBody>
                <table className="suitable-table">
                    <thead>
                        <tr>
                            {languages.map(lang => (<th key={lang}>{lang}</th>))}
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {suitables.map((suitable, i) => {
                            return (
                                <tr key={i}>
                                    {languages.map((lang, j) => {
                                        let name = (_.find(suitable.translations || [], { code: lang }) || {}).name || '';
                                        return (
                                            <td key={lang}>
                                                <Input
                                                    value={name}
                                                    onChange={e => {
                                                        console.log(e.target.value);
                                                        suitable.translations = suitable.translations || [];
                                                        let item = _.find(suitable.translations, { code: lang });
                                                        if (!item) {
                                                            item = { code: lang, name: e.target.value };
                                                            suitable.translations.push(item);
                                                        } else {
                                                            item.name = e.target.value;
                                                        }
                                                        setSuitables([...suitables]);
                                                    }}
                                                    autoFocus={j === 0}
                                                />
                                            </td>
                                        );
                                    })}
                                    <td>
                                        <div
                                            className="btn-plus"
                                            onClick={() => {
                                                let confirm = window.confirm(translate('Suitable.RemoveConfirm'));
                                                if (confirm) {
                                                    suitables.splice(i, 1);
                                                    setSuitables([...suitables]);
                                                }
                                            }}
                                        ><i className="fa fa-minus" aria-hidden="true"></i></div>
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan="3">
                                <div
                                    className="btn-plus"
                                    onClick={() => setSuitables([...suitables, { translations: [] }])}
                                ><i className="fa fa-plus" aria-hidden="true"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>{translate('Btn.Save')}</Button>{' '}
                <Button color="secondary" onClick={toggle}>{translate('Btn.Cancel')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default SuitableModal;