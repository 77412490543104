export function getYesNoOptions(translate) {
    let result = [
        getYesNo(translate, false),
        getYesNo(translate, true)
    ];
    return result;
}

export function getYesNo(translate, value) {
    let result = { value, label: translate(`Global.${value ? 'Yes' : 'No'}`) };
    return result;
}