import * as toastr from 'toastr';
import React, { Component } from 'react';

import api from '../../utils/api';
import SimpleTab from '../SimpleTab/SimpleTab';
import { AppContext } from '../../contexts/AppContext';
import RecipeDetail from './RecipeDetail';


export default class RecipeAdmin extends Component {

    static contextType = AppContext;

    constructor() {
        super();
        this.state = {
            recipes: [],
            detail: null
        }

        this.onEdit = this.onEdit.bind(this);
        this.onAdd = this.onAdd.bind(this);
        this.getDifficultyValue = this.getDifficultyValue.bind(this);
        this.onCopy = this.onCopy.bind(this);
    }

    componentDidMount() {
        this.loadRecipes();
    }

    async loadRecipes() {
        try {
            let response = await api.getRecipes();
            let recipes = (response.data || []);
            this.setState({ recipes });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    onEdit(item) {
        let detail = { ...item, ts: new Date().getTime() };
        this.setState({ detail });
    }

    onAdd() {
        let detail = { ts: new Date().getTime() };
        this.setState({ detail });
    }

    getDifficultyValue(item) {
        let { translate } = this.context;

        if (item.difficulty >= 1 && item.difficulty <= 5) {
            return translate(`Difficulty.${item.difficulty}`);
        }

        return '';
    }

    async onCopy(item) {
        let { translate } = this.context;

        let detail = { ts: new Date().getTime() };
        console.log('onCopy', item);
        if (item && item.id) {
            let response = await api.GetRecipe(item.id);
            let { translations, duration, difficulty, servings, energy, weight, items } = response.data || {};
            if (translations.length) {
                translations.forEach(o => {
                    if (o && o.name) {
                        o.name = o.name + translate('CopyPostfix', { lng: o.lang })
                    }
                });
            }
            items = (items || []).map(o => ({ ...o, value: o.quantity }));
            detail = { ...detail, translations, duration, difficulty, servings, energy, weight, items };
        } else {
            console.log('No item id.');
        }
        this.setState({ detail });
    }

    canCopy(item) {
        return !!item;
    }

    render() {
        let { translate } = this.context;
        let { recipes, detail } = this.state;

        let showDetail = !!detail;

        return (
            <div className="recipe-admin">
                <div className="panel-header">{translate('RecipeAdmin.Title')}</div>
                <SimpleTab
                    cols={[
                        { key: 'name', name: translate('RecipeAdmin.Name'), style: { width: '60%' } },
                        { key: 'duration', name: translate('RecipeAdmin.Duration'), valueType: 'number', style: { width: '15%' } },
                        { key: 'difficulty', name: translate('RecipeAdmin.Difficulty'), style: { width: '25%' }, getValue: this.getDifficultyValue }
                    ]}
                    data={recipes}
                    onEdit={this.onEdit}
                    onAdd={this.onAdd}
                    onCopy={this.onCopy}
                    canCopy={this.canCopy}
                />

                {showDetail &&
                    <RecipeDetail
                        key={detail.ts}
                        data={detail}
                        onClose={() => this.setState({ detail: null })}
                        onSubmit={() => {
                            this.loadRecipes()
                            this.setState({ detail: null });
                        }}
                    />
                }
            </div>
        );
    }
}
