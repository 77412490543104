import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import { LoginMenu } from '../api-authorization/LoginMenu';
import './NavMenu.scss';
import { AppContext } from '../../contexts/AppContext';


export class NavMenu extends Component {
    static displayName = NavMenu.name;
    static contextType = AppContext;

    static propsTypes = {
        isAdmin: PropTypes.bool,
        selectedShare: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.toggleNavbar = this.toggleNavbar.bind(this);
        this.state = {
            collapsed: true
        };
    }

    toggleNavbar() {
        this.setState({
            collapsed: !this.state.collapsed
        });
    }

    render() {
        let { translate } = this.context;
        let { isAdmin, selectedShare } = this.props;
        let { collapsed } = this.state;

        return (
            <header>
                <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                    <Container>
                        <div className="navbar-logo">
                            <img alt="food4fit" src="/img/logo-food4fit.svg" />
                        </div>
                        <NavbarToggler onClick={this.toggleNavbar} className="mr-2" />
                        <Collapse className="d-sm-inline-flex flex-sm-row-reverse" isOpen={!this.state.collapsed} navbar>
                            <ul className="navbar-nav flex-grow app-menu">
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/" onClick={o => this.setState({ collapsed: true })}>{translate('Overview.Link')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/report" onClick={o => this.setState({ collapsed: true })}>{translate('Reports.Link')}</NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink tag={Link} className="text-dark" to="/edu" onClick={o => this.setState({ collapsed: true })}>{translate('Edu.Link')}</NavLink>
                                </NavItem>
                                <LoginMenu
                                    isAdmin={isAdmin}
                                    selectedShare={selectedShare}
                                    onClick={o => this.setState({ collapsed: true })} collapsed={collapsed}>
                                </LoginMenu>
                            </ul>
                        </Collapse>
                    </Container>
                </Navbar>
            </header>
        );
    }
}
