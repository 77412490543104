import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from "react";

import { AppContext } from "../../contexts/AppContext";
import { LT_ACTIVITY, LT_MENU } from '../../constants/listType';

import './dataList.scss';
import ItemCell, { ICT_TEXT, ICT_TIME, ICT_CELL, ICT_CELL_TEXT, ICT_ICO } from './ItemCell';
import { getHealthIco, HI_NORMAL } from '../../constants/HealthyIndex';
import { arraySort } from '../../utils/sort';
import { PanelTooltip } from '../PanelTooltip';


class DataList extends Component {

    static propTypes = {
        eOutputs: PropTypes.array,
        eInputs: PropTypes.array,
        onEnergyOutput: PropTypes.func,
        onEnergyInput: PropTypes.func,
        type: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.state = {
            selectedList: props.type,
            activityTitleTooltip: { show: false, pos: 0 }
        };

        this.add = this.add.bind(this);
        this.changeSelectedList = this.changeSelectedList.bind(this);
        this.onTitleMouseEnter = this.onTitleMouseEnter.bind(this);
        this.onTitleMouseLeave = this.onTitleMouseLeave.bind(this);
    }

    componentDidMount() {
    }

    getDataListOptions() {
        let result = [
            { value: LT_ACTIVITY, textKey: 'Overview.Activity.Title' },
            { value: LT_MENU, textKey: 'Overview.Menu.Title' }
        ];
        return result;
    }

    getTitle(options, type) {
        let result = _.find(options, { value: type }).textKey;
        return result;
    }

    getItems(eInputs, eOutputs, type, foodTypes) {
        switch (type) {
            case LT_ACTIVITY:
                return (eOutputs || []).map(o => ({
                    ...o,
                    text: o.activityName,
                    energy: !!o.duration && !!o.energyPerMinute ? Math.round(o.duration * o.energyPerMinute) : null
                }));
            case LT_MENU:
                let res = (eInputs || []).map(o => {
                    let foodTypeText = null;
                    let foodTypeOrder = 100;
                    if (o.foodTypeId) {
                        let foodType = _.find(foodTypes, { id: o.foodTypeId });
                        if (foodType) {
                            foodTypeText = foodType.label;
                            foodTypeOrder = foodType.rid
                        }
                    }
                    return {
                        ...o,
                        text: o.foodName,
                        amount: o.quantity,
                        unit: o.foodUnit,
                        energy: Math.round(o.foodEnergy * o.quantity / 100),
                        foodTypeText,
                        foodTypeOrder,
                        healthIndex: o.foodHealthIndex || HI_NORMAL
                    };
                });
                arraySort(res, 'foodTypeOrder');
                return res;
            default:
                return [];
        }
    }

    add() {
        let { selectedList } = this.state;
        let { onEnergyOutput, onEnergyInput } = this.props;

        switch (selectedList) {
            case LT_ACTIVITY:
                if (onEnergyOutput) {
                    onEnergyOutput();
                }
                break;
            case LT_MENU:
                if (onEnergyInput) {
                    onEnergyInput();
                }
                break;
            default:
                break;
        }
    }

    edit(item, type) {
        let { onEnergyOutput, onEnergyInput } = this.props;

        switch (type) {
            case LT_ACTIVITY:
                if (onEnergyOutput) {
                    onEnergyOutput(item);
                }
                break;
            case LT_MENU:
                if (onEnergyInput) {
                    onEnergyInput(item);
                }
                break;
            default:
                break;
        }
    }

    changeSelectedList(selectedList) {
        this.setState({ selectedList });
    }

    onTitleMouseEnter(e) {
        let cRect = e.currentTarget.getBoundingClientRect();
        let pos = Math.round(Math.max(e.clientX - cRect.left, 15));
        this.setState({ activityTitleTooltip: { show: true, pos } });
    }

    onTitleMouseLeave(e) {
        this.setState({ activityTitleTooltip: { show: false, pos: 0 } });
    }

    render() {
        let { translate, language } = this.context;
        let { selectedList, activityTitleTooltip } = this.state;
        let { eOutputs, eInputs, foodTypes, showSum } = this.props;

        let dataListOptions = this.getDataListOptions();
        let titleKey = this.getTitle(dataListOptions, selectedList);
        let items = this.getItems(eInputs, eOutputs, selectedList, foodTypes);
        let isActivity = selectedList === LT_ACTIVITY;
        let isMenu = selectedList === LT_MENU;
        let sum = null;

        if (showSum && items.length) {
            sum = `${Math.round(items.reduce((r, o) => r + o.energy, 0)).toLocaleString(language)} kJ`;
        }

        return (
            <div className="data-list f4f-panel">
                <div className="data-list-header">
                    {isActivity &&
                        <div
                            className="title"
                            onMouseEnter={this.onTitleMouseEnter}
                            onMouseLeave={this.onTitleMouseLeave}
                        >
                            {translate(titleKey)}
                            <i className="fa fa-info-circle" aria-hidden="true"></i>
                        </div>
                    }
                    {!isActivity &&
                        <div className="title">{translate(titleKey)}</div>
                    }
                    {sum &&
                        <div className="sum">{sum}</div>
                    }
                </div>
                <div className="data-list-body">
                    {items.map((o, i) => (
                        <div
                            key={i}
                            className="data-list-item"
                            onClick={() => this.edit(o, selectedList)}
                        >
                            {o.foodTypeText
                                ? <ItemCell type={ICT_CELL_TEXT} value={o.text} customName={o.foodTypeText} />
                                : <ItemCell type={ICT_TEXT} value={o.text} />
                            }
                            {isActivity &&
                                <ItemCell type={ICT_TIME} value={o.duration} />
                            }
                            {isMenu &&
                                <ItemCell type={ICT_CELL} value={o.amount ? o.amount.toLocaleString(language) : o.amount} unit={o.unit} name="DataList.Amount" />
                            }
                            <ItemCell type={ICT_CELL} value={o.energy ? o.energy.toLocaleString(language) : o.energy} unit="kJ" name="DataList.Energy" />
                            {isMenu &&
                                <ItemCell
                                    type={ICT_ICO}
                                    value={getHealthIco(o.healthIndex)}
                                    name={`HealthIndex.${o.healthIndex}`}
                                />
                            }
                        </div>
                    ))}
                </div>
                <div className="data-list-buttons">
                    <div
                        className="button"
                        onClick={this.add}
                    >{translate(isActivity ? 'DataList.AddActivity' : 'DataList.AddFood')}</div>
                </div>
                {activityTitleTooltip.show &&
                    <PanelTooltip
                        pos={activityTitleTooltip.pos}
                        value={translate('Overview.Activity.Tooltip')}
                    />
                }
            </div>
        );
    }
}

DataList.contextType = AppContext;

export default DataList;