const en = {
    "Test": {
        "Lang": "Language test"
    },
    "Logout": {
        "Link": "Logout"
    },
    "Login": {
        "Link": "Login",
        "LoginAction": "Loading..."
    },
    "Settings": {
        "Link": "Settings",
        "Title": "Settings"
    },
    "Overview": {
        "Link": "Overview",
        "Activity": {
            "Title": "Activities",
            "Tooltip": "If not filled in, the caloric expenditure will be used according to the set way of life."
        },
        "Menu": {
            "Title": "Foods"
        }
    },
    "Reports": {
        "Link": "Nutritionist",
        "Title": "Nutritionist",
        "Text": "No food is entered in the selected period.",
        "Main": {
            "Title": "Proportion of main components of the diet",
            "Fat": "Fat",
            "Carbohydrate": "Carbohydrate",
            "Protein": "Protein",
            "Standard": "standard"
        },
        "All": {
            "Title": "General overview",
            "Energy": "Energy",
            "Fat": "Fat",
            "Carbohydrate": "Carbohydrate",
            "Protein": "Protein",
            "Fiber": "Fiber",
            "Sodium": "Sodium",
            "Sfat": "Saturated fats",
            "Fluids": "Fluids",
            "Alcohol": "Alcohol",
            "Cholesterol": "Cholesterol",
            "Fruit": "Fruit and vegetable",
            "Milk": "Milk products",
            "Fish": "Fish",
            "Meal": "Meal count",
            "Mean": "Average daily income",
            "Standard": "Daily norm",
            "Rate": "% daily norm"
        },
        "Summary": {
            "Title": "Diet evaluation",
            "Info": "The diet can be evaluated from three aspects: quantitative, qualitative and in terms of regularity (frequency) of eating.",
            "Quantity": {
                "Title": "In terms of quantitative",
                "Energy": {
                    "Prefix": "The average <strong> energy intake </strong> of your diet is {value}",
                    "0": ", which is less than your recommended caloric intake. With such a caloric intake, there should be a gradual weight loss, if this is not the case, there is a mistake somewhere. This can be in an inappropriate composition of the menu, in bad timing or in insufficient regularity of individual meals, the error can of course also be the registration of individual items, or in setting parameters for the calculation of the recommended energy expenditure.",
                    "1": ", which means that your caloric intake more or less corresponds to your recommended caloric intake. With such an intake, your body weight should be without major fluctuations and practically balanced. If this is not the case, there is a mistake somewhere. This can be in an unsuitable composition of the menu, in bad timing or in insufficient regularity of individual meals, the error can of course also be the registration of individual items, or in setting parameters for the calculation of the recommended caloric expenditure.",
                    "2": ", which is significantly more than your recommended caloric intake. With such a caloric intake, your body weight should gradually increase. To prevent this, you will need to reduce your caloric intake or increase your energy expenditure."
                }
            },
            "Quality": {
                "Title": "In terms of quality",
                "Fat": {
                    "Prefix": "Your average <strong> fat </strong> intake is {value} of your total caloric intake",
                    "0": ", which is less than the recommended fat intake. Of course, that doesn't mean you eat badly. The traditional Japanese diet also contains less than 20% fat from total caloric intake and is very healthy, depending on the overall composition and balance of individual nutrients. However, individuals with a low dietary fat intake may have long-term low intakes of vitamins E and B12, or other fat-soluble vitamins such as vitamins A, D and K, or some important trace elements such as zinc. Your intake of these substances (with the exception of vitamins K and zinc, which have not been analyzed) is listed below.",
                    "1": ", it is therefore completely in the standard and according to all professional recommendations. However, the composition of the fats consumed is also important, ie what percentage do you receive in the form of saturated and unsaturated fats. The composition of the fats you consume is discussed below.",
                    "2": ", which means that your fat intake is borderline. If most of the fat from your diet consists of vegetable or fish fats, nothing happens at all, after all, fats make up up to 40% of the total caloric intake in a typical Mediterranean diet. The composition of the fats you consume is discussed below.",
                    "3": ", which means that your fat intake is too high. Purely theoretically, if most of the fat from your diet consists of vegetable or fish fats, there is no such high intake of fats to the detriment (except for the danger of high calorific value) - after all, fats make up up to 40% of the total caloric intake in a typical Mediterranean diet. In the Czech Republic, this option is absolutely exceptional. The composition of the fats you consume is discussed below.",
                    "Summary": "To reduce fat intake, consume cheese up to 30% fat in dry matter, we recommend cottage, mozzarella, cottage cheese, yogurt up to 3% fat, kefir, acidophilic milk, sour cream (low fat) in smaller quantities as a supplement. It is advantageous to consume dairy products with probiotic cultures. Reduce classic butter, replace it with vegetable margarines, preferably low-fat (they often contain only 40% calories compared to regular butter). To a lesser extent, include in the diet unroasted and unsalted nuts (almonds, walnuts, cashews, etc.) and seeds (flax, hemp, sunflower, pumpkin, etc.). Try to replace pork and beef with poultry and fish, try to introduce a „meatless day“ about twice a week. Use quality vegetable oils containing omega-3 fatty acids (eg flaxseed or hemp oil), extra virgin olive oil, but also rapeseed oil. Use cooking, stewing, fat-free toasting, grilling and fat-free baking as heat treatment. Add quality oils to the finished meal and do not heat-treat further. Fish should be present in your diet at least twice a week."
                },
                "Carbohydrat": {
                    "Prefix": "Your average <strong> carbohydrate </strong> intake is {value} of your total caloric intake",
                    "0": ", which is not good. Complex carbohydrates (not simple, sweet sugars) should make up more than half of your caloric intake. Low intake of complex carbohydrates is usually associated with high intake of fats and low intake of foods and foods of plant origin. There is often a low intake of fiber, minerals, trace elements, some vitamins and other micronutrients. Intake of complex carbohydrates can be increased by consuming wholemeal bread and bread (instead of white bread), oatmeal, cereals, paddy rice, semolina and wholemeal pasta, various alternative cereals (flakes, millet, buckwheat, couscous, bulgur, quinoa…), legumes and of course, maximum consumption of vegetables and fruits. It is important to try to reduce your intake of simple sugars (unless their consumption is associated with physical performance), not to sweeten drinks, not to drink sweetened lemonades, not to consume sweets, cookies, cakes, chocolates and similar delicacies unnecessarily.",
                    "1": ", which is correct. Complex carbohydrates (not simple sugars) should make up more than half of your energy intake. The ratio of complex and simple carbohydrates is important. It is important to know about the dangers of simple sugars (unless their consumption is associated with physical performance), not to sweeten drinks, not to drink sweetened lemonades, not to consume sweets, cookies, cakes, chocolates and similar delicacies unnecessarily."
                },
                "Protein": {
                    "Prefix": "Your average <strong> protein </strong> intake is {value} of your total caloric intake",
                    "0": ", therefore, less than recommended. If protein intake is too low, the body may be missing some important substances in protein-rich foods for a long time, such as B vitamins, iron, zinc, calcium, or some so-called essential amino acids, the lack of which can negatively affect a number of functions.",
                    "1": ", which corresponds to the recommended values.",
                    "2": ", which is more than recommended. Such a high intake is usually hand in hand with a high intake of fats, especially animal fats. The body cannot use high protein intake and must get rid of excess protein. This puts a strain on the liver, kidneys and circulatory system. It is generally recommended to include more vegetable protein, in the form of legumes, cereals and alternative sources - tofu, soy meat and soy products (spreads, pâtés). So make sure you choose the right source of protein - limit sausages (they are too fatty and salted) and pork and beef, prefer poultry, fish, game. Stewed ham with a high protein content and dried ham (prosciutto) are suitable."
                },
                "Fiber": {
                    "Prefix": "Your average <strong> fiber </strong> intake is {value} per day",
                    "0": ", which is less than the recommended 25 g of fiber per day. Low fiber intake is associated with high blood cholesterol levels, cardiovascular disease, some types of cancer, especially colon cancer. Fiber is contained in the cell walls of plant cells, so most fiber is found in fruits and vegetables (preferably raw) as well as whole grain breads and cereals. A lot of fiber contains paddy rice, nuts (but it's definitely not a good idea to eat salted peanuts), seeds, legumes, oatmeal. It should also be noted that neither meat nor dairy products contain any fiber. Conversely, most fiber contains wheat bran (up to 45 g / 100 g), flax seeds (38 g / 100 g), soy (18 g / 100 g) and whole wheat bread (8-10 g / 100 g).",
                    "1": ", which corresponds to the recommended values. We praise you!"
                },
                "Sodium": {
                    "Prefix": "Your average <strong> salt </strong> intake is {value}, which corresponds to {sodium} <strong> sodium </strong> per day",
                    "0": ", which is less than the recommended maximum intake. We praise you!",
                    "1": ", which is more than the recommended maximum income. Sodium intake, especially table salt, is directly proportional to the risks of cardiovascular disease, high blood pressure and metabolic syndrome. We consume most of the sodium in foods purchased in self-service stores - about 65%, so it is necessary to choose foods with low sodium content. It is mostly found in pastries, meat, pizzas and pasta, cheeses, soups (especially instant) and various fast-food dishes (sandwiches, burgers). It should be noted that this standard is very strict, it does not take into account the total caloric intake (the standard is the same for a 50-pound woman and for a 100-pound man) or increased salt loss by perspiration (is becoming more important in athletes)."
                },
                "SatFat": {
                    "Prefix": "Your <strong> saturated fat </strong> intake is {value} of your total caloric intake",
                    "0": ", which is excellent. We praise you!",
                    "1": ", which is more than recommended by medical authorities. High intake of saturated fats is associated with high intake of fats in general, especially with high intake of animal fats. The higher the intake of saturated fats, the higher the risks of cardiovascular disease and many other diseases, including cancer. Solved how to reduce the intake of saturated fats are given in the evaluation of the ratio of animal and vegetable fats."
                },
                "Water": {
                    "Prefix": "Your average <strong> fluid intake </strong> is {value} per day",
                    "0": ", which not good. Prolonged low fluid intake can damage the kidneys, is associated with a higher risk of urinary tract infections, constipation often occurs, there may be impaired attention, memory, physical performance.",
                    "1": ", which is good. Take this recommendation as a guide only, it depends very much on climatic conditions and physical activity. Liquids should be taken in the form of (still) unflavoured water, unsweetened teas, sugar-free coffee (it may be with milk, but not full-fat). Unsuitable are sweetened minerals, energy drinks, sweet lemonades, juices that contain a large amount of simple carbohydrates (unless their consumption is associated with more intense sports activities). From alcoholic beverages, the most suitable is white or red wine, wine splash, but again, pay attention to the fact that alcohol is very rich in energy and also pay attention to the recommended maximum amount of alcohol per day (see alcohol intake)."
                },
                "Alcohol": {
                    "Prefix": "Your average <strong> alcohol </strong> intake is {value} per day",
                    "0": ", which is tolerable income. There are even data that say that regular, moderate alcohol consumption (equivalent to about 2 deci of wine or one large beer) even has a protective effect on the cardiovascular system. It should be noted, however, that alcohol has a relatively high caloric value (one 12 ° beer represents about 1,000 kJ of usable energy). It should also be emphasized that binge drinking (weekend drinkers, also called binge drinkers in English) is also unhealthy, although the average alcohol consumption calculated over the whole week may not be excessive.",
                    "1": ", which is unhealthy. Excessive alcohol consumption has a number of adverse effects on the human body, from liver damage to brain disorders, cardiovascular disease, but also to an increased risk of cancer. It should also be emphasized that alcohol has a relatively high caloric value (one 12 ° beer represents about 1,000 kJ of usable energy) and with heavy alcohol consumption, the supply of calories from this source can enormously disrupt the energy balance and lead to weight gain."
                },
                "Fruit": {
                    "Prefix": "Your <strong> fruit and vegetable </strong> intake is {value} per day (1 piece = 80 g)",
                    "0": ", which is not enough. Five pieces of fruit or vegetables per day is the minimum recommended by professional authorities, especially to cover the required intake of fiber, minerals, trace elements, some vitamins and other micronutrients. Adequate fiber intake, of course, also changes positively for most of the other parameters assessed: fat and carbohydrate intake, vegetable and animal fat ratio, saturated fat and cholesterol intake…",
                    "1": ", which is great. We praise you!"
                },
                "Milk": {
                    "Prefix": "Your <strong> dairy intake </strong> intake is {value} per day (1 piece = 250 ml milk / 40 g cheese / 200 g yogurt)",
                    "0": ", which is not enough. Milk and dairy products are a particularly important source of calcium. Dairy products with a lower fat content (eg yoghurts up to 3% fat, maximum semi-skimmed milk, cheeses with low fat content in dry matter (up to 30%) should be consumed.",
                    "1": ", which is right. Milk and dairy products are a particularly important source of calcium."
                },
                "Fish": {
                    "Prefix": "Your <strong> fish and fish meal </strong> intake is {value} per week (1 piece = 100 g)",
                    "0": ", which is not enough. Fish is an important source of polyunsaturated fatty acids (fish oil), which are healthy and very beneficial for humans. Their consumption can replace, at least in part, the intake of red meat, and thus reduce the intake of saturated fats and cholesterol.",
                    "1": ", which is right. Fish is an important source of polyunsaturated fatty acids (fish oil), which are healthy and very beneficial for humans."
                }
            },
            "Regular": {
                "Title": "Regularity of meals",
                "Text": "You should eat regularly, at least 5 times a day. Do not miss breakfast, morning or afternoon snacks, which should consist mainly of fruit or vegetables in combination with a dairy product or cereal bar / knackerbrot so that the condition of consuming at least 5 pieces of fruit or vegetables per day and 2 dairy products per day can be met. The regularity of eating and eating at least 5 times a day is just as important as the caloric value and composition of your diet."
            }
        },
        "Loading": "The data is being loaded...",
        "Period": {
            "Label": "Rated season",
            "Between": "to"
        },
        "User": "User",
        "Export": "Download report",
        "ExportExt": "Download extended report",
        "ExportSport": "Download sports report",
        "Ext": "extended",
        "Sport": "sports",
        "Report": "report",
        "Daily": "Download the daily report",
        "DailyReport": "daily report"
    },
    "DataList": {
        "Time": "Time",
        "Energy": "Energy",
        "Amount": "Amount",
        "Add": "Add",
        "AddActivity": "Enter activity",
        "AddFood": "Enter food",
        "Text": "Text",
        "TextPlaceholder": "Enter text",
        "Activity": "Activity"
    },
    "Btn": {
        "Save": "Save",
        "Cancel": "Cancel",
        "Delete": "Delete",
        "Close": "Close"
    },
    "EnergyProgressBar": {
        "Lack": "Lack",
        "Surplus": "Surplus",
        "TotalInput": "Total energy intake",
        "TotalOutput": "Total energy expenditure",
        "TodayInput": "Current energy intake",
        "TodayOutput": "Current energy expenditure",
        "BasalOutput": "Of which basal metabolic output",
        "LifeStyleOutput": "Of this, dispensing a set way of life",
        "TipNote": "The orange line in the middle determines the value of the equilibrium energy balance.",
        "PlanTipDown": "The green line determines the balance of -2,770 kJ for the whole day, for a maximum recommended weight loss of 0.5 kg per week.",
        "PlanTipUp": "The green line determines the balance of +2,770 kJ for the whole day, for the maximum recommended weight gain of 0.5 kg per week.",
        "Title": "Energy balance"
    },
    "EnergyOutput": {
        "Title": "Activity",
        "TimeFrom": "Start time",
        "Duration": "Duration [min]",
        "Category": "Category",
        "Energy": "Energy",
        "Err": {
            "ActivityRequired": "Name is required.",
            "DurationRequired": "Duration is required."
        },
        "Activity": "Name",
        "DeleteConfirm": "Are you sure you want to delete the selected activity?",
        "DeleteSuccess": "The Activity was deleted successfully."
    },
    "EnergyInput": {
        "Title": "Food",
        "FoodItem": "Name",
        "FoodType": "Day part",
        "FoodCategory": "Category",
        "Favorite": "Favorite",
        "Other": "Others",
        "Amount": "Amount",
        "Energy": "Energy",
        "Err": {
            "AmountRequired": "Amount is required.",
            "FoodRequired": "Name is required."
        },
        "DeleteConfirm": "Are you sure you want to delete the selected food?",
        "DeleteSuccess": "The food was deleted successfully.",
        "HealthPostfix": "food"
    },
    "FoodCategory": {
        "All": "All",
        "Favorite": "Favorite",
        "Other": "Others"
    },
    "FoodType": {
        "Placeholder": "Not specified"
    },
    "Err": {
        "System": "Unexpected error. Please contact your system administrator.",
        "NoWeight": "You probably don't have a weight set in the user's profile."
    },
    "ActivityCategory": {
        "All": "All"
    },
    "DateNavigator": {
        "Today": "Today",
        "TodayPrefix": "Today at "
    },
    "ElementGraph": {
        "Fat": "Fat",
        "Carbohydrate": "Carbohydrate",
        "Protein": "Protein",
        "Fiber": "Fiber",
        "Sodium": "Salt",
        "SatFat": "Saturated Fat",
        "FatTip": "Total intake: {value} g ({rate}%) \r\n The total daily energy intake of fats should not exceed {gh}% of the total caloric intake.",
        "CarbohydrateTip": "Total intake: {value} g ({rate}%) \r\n The total daily energy intake from carbohydrates should be {gl}-{gh}% of the total caloric intake.",
        "ProteinTip": "Total intake: {value} g ({rate}%) \r\n The total daily energy intake from proteins should be {gl}-{gh}% of the total caloric intake.",
        "FiberTip": "Total received: {value} g ({rate}%) \r\n The total daily fiber intake should be at least 25 g per day.",
        "SodiumTip": "Total taken: {value} mg ({rate}%) \r\n The total daily intake of sodium should not exceed 2300 mg per day.",
        "SatFatTip": "Total intake: {value} g ({rate}%)\r\nThe total daily intake of saturated fatty acids should not exceed 7% of the total caloric intake.",
        "RateTitle": "Nutrient ratio",
        "AmountTitle": "Amount of nutrients"
    },
    "Profile": {
        "Loading": "Loading user profile...",
        "Link": "Profile",
        "Title": "Profile",
        "Save": "Save",
        "Username": "Name",
        "Phone": "Phone number",
        "InvalidPhone": "The Phone number field is not a valid phone number.",
        "Updated": "Your profile has been updated",
        "Weight": "Weight [kg]",
        "WeightTooltip": "Information about you serves to significantly refine the calculation of the energy released.",
        "Height": "Height [cm]",
        "Year": "Year of birth",
        "Gender": {
            "Label": "Gender",
            "1": "Man",
            "2": "Woman"
        },
        "UpdateError": "Unexpected error when trying to update user profile.",
        "Plan": {
            "Label": "My plan",
            "Tooltip": "The maximum recommended weight loss or weight gain is 0.5 kg per week. The boundaries of this energy balance will be shown in the overview.",
            "1": "Lose weight",
            "2": "Gain weight"
        },
        "LifeStyle": {
            "Label": "Life style",
            "NoneTooltip": "Undefined - energy expenditure will be calculated only from basal metabolism and manually entered activities",
            "1": "Sedentary",
            "SedentaryTooltip": "Sedentary - a sedentary job or less than 1 hour of sports per week",
            "2": "Low active",
            "LowActiveTooltip": "Slightly active - occasional exercise at work or 1 to 3.5 hours of sports per week",
            "3": "Active",
            "ActiveTooltip": "Active - active movement at work or 3.5 to 7 hours of sports per week",
            "4": "Very active",
            "VeryActiveTooltip": "Very active - strenuous work or more than 7 hours of sports per week"
        },
        "Basal": {
            "Label": "Basal metabolism",
            "1": "Calculate according to the entered data",
            "2": "Use the measured value"
        },
        "BasalTooltip": "Or basal metabolic rate (BMR) is energy released only by the functioning of vital organs, such as the heart, lungs, brain.",
        "BasalValue": "The value of basal metabolism [kJ]",
        "Err": {
            "WeightInvalid": "Invalid value",
            "HeightInvalid": "Invalid value",
            "BirthInvalid": "Invalid value",
            "BasalRequired": "Value is required",
            "BasalInvalid": "Invalid value"
        },
        "Email": "E-mail"
    },
    "Edu": {
        "Link": "Education",
        "Title": "Recommended sites",
        "Sportv": {
            "Title": "Sportvital-Pro",
            "Description": "Komplexní zdroj informací o zdravé výživě, sportu a zdravém životním stylu."
        },
        "Mze": {
            "Title": "Ministerstvo zemědělství – potraviny",
            "Description": "Webový portál Ministerstva zemědělství České republiky týkající se kvality potravin."
        },
        "Bezpo": {
            "Title": "Informační centrum bezpečnosti potravin",
            "Description": ""
        },
        "Cojis": {
            "Title": "Víš, co jíš?",
            "Description": "Informace o výživě a onemocněních z potravin."
        },
        "Nutrib": {
            "Title": "Nutribaze.cz",
            "Description": "Databáze složení potravin České republiky."
        },
        "Spolpv": {
            "Title": "Společnost pro výživu",
            "Description": ""
        },
        "Usda": {
            "Title": "USDA FoodData Central",
            "Description": "Online databáze potravin s kompletním složením poskytovaná americkým Ministerstvem zemědělství."
        },
        "Pubmed": {
            "Title": "Pubmed",
            "Description": "Databáze americké Národní lékařské knihovny s více než 30 milionů odborných článku z oblasti biomedicíny."
        },
        "Recomend": {
            "Title": "Recommended documents"
        },
        "FoodFeature": {
            "Title": "Foods with properties",
            "Feature": "Properties",
            "Download": "Download list"
        },
        "MenuDesign": {
            "Title": "Menu design",
            "Feature": "Properties",
            "Btn": "Download list",
            "Suitable": "For Mode",
            "ExcludeFeature": "Unwanted features"
        }
    },
    "Logo": {
        "Text": "Projekt SW aplikace Food4Fit byl podpořen programem Podpora NNO v působnosti MZe ČR"
    },
    "Selectbox": {
        "NoOptions": "No options",
        "NotFound": "Not found"
    },
    "Plan": {
        "Link": "Planning",
        "Title": "Planning",
        "LastWeightDate": "Date of the last update of the balance",
        "CurrentWeight": "Current weight [kg]",
        "PlanWeight": "Plan weight [kg]",
        "PlanDate": "Required date of reaching the target weight",
        "EnergyBalance": "Recommended value of the daily energy balance to reach the target weight",
        "Err": {
            "EnergyBadMinus": "The target weight and the required date of achievement do not comply with the rules of weight loss. For a maximum recommended weight loss of 0.5 kg per week, the limit of the daily energy balance is -2,770 kJ.",
            "CurWeightRequired": "Current weight is required.",
            "PlanWeightRequired": "Target weight is required.",
            "PlanDateRequired": "Target weight date is required."
        },
        "Save": "Recalculate to date"
    },
    "Password": {
        "Link": "Password",
        "Title": "Password",
        "TitleNew": "New password",
        "TitleChange": "Change password",
        "CurrentPwd": "Current password",
        "NewPwd": "New password",
        "ConfirmPwd": "Confirm new password",
        "Btn": "Update password",
        "BtnSet": "Set password",
        "Err": {
            "PasswordMismatch": "Incorrect current password.",
            "CurrentPwdRequired": "The Password field is required.",
            "NewPwdRequired": "The New password field is required.",
            "NewPwdInvalid": "The New password must be at least {min} and at max {max} characters long.",
            "ConfirmPwdInvalid": "The new password and confirmation password do not match.",
            "InvalidPwd": "Incorrect new password.",
            "Load": "Failed to retrieve password information."
        },
        "Changed": "Your password has been changed.",
        "Set": "Password has been set.",
        "Loading": "Loading data...",
        "NewInfo": "You do not have a local username / password for this page. Add a local account so you can sign in without an external sign in."
    },
    "ExtLogin": {
        "Link": "External logins",
        "Title": "External logins",
        "Another": "Add another service to log in.",
        "Remove": "Remove",
        "Removed": "The external login was removed.",
        "Added": "The external login was added.",
        "Err": {
            "DuplicateUserName": "User name {username} is already taken.",
            "LoadLogins": "Failed to load list of external accounts.",
            "RemoveFail": "Remove external login failed."
        },
        "NoInfo": "Error loading external login information.",
        "Add": "Add"
    },
    "Personal": {
        "Link": "Personal data",
        "Title": "Personal data",
        "Text1": "Your account contains personal data that you have given us. This page allows you to delete that data.",
        "Text2": "Deleting this data will permanently remove your account, and this cannot be recovered.",
        "Download": "Download",
        "Delete": "Delete",
        "DeleteSuccess": "Account successfully deleted."
    },
    "Gender": {
        "MaleShort": "M",
        "FemaleShort": "F"
    },
    "Unit": {
        "Pcs": "pcs",
        "Week": "week"
    },
    "Admin": {
        "Link": "Administration",
        "Title": "Administration"
    },
    "ActivityAdmin": {
        "Link": "Aktivities",
        "Title": "Aktivities",
        "Name": "Name",
        "Deleted": "Deleted",
        "ActivityDetail": "Activity",
        "Energy50": "Energy 50kg [kcal/min]",
        "Energy71": "Energy 71kg [kcal/min]",
        "Energy92": "Energy 92kg [kcal/min]"
    },
    "FoodAdmin": {
        "Link": "Foods",
        "Title": "Foods",
        "Name": "Name",
        "Category": "Category",
        "Energy": "Energy [kJ/100 g]",
        "Health": "Healthy index",
        "FoodDetail": "Food",
        "Unit": "Unit",
        "Note": "Note",
        "Params": "Params",
        "Feature": "Properties",
        "Export": "Food export",
        "Suitable": "Suitable for mode",
        "FoodType": "Type of food"
    },
    "RecipeAdmin": {
        "Link": "Recipes",
        "Title": "Recipes",
        "Name": "Name",
        "Duration": "Duration",
        "Difficulty": "Difficulty",
        "RecipeDetail": "Recipe",
        "Servings": "Number of servings",
        "Energy": "Energy",
        "Weight": "Weight",
        "Preparation": "Preparation process",
        "Items": "Ingredients",
        "Photo": "Photo"
    },
    "RuleAdmin": {
        "Link": "Rules",
        "Title": "Rules"
    },
    "UserAdmin": {
        "Link": "Users",
        "Title": "Users",
        "Email": "E-mail",
        "Role": "Role",
        "RoleUser": "User",
        "RoleAdmin": "Admin",
        "UserDetail": "User",
        "Count": "Count: {shared} / {count}",
        "Name": "Name"
    },
    "Global": {
        "No": "No",
        "Yes": "Yes"
    },
    "HealthIndex": {
        "0": "Normal",
        "1": "Unhealthy",
        "2": "Healthy"
    },
    "Difficulty": {
        "0": "Unknown",
        "1": "Very easy",
        "2": "Easy",
        "3": "Narmal",
        "4": "Hard",
        "5": "Very hard"
    },
    "Share": {
        "Title": "Access sharing",
        "Link": "Access sharing",
        "Text": "For example, if you want to allow your nutritionist to access your user account and the data you enter, please enter the email address you registered with in the Food4Fit application below.",
        "Email": "User email",
        "SaveSuccess": "Settings saved."
    },
    "ReportAdmin": {
        "Link": "Reports",
        "Name": "Name",
        "Title": "Texting"
    },
    "ReportType": {
        "0": "Basic",
        "1": "Extended",
        "2": "Sport"
    },
    "Feature": {
        "Title": "Codelist of mode",
        "RemoveConfirm": "Are you sure you want to delete the property? It will also remove the property from all meals.",
        "Composition": "Key Composition"
    },
    "Select": {
        "Placeholder": "Select..."
    },
    "CopyPostfix": " - copy",
    "Suitable": {
        "Title": "Codelist of mode",
        "RemoveConfirm": "Are you sure you want to delete the mode? It will also remove the mode from all meals."
    },
    "FoodTypes": {
        "o": "Other",
        "b": "Breakfast",
        "s": "Snack",
        "l": "Main meal",
        "d": "Late Dinner"
    }
}

export default en;