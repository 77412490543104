import _ from 'lodash';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-virtualized';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';

import api from '../../utils/api';
import { defaultStyle } from '../../utils/style';
import { filterCiAi } from '../../utils/filter';
import { AppContext } from '../../contexts/AppContext';


class UserDetail extends Component {

    static propTypes = {
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        data: PropTypes.object
    }

    constructor(props, context) {
        super(props);

        this.roleOptions = [
            { value: false, label: context.translate('UserAdmin.RoleUser') },
            { value: true, label: context.translate('UserAdmin.RoleAdmin') }
        ]
        this.state = {
            role: _.find(this.roleOptions, { value: props.data.isAdmin })
        }

        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
    }

    toggle() {
        let { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    async save() {
        let { role } = this.state;
        let { onSubmit, data } = this.props;
        let { translate } = this.context;

        try {
            let requestData = {
                isAdmin: role.value
            };
            let response = await api.updateUser(data.id, requestData);
            if (onSubmit) {
                onSubmit(response.data);
            }
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    render() {
        let { translate } = this.context;
        let { data } = this.props;
        let { role } = this.state;

        return (
            <Modal isOpen={true} toggle={this.toggle} autoFocus={false}>
                <ModalHeader toggle={this.toggle}>{translate('UserAdmin.UserDetail')}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>{translate('UserAdmin.Email')}</Label>
                            <Input
                                value={(data || {}).email}
                                onChange={() => { }}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('UserAdmin.Role')}</Label>
                            <Select
                                options={this.roleOptions}
                                value={role}
                                onChange={role => this.setState({ role })}
                                placeholder=" "
                                autoFocus={true}
                                isClearable={false}
                                styles={defaultStyle()}
                                noOptionsMessage={() => translate('Selectbox.NoOptions')}
                                filterOption={filterCiAi}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>{translate('Btn.Save')}</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>{translate('Btn.Cancel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

UserDetail.contextType = AppContext;

export default UserDetail;
