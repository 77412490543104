import _ from 'lodash';
import * as toastr from 'toastr';
import classnames from 'classnames';
import memoize from 'memoize-one';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import ReactTooltip from 'react-tooltip';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button, FormFeedback } from 'reactstrap';
import Select from 'react-select-virtualized';

import api from '../../utils/api';
import { apply } from '../../utils/validation';
import { arraySort } from '../../utils/sort';
import { categoryStyle, defaultStyle } from '../../utils/style';
import { filterCiAi } from '../../utils/filter';
import { AppContext } from '../../contexts/AppContext';
import { FC_FAVORITE, FC_OTHER, FC_ALL } from '../../constants/foodCategory';

import './energyInputDialog.scss';
import { getHealthIco } from '../../constants/HealthyIndex';
import { getImgUrl } from '../../utils/Img';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function getRecipeImgUrl(food, foodItems) {
    if (food) {
        if (food.recipeImg) {
            return getImgUrl(food.recipeImg);
        }

        if (food.id && foodItems) {
            let foodItem = foodItems[`${food.id}`];
            if (foodItem && foodItem.recipeImg) {
                return getImgUrl(foodItem.recipeImg);
            }
        }
    }

    return null;
}

class EnergyInputDialog extends Component {

    static propTypes = {
        isOpen: PropTypes.bool,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        foodTypes: PropTypes.array,
        foodItems: PropTypes.object,
        categories: PropTypes.array,
        date: PropTypes.object,
        data: PropTypes.object,
        asUser: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.state = {
            food: props.data && props.data.foodItemId ? {
                label: props.data.foodName,
                value: props.data.foodItemId,
                id: props.data.foodItemId,
                name: props.data.foodName,
                energy: props.data.foodEnergy,
                unit: props.data.foodUnit
            } : null,
            foodType: this.initFoodTypes(props.data, props.foodTypes),
            foodCategory: this.initFoodCategory(props.categories),
            amount: (props.data || {}).quantity || null,
            time: moment(`${moment(props.date).startOf('date').format('YYYY-MM-DD')}T${moment().startOf('minute').format('HH:mm:ss')}`),
            errors: null,
            note: '',
            recipeText: '',
            isRecipeOpen: false
        }

        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.selectFood = this.selectFood.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        this.mouseEnterOnRecipe = this.mouseEnterOnRecipe.bind(this);

        this.memGetFoodItemOptions = memoize(this.getFoodItemOptions);
    }

    initFoodTypes(eInput, foodTypes) {
        let result = null;

        if (eInput && eInput.foodTypeId && foodTypes) {
            result = _.find(foodTypes, { id: eInput.foodTypeId });

            if (!result) {
                result = {
                    value: eInput.foodTypeId,
                    id: eInput.foodTypeId,
                    name: eInput.foodTypeName,
                    label: eInput.foodTypeName
                };
            }
        }

        return result;
    }

    initFoodCategory(categories) {
        let result = null;

        if (categories && categories.length && categories[0].id !== FC_ALL) {
            result = categories[0];
        }

        return result;
    }

    toggle() {
        let { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    async save() {
        if (this.validate()) {
            let { foodType, food, time, amount } = this.state;
            let { onSubmit, data, asUser } = this.props;
            let { translate } = this.context;

            try {
                let requestData = {
                    foodTypeId: (foodType || {}).id,
                    foodItemId: (food || {}).id,
                    time: time.toISOString(true),
                    amount,
                    asUser
                };
                let response = data && data.id
                    ? await api.updateEnergyInput(data.id, requestData)
                    : await api.addEnergyInput(requestData);
                if (onSubmit) {
                    onSubmit(response.data);
                }
            } catch (error) {
                console.log(error);
                toastr.error(translate('Err.System'));
            }
        }
    }

    async delete() {
        let { translate } = this.context;
        let { onSubmit, data, asUser } = this.props;

        if (!data || !data.id) {
            return;
        }

        let confirm = window.confirm(translate('EnergyInput.DeleteConfirm'));
        if (confirm) {
            try {
                let response = await api.deleteEnergyInput(data.id, { asUser });
                toastr.success(translate('EnergyInput.DeleteSuccess'));

                if (onSubmit) {
                    onSubmit(response.data);
                }
            } catch (error) {
                console.log(error);
                toastr.error(translate('Err.System'));
            }
        }
    }

    validate() {
        let { food, amount, errors } = this.state;

        errors = {};

        apply(errors, 'Food', 'Required', !!food);
        apply(errors, 'Amount', 'Required', amount > 0);

        let result = !Object.keys(errors).length;

        this.setState({ errors });
        return result;
    }

    getFoodItemOptions(foods, category) {
        //console.log('getFoodItemOptions begin', { foods });
        let result = _.map(foods || []).filter(o => {
            if (!category || !category.id) {
                return true;
            }
            switch (category.id) {
                case FC_FAVORITE:
                    return o.isFavorite;
                case FC_OTHER:
                    return !o.categories || !o.categories.length;
                default:
                    return _.some(o.categories || [], { id: category.id });
            }
        }).map(o => ({
            ...o,
            value: o.id,
            label: o.name
        }));
        arraySort(result, 'name');
        //console.log('getFoodItemOptions end', new Date().getTime());
        return result;
    }

    selectFood(food) {
        let { note, recipeText } = food || {};
        let { errors } = this.state;

        this.setState({ food, note, recipeText }, () => {
            if (errors) {
                this.validate();
            }
        });
    }

    getEnergyText(energy, amount, unit, language) {
        unit = unit || '?';
        if (energy === null || energy === undefined) {
            return '';
            //return `? kJ / 100 ${unit}`;
        }

        amount = amount || 0;
        let value = Math.round(energy * amount / 100);
        var result = `${value ? value.toLocaleString(language) : value} kJ | ${energy ? Math.round(energy).toLocaleString(language) : energy} kJ/100 ${unit}`;
        return result;
    }

    getAmountLabel(unit, translate) {
        let result = translate('EnergyInput.Amount');
        if (unit) {
            result += ` [${unit}]`;
        }
        return result;
    }

    changeCategory(foodCategory) {
        if (foodCategory && foodCategory.id === FC_ALL) {
            foodCategory = null;
        }
        this.setState({
            foodCategory,
            food: null,
            amount: null,
            note: null,
            recipeText: null
        }, () => {
            if (this.state.errors) {
                this.validate();
            }
        });
    }

    openRecipe() {
        this.setState({ isRecipeOpen: true });
    }

    closeRecipe() {
        this.setState({ isRecipeOpen: false });
    }

    getRecipeText(recipeText, food, foodItems) {
        if (recipeText) {
            return recipeText;
        }

        if (food && foodItems && foodItems[`${food.id}`]) {
            return foodItems[`${food.id}`].recipeText;
        }

        return '';
    }

    getNote(note, food, foodItems) {
        if (note) {
            return note;
        }

        if (food && foodItems && foodItems[`${food.id}`]) {
            return foodItems[`${food.id}`].note;
        }

        return '';
    }

    mouseEnterOnRecipe() {
        let { recipeText } = this.state;

        if (!!recipeText) this.openRecipe();
    }

    render() {
        let { isOpen, foodItems, foodTypes, categories, data } = this.props;
        let { translate, language } = this.context;
        let { food, foodType, foodCategory, amount, errors, note, recipeText, isRecipeOpen } = this.state;
        let { energy, unit, healthyIndex } = (food ? foodItems[food.id] : null) || {};

        let foodItemOptions = this.memGetFoodItemOptions(foodItems, foodCategory);
        let energyText = this.getEnergyText(energy, amount, unit, language);
        let amountLabel = this.getAmountLabel(unit, translate);
        note = this.getNote(note, food, foodItems);
        let showNote = !!note;
        recipeText = this.getRecipeText(recipeText, food, foodItems);
        let recipeImg = getRecipeImgUrl(food, foodItems);
        let showRecipe = !!recipeText;
        let showDelete = !!data && !!data.id;
        let autoFocus = !data || !data.id;
        let isFoodInvalid = errors && errors.Food;
        let health = healthyIndex === null || healthyIndex === undefined ? -1 : healthyIndex;

        // console.log('EnergyInputDialog.render', { recipeText, recipeImg, food, foodItems });

        return (
            <Modal
                className="energy-input-modal"
                isOpen={isOpen}
                toggle={this.toggle}
                autoFocus={false}
            >
                <ModalHeader toggle={this.toggle}>{translate('EnergyInput.Title')}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>{translate('EnergyInput.FoodType')}</Label>
                            <Select
                                options={foodTypes}
                                value={foodType}
                                onChange={foodType => this.setState({ foodType })}
                                placeholder={translate('FoodType.Placeholder')}
                                isSearchable={false}
                                styles={defaultStyle()}
                                noOptionsMessage={() => translate('Selectbox.NoOptions')}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('EnergyInput.FoodCategory')}</Label>
                            <Select
                                options={categories}
                                value={foodCategory}
                                onChange={this.changeCategory}
                                placeholder={translate('FoodCategory.All')}
                                styles={categoryStyle}
                                noOptionsMessage={() => translate('Selectbox.NoOptions')}
                                filterOption={filterCiAi}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label
                                className="extended-label"
                                onClick={e => { if (showRecipe) this.openRecipe(); }}
                            >
                                <span
                                    onMouseEnter={this.mouseEnterOnRecipe}
                                >{translate('EnergyInput.FoodItem')}</span>
                                {showRecipe &&
                                    <span
                                        onMouseEnter={this.mouseEnterOnRecipe}
                                    ><i className="fa fa-info-circle" aria-hidden="true"></i></span>
                                }
                                <span className="fill-row"></span>
                                {health >= 0 &&
                                    <Fragment>
                                        <span className="health-lbl-text">{translate(`HealthIndex.${health}`) + ' '}</span>
                                        <span className="health-lbl-text">{translate('EnergyInput.HealthPostfix')}</span>
                                        <span className="health-lbl-ico"><i className={classnames(`fa fa-${getHealthIco(health)}`)} aria-hidden="true"></i></span>
                                    </Fragment>
                                }
                            </Label>
                            <Select
                                options={foodItemOptions}
                                value={food}
                                onChange={this.selectFood}
                                placeholder=" "
                                autoFocus={autoFocus}
                                isClearable={false}
                                className={classnames({ 'is-invalid': isFoodInvalid })}
                                styles={defaultStyle({ isInvalid: isFoodInvalid })}
                                noOptionsMessage={() => translate('Selectbox.NotFound')}
                                filterOption={filterCiAi}
                            />
                            <FormFeedback>{translate(`EnergyInput.Err.Food${(errors || {}).Food}`)}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label data-tip={note}>
                                <span>{amountLabel}</span>
                                {showNote &&
                                    <span> <i className="fa fa-info-circle" aria-hidden="true"></i></span>
                                }
                            </Label>
                            <Input
                                type="number"
                                value={amount || ''}
                                onChange={e => {
                                    this.setState({ amount: parseFloat(e.target.value) }, () => {
                                        if (errors) {
                                            this.validate();
                                        }
                                    });
                                }}
                                invalid={errors && !!errors.Amount}
                            />
                            <FormFeedback>{translate(`EnergyInput.Err.Amount${(errors || {}).Amount}`)}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('EnergyInput.Energy')}</Label>
                            <Input
                                value={energyText || ''}
                                onChange={() => { }}
                                disabled
                            />
                        </FormGroup>
                        <ReactTooltip
                            className="multiline imsp-tooltip"
                            //place="bottom"
                            //type="custom"
                            html={true}
                        />
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>{translate('Btn.Save')}</Button>{' '}
                    {showDelete &&
                        <Button color="warning" onClick={this.delete}>{translate('Btn.Delete')}</Button>
                    }
                    <Button color="secondary" onClick={this.toggle}>{translate('Btn.Cancel')}</Button>
                </ModalFooter>
                <div
                    className={classNames('recipe-container', { show: isRecipeOpen })}
                    onMouseLeave={e => this.closeRecipe()}
                >
                    <div className="recipe-header">
                        <div></div>
                        <div
                            className='close-container'
                            onClick={e => this.closeRecipe()}
                        >
                            <FontAwesomeIcon icon="fa-solid fa-times" />
                        </div>
                    </div>
                    <div className="recipe-body">
                        {recipeImg &&
                            <div className='recipe-img-container'>
                                <img src={recipeImg} alt='recipe img' />
                            </div>
                        }
                        <div
                            className="recipe-content"
                            dangerouslySetInnerHTML={{ __html: recipeText }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

EnergyInputDialog.contextType = AppContext;

export default EnergyInputDialog;