import * as toastr from 'toastr';
import React, { Component } from 'react';

import api from '../../utils/api';
import SimpleTab from '../SimpleTab/SimpleTab';
import { AppContext } from '../../contexts/AppContext';
import ActivityDetail from './ActivityDetail';


class ActivityAdmin extends Component {

    static contextType = AppContext;

    constructor() {
        super();
        this.state = {
            activities: [],
            detail: null
        }

        this.onEdit = this.onEdit.bind(this);
        this.onAdd = this.onAdd.bind(this);
    }

    componentDidMount() {
        this.loadActivities();
    }

    async loadActivities() {
        try {
            let response = await api.getActivities();
            let activities = (response.data || []).map(o => ({
                ...o,
                //role: o.isAdmin ? translate('ActivityAdmin.RoleAdmin') : translate('ActivityAdmin.RoleUser')
            }));
            this.setState({ activities });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    onEdit(item) {
        let detail = { ...item, ts: new Date().getTime() };
        this.setState({ detail });
    }

    onAdd() {
        let detail = { ts: new Date().getTime() };
        this.setState({ detail });
    }

    render() {
        let { translate } = this.context;
        let { activities, detail } = this.state;

        let showDetail = !!detail;

        return (
            <div className="page-content activity-admin">
                <div className="panel-header">{translate('ActivityAdmin.Title')}</div>
                <SimpleTab
                    cols={[
                        { key: 'name', name: translate('ActivityAdmin.Name'), style: { width: '50%' } },
                        { key: 'energyExpenditure50kg', name: translate('ActivityAdmin.Energy50'), style: { width: '15%' }, valueType: 'number' },
                        { key: 'energyExpenditure71kg', name: translate('ActivityAdmin.Energy71'), style: { width: '15%' }, valueType: 'number' },
                        { key: 'energyExpenditure92kg', name: translate('ActivityAdmin.Energy92'), style: { width: '15%' }, valueType: 'number' },
                        { key: 'deleted', name: translate('ActivityAdmin.Deleted'), valueType: 'bool' }
                    ]}
                    data={activities}
                    onEdit={this.onEdit}
                    onAdd={this.onAdd}
                />

                {showDetail &&
                    <ActivityDetail
                        key={detail.ts}
                        data={detail}
                        onClose={() => this.setState({ detail: null })}
                        onSubmit={() => {
                            this.loadActivities()
                            this.setState({ detail: null });
                        }}
                    />
                }
            </div>
        );
    }
}

export default ActivityAdmin;