import React, { Component } from 'react';
import { Helmet } from "react-helmet";
import { Route, Switch } from 'react-router';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import i18n from './i18n/i18n';
import { detectAppLanguage, setLngToLocalStore } from './utils/language';
import PropTypes from 'prop-types';

import "react-datepicker/dist/react-datepicker.css";
import './style/reactDatePicker.scss';
import './style/reactSelectVirtualized.scss';
import './app.scss'
import { AppContext } from './contexts/AppContext';
import api from './utils/api';
import { AuthorizedContext } from './components/AuthorizedContext';
import Info from './components/Info/Info';
//import { Layout } from './components/Layout';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {  } from '@fortawesome/free-regular-svg-icons'
library.add(faTimes)


window.language = detectAppLanguage();

export default class App extends Component {
    static displayName = App.name;

    static childContextTypes = {
        language: PropTypes.string,
        translate: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        console.log('init App', new Date().getTime(), window.location);

        this.i18n = i18n(window.language);
        this.state = {
            language: window.language
        }

        this.translate = this.translate.bind(this);
        this.setLanguage = this.setLanguage.bind(this);
    }

    componentDidMount() {
        api.setLangAsync(this.state.language);
    }

    getChildContext() {
        return {
            language: this.state.language,
            translate: this.translate
        };
    }

    translate(key, options) {
        if (options) {
            return this.i18n.t(key, options);
        }

        //console.log('translate', { key });
        return this.i18n.t(key);
    }

    async setLanguage(lang) {
        if (this.state.language !== lang) {
            window.language = lang;
            setLngToLocalStore(lang);
            this.i18n.changeLanguage(lang);
            this.setState({ language: lang }, async () => {
                await api.setLangAsync(lang);
            });
        }
    }

    render() {
        let { language } = this.state;

        let context = {
            language,
            translate: this.translate,
            setLanguage: this.setLanguage
        };
        return (
            <AppContext.Provider value={context}>
                <Helmet
                    htmlAttributes={{ lang: language }}
                >
                    <title>Food4Fit</title>
                </Helmet>
                <Switch>
                    <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
                    <Route path='/info' render={props => {
                        return (
                            <Info />
                        );
                    }} />
                    <AuthorizeRoute component={AuthorizedContext} />
                </Switch>
            </AppContext.Provider>
        );
    }
}
