import classnames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AppContext } from '../../contexts/AppContext';

import './elementGraph.scss';


class ElementGraph extends Component {

    static propTypes = {
        data: PropTypes.object
    }

    constructor() {
        super();

        this.state = {
            showtip: false,
            tip: '',
            pos: 0
        }
    }

    getItems(data, translate, language) {
        let { fat, carbohydrat, protein, fiber, sodium, satFat } = data || {};
        let result = [
            this.getItem(fat, 'ElementGraph.Fat', translate, language),
            this.getItem(carbohydrat, 'ElementGraph.Carbohydrate', translate, language),
            this.getItem(protein, 'ElementGraph.Protein', translate, language),
            { delimiter: true },
            this.getItem(satFat, 'ElementGraph.SatFat', translate, language),
            this.getItem(fiber, 'ElementGraph.Fiber', translate, language),
            this.getItem(sodium, 'ElementGraph.Sodium', translate, language)
        ];
        return result;
    }

    getItem(data, key, translate, language) {
        let result = {
            name: translate(key),
            value: this.getRate(data, language),
            type: this.getType(data),
            tip: translate(key + 'Tip')
                .replace('{value}', this.getValue(data, language))
                .replace('{rate}', this.getRate(data, language))
                .replace('{gl}', _.get(data, 'gl', '{gl}'))
                .replace('{gh}', _.get(data, 'gh', '{gh}'))
        }
        return result;
    }

    getValue(item, language) {
        let result = ((item || {}).value || 0);
        result = Math.round(result);
        return result.toLocaleString(language);
    }

    getRate(item, language) {
        let result = ((item || {}).rate || 0) * 100;
        result = Math.min(result, 999);
        result = result < 10 ? Math.round(result, 1) : Math.round(result);
        return result.toLocaleString(language);
    }

    getType(item) {
        let { level } = item || {};
        switch (level) {
            case 0:
                return 'good';
            case 1:
                return 'warn';
            default:
                return 'bad';
        }
    }

    render() {
        let { translate, language } = this.context;
        let { data } = this.props;
        let { showtip, tip, pos } = this.state;

        let items = this.getItems(data, translate, language);
        //console.log('data', data);

        return (
            <div className="element-graph-container f4f-panel">
                <div className="element-graph-title">
                    <div className="title-item">{translate('ElementGraph.RateTitle')}</div>
                    <div className="title-item">{translate('ElementGraph.AmountTitle')}</div>
                </div>
                <div className="element-graph-items">
                    {items.map((o, i) => {
                        if (o.delimiter) {
                            return (
                                <div
                                    key={i}
                                    className="element-graph-delimiter">
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={i}
                                    className={classnames('element-graph-item', o.type)}
                                >
                                    <div
                                        className="element-container"
                                        onMouseEnter={e => {
                                            let cRect = e.currentTarget.getBoundingClientRect();
                                            let pRect = e.currentTarget.parentElement.parentElement.getBoundingClientRect();
                                            let pos = Math.round(cRect.left - pRect.left + (cRect.width / 2));
                                            this.setState({
                                                tip: o.tip,
                                                showtip: true,
                                                pos
                                            });
                                        }}
                                        onMouseLeave={e => {
                                            this.setState({
                                                tip: '',
                                                showtip: false,
                                                pos: 0
                                            });
                                        }}
                                    >
                                        <div className="value-container">
                                            <div className="value">{o.value}</div>
                                        </div>
                                        <div className="label">{o.name}</div>
                                    </div>
                                </div>
                            );
                        }
                    })}
                    {showtip &&
                        <div className="custom-tooltip">
                            <div className="tooltip-arrow" style={{ left: `${pos}px` }} />
                            {tip}
                        </div>
                    }
                </div>
            </div>
        );
    }
}

ElementGraph.contextType = AppContext;

export default ElementGraph;