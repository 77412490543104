import * as toastr from 'toastr';
import moment from 'moment';
import DatePicker from "react-datepicker";
import PropTypes from 'prop-types';
import React, { Component } from "react";
import Select from 'react-select-virtualized';

import api from '../../utils/api';
import DateInput from "./DateInput";
import { transparentStyle } from '../../utils/style';
import { AppContext } from "../../contexts/AppContext";

import './report.scss';
import { RT_BASIC, RT_EXTENDED, RT_SPORT } from '../../constants/reportType';
import { arraySort } from '../../utils/sort';


class Report extends Component {

    static propsTypes = {
        shares: PropTypes.array,
        selectedShare: PropTypes.object,
        onShareSelect: PropTypes.func,
        isAdmin: PropTypes.bool
    }

    constructor() {
        super();

        let today = moment().startOf('date');
        this.state = {
            loading: false,
            data: null,
            from: sessionStorage.getItem('report_from')
                ? moment(sessionStorage.getItem('report_from'))
                : moment(today).subtract(30, 'days'),
            to: sessionStorage.getItem('report_to')
                ? moment(sessionStorage.getItem('report_to'))
                : moment(today).subtract(1, 'days'),
            max: moment(today).subtract(1, 'days')
        };

        this.exportReport = this.exportReport.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let { selectedShare } = this.props;

        this.setState({ loading: true }, async () => {
            try {
                let asUser = (selectedShare || {}).id || '';
                let response = await api.getReport({ from: this.state.from.format('YYYY-MM-DD'), to: this.state.to.format('YYYY-MM-DD'), asUser });
                let data = response.data || {};
                //console.log('data', data);
                this.setState({ data, loading: false });
            } catch (error) {
                console.log(error);
                toastr.error(this.context.translate('Err.System'));
                this.setState({ loading: false });
            }
        });
    }

    async exportReport(reportType = RT_BASIC) {
        let { translate } = this.context;
        let { selectedShare } = this.props;

        this.setState({ loading: true }, async () => {
            try {
                let params = { from: this.state.from.format('YYYY-MM-DD'), to: this.state.to.format('YYYY-MM-DD'), type: reportType };
                let asUser = (selectedShare || {}).id || '';
                if (asUser) {
                    params.asUser = asUser;
                }
                let response = await api.exportReport(params);

                var type = response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
                var blob = new Blob([response.data], { type });

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                let rt = reportType === RT_EXTENDED
                    ? `${translate('Reports.Ext')} `
                    : reportType === RT_SPORT
                        ? `${translate('Reports.Sport')} `
                        : ''
                link.download = `Food4Fit ${this.state.from.format('YYYY.MM.DD')} - ${this.state.to.format('YYYY.MM.DD')} ${rt}${translate('Reports.Report')}.docx`;
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error);
                toastr.error(translate('Err.System'));
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    async exportDailyReport() {
        let { translate } = this.context;
        let { selectedShare } = this.props;

        this.setState({ loading: true }, async () => {
            try {
                let params = { from: this.state.from.format('YYYY-MM-DD'), to: this.state.to.format('YYYY-MM-DD') };
                let asUser = (selectedShare || {}).id || '';
                if (asUser) {
                    params.asUser = asUser;
                }
                let response = await api.exportDailyReport(params);

                var type = response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                var blob = new Blob([response.data], { type });

                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = `Food4Fit ${this.state.from.format('YYYY.MM.DD')} - ${this.state.to.format('YYYY.MM.DD')} ${translate('Reports.DailyReport')}.xlsx`;
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.log(error);
                toastr.error(translate('Err.System'));
            } finally {
                this.setState({ loading: false });
            }
        });
    }

    changePeriod(date, prop) {
        let value = moment(date).startOf('date');
        let state = {};
        state[prop] = value;
        sessionStorage.setItem([`report_${prop}`], value.toISOString());
        this.setState(state, () => this.loadData())
    }

    render() {
        let { translate, language } = this.context;
        let { loading, data, from, to, max } = this.state;
        let { shares, selectedShare, onShareSelect, isAdmin } = this.props;

        let {
            isSomeData,
            energyText, energyStandardText, energyRateValueText, energyState,
            fatRateValueText, fatText, fatPreviewStandardText, fatStandardRateText, fatState, fatRateStandardText, fatRateState,
            carbohydratRateValueText, carbohydratText, carbohydratPreviewStandardText, carbohydratStandardRateText, carbohydratState, carbohydratRateStandardText, carbohydratRateState,
            proteinRateValueText, proteinText, proteinPreviewStandardText, proteinStandardRateText, proteinState, proteinRateStandardText, proteinRateState,
            fiberValueText, fiberStandardText, fiberRateText, fiberState,
            saltValueText, saltValueStandardText, saltRateText, saltState,
            satFatRateValueText, satFatRateValueStandardText, satFatState, satFatStandardRateText,
            waterValueText, waterValueStandardText, waterRateText, waterState,
            alcoholValueText, alcoholValueStandardText, alcoholRateText, alcoholState,
            fruitText, fruitStandardText, fruitRateText, fruitState,
            milkValueText, milkValueStandardText, milkRateText, milkState,
            fishValueText, fishValueStandardText, fishRateText, fishState,
            foodsPerDayText, foodsPerDayStandardText, foodsPerDayRateText, foodsPerDayState,
            energySummary, fatSummary, carbohydratSummary,
            proteinSummary, fiberSummary, satFatSummary, waterSummary,
            sodiumSummary, alcoholSummary, fruitSummary, milkSummary,
            fishSummary, regularSummary
        } = data || {};

        let showLoading = loading && !data;
        let showData = !showLoading && isSomeData;
        let showExtended = showData && isAdmin;
        let showNoData = !showLoading && !isSomeData;

        let showShares = !!(shares && shares.length);
        let shareOptions = showShares ? arraySort(shares, 'label') : [];

        //console.log('period', {
        //    from: from.format('DD. MM. YYYY  HH:mm:ss'),
        //    to: to.format('DD. MM. YYYY  HH:mm:ss'),
        //    ssFrom: sessionStorage.getItem('report_from'),
        //    ssTo: sessionStorage.getItem('report_to')
        //});

        return (
            <div className="report-container">
                <div className="report-content">
                    <div className="panel-header">{translate('Reports.Title')}</div>
                    <div className="panel-section">
                        {showShares &&
                            <div className="user-selector-container">
                                <div className="user-selector-label">{translate('Reports.User')}:</div>
                                <Select
                                    classNamePrefix="share-select"
                                    options={shareOptions}
                                    value={selectedShare}
                                    onChange={item => onShareSelect(item)}
                                    isSearchable={true}
                                    styles={transparentStyle}
                                    isClearable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                        }
                        <div className="report-period-container">
                            <div className="report-period-label">{translate('Reports.Period.Label')}:</div>
                            <div className="report-period-value">
                                <div className="report-period-from">
                                    <DatePicker
                                        selected={from.valueOf()}
                                        onChange={date => this.changePeriod(date, 'from')}
                                        maxDate={to.valueOf()}
                                        customInput={<DateInput />}
                                        locale={language}
                                        popperModifiers={{
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="report-period-between">{translate('Reports.Period.Between')}</div>
                                <div className="report-period-to">
                                    <DatePicker
                                        selected={to.valueOf()}
                                        onChange={date => this.changePeriod(date, 'to')}
                                        minDate={from.valueOf()}
                                        maxDate={max.valueOf()}
                                        customInput={<DateInput />}
                                        locale={language}
                                        popperModifiers={{
                                            preventOverflow: {
                                                enabled: true,
                                                escapeWithReference: false,
                                                boundariesElement: 'viewport'
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {showData &&
                        <div className="export-report">
                            <div
                                className="report-btn"
                                onClick={() => this.exportReport(RT_BASIC)}
                            >{translate('Reports.Export')}</div>
                        </div>
                    }
                    {showExtended &&
                        <div className="export-report-ext">
                            <div
                                className="report-btn"
                                onClick={() => this.exportReport(RT_EXTENDED)}
                            >{translate('Reports.ExportExt')}</div>
                            <div
                                className="report-btn"
                                onClick={() => this.exportReport(RT_SPORT)}
                            >{translate('Reports.ExportSport')}</div>
                        </div>
                    }
                    {showData &&
                        <div className="export-report-ext">
                            <div
                                className="report-btn"
                                onClick={() => this.exportDailyReport()}
                            >{translate('Reports.Daily')}</div>
                        </div>
                    }
                    {showData &&
                        <div className="panel-section">
                            <div className="section-header">{translate('Reports.Main.Title')}</div>
                            <div className="section-table">
                                <table>
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="cell">{translate('Reports.Main.Fat')}</td>
                                            <td className={`cell center state${fatRateState}`}>{fatRateValueText}</td>
                                            <td className="cell center">{fatRateStandardText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell">{translate('Reports.Main.Carbohydrate')}</td>
                                            <td className={`cell center state${carbohydratRateState}`}>{carbohydratRateValueText}</td>
                                            <td className="cell center">{carbohydratRateStandardText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell">{translate('Reports.Main.Protein')}</td>
                                            <td className={`cell center state${proteinRateState}`}>{proteinRateValueText}</td>
                                            <td className="cell center">{proteinRateStandardText}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {showData &&
                        <div className="panel-section">
                            <div className="section-header">{translate('Reports.All.Title')}</div>
                            <div className="section-table">
                                <table>
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th className="cell"></th>
                                            <th className="cell center">{translate('Reports.All.Mean')}</th>
                                            <th className="cell center">{translate('Reports.All.Standard')}</th>
                                            <th className="cell center">{translate('Reports.All.Rate')}</th>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Energy')}</td>
                                            <td className="cell center">{energyText}</td>
                                            <td className="cell center">{energyStandardText}</td>
                                            <td className={`cell center state${energyState}`}>{energyRateValueText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Fat')}</td>
                                            <td className="cell center">{fatText}</td>
                                            <td className="cell center">{fatPreviewStandardText}</td>
                                            <td className={`cell center state${fatState}`}>{fatStandardRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Carbohydrate')}</td>
                                            <td className="cell center">{carbohydratText}</td>
                                            <td className="cell center">{carbohydratPreviewStandardText}</td>
                                            <td className={`cell center state${carbohydratState}`}>{carbohydratStandardRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Protein')}</td>
                                            <td className="cell center">{proteinText}</td>
                                            <td className="cell center">{proteinPreviewStandardText}</td>
                                            <td className={`cell center state${proteinState}`}>{proteinStandardRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Fiber')}</td>
                                            <td className="cell center">{fiberValueText}</td>
                                            <td className="cell center">{fiberStandardText}</td>
                                            <td className={`cell center state${fiberState}`}>{fiberRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Sodium')}</td>
                                            <td className="cell center">{saltValueText}</td>
                                            <td className="cell center">{saltValueStandardText}</td>
                                            <td className={`cell center state${saltState}`}>{saltRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Sfat')}</td>
                                            <td className="cell center">{satFatRateValueText}</td>
                                            <td className="cell center">{satFatRateValueStandardText}</td>
                                            <td className={`cell center state${satFatState}`}>{satFatStandardRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Fluids')}</td>
                                            <td className="cell center">{waterValueText}</td>
                                            <td className="cell center">{waterValueStandardText}</td>
                                            <td className={`cell center state${waterState}`}>{waterRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Alcohol')}</td>
                                            <td className="cell center">{alcoholValueText}</td>
                                            <td className="cell center">{alcoholValueStandardText}</td>
                                            <td className={`cell center state${alcoholState}`}>{alcoholRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Fruit')}</td>
                                            <td className="cell center">{fruitText}</td>
                                            <td className="cell center">{fruitStandardText}</td>
                                            <td className={`cell center state${fruitState}`}>{fruitRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Milk')}</td>
                                            <td className="cell center">{milkValueText}</td>
                                            <td className="cell center">{milkValueStandardText}</td>
                                            <td className={`cell center state${milkState}`}>{milkRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Fish')}</td>
                                            <td className="cell center">{fishValueText}</td>
                                            <td className="cell center">{fishValueStandardText}</td>
                                            <td className={`cell center state${fishState}`}>{fishRateText}</td>
                                        </tr>
                                        <tr>
                                            <td className="cell title">{translate('Reports.All.Meal')}</td>
                                            <td className="cell center">{foodsPerDayText}</td>
                                            <td className="cell center">{foodsPerDayStandardText}</td>
                                            <td className={`cell center state${foodsPerDayState}`}>{foodsPerDayRateText}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }
                    {showData &&
                        <div className="panel-section">
                            <div className="section-header">{translate('Reports.Summary.Title')}</div>
                            <div className="section-paragraph">
                                <div className="paragraph-text">
                                    <p>{translate('Reports.Summary.Info')}</p>
                                </div>
                            </div>
                            <div className="section-paragraph">
                                <div className="paragraph-header">{translate('Reports.Summary.Quantity.Title')}</div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: energySummary }} />
                                </div>
                            </div>
                            <div className="section-paragraph">
                                <div className="paragraph-header">{translate('Reports.Summary.Quality.Title')}</div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: fatSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: carbohydratSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: proteinSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: fiberSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: sodiumSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: satFatSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: waterSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: alcoholSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: fruitSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: milkSummary }} />
                                </div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: fishSummary }} />
                                </div>
                            </div>
                            <div className="section-paragraph">
                                <div className="paragraph-header">{translate('Reports.Summary.Regular.Title')}</div>
                                <div className="paragraph-text">
                                    <p dangerouslySetInnerHTML={{ __html: regularSummary }} />
                                </div>
                            </div>
                        </div>
                    }
                    {showNoData &&
                        <p>{translate('Reports.Text')}</p>
                    }
                    {showLoading &&
                        <p>{translate('Reports.Loading')}</p>
                    }
                </div>
            </div>
        );
    }
}

Report.contextType = AppContext

export default Report;