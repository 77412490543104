export const HI_NORMAL = 0;
export const HI_UNHEALTHY = 1;
export const HI_HEALTHY = 2;

export function getHealthIco(value) {
    switch (value) {
        case HI_UNHEALTHY:
            return 'frown-o';
        case HI_HEALTHY:
            return 'smile-o';
        default:
            return 'meh-o';
    }
}