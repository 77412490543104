import * as toastr from 'toastr';
import React, { Component } from "react";

import api from "../../utils/api";
import pkg from '../../../package.json';
import { AppContext } from "../../contexts/AppContext";

import './info.scss';


class Info extends Component {

    constructor() {
        super();

        this.state = {
            info: {}
        }
    }

    componentDidMount() {
        this.loadInfo();
    }

    async loadInfo() {
        let { translate } = this.context;

        try {
            var response = await api.getInfo();
            var info = response.data || {};
            this.setState({ info });
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    render() {
        let { info } = this.state;

        return (
            <div className="info-container">
                <div className="info-content">
                    <div className="info-block">
                        <div className="block-title">Klientská aplikace</div>
                        <div className="block-item">
                            <div className="label">Název</div>
                            <div className="value">{pkg.name}</div>
                        </div>
                        <div className="block-item">
                            <div className="label">Verze</div>
                            <div className="value">{pkg.version}</div>
                        </div>
                    </div>
                    <div className="info-block">
                        <div className="block-title">Serverová aplikace</div>
                        <div className="block-item">
                            <div className="label">Název</div>
                            <div className="value">{info.name}</div>
                        </div>
                        <div className="block-item">
                            <div className="label">Verze</div>
                            <div className="value">{info.version}</div>
                        </div>
                        <div className="block-item">
                            <div className="label">Prostředí</div>
                            <div className="value">{info.env}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Info.contextType = AppContext;

export default Info;