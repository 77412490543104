import * as toastr from 'toastr';
import React, { Component } from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import api from '../../utils/api';
import { apply } from '../../utils/validation';
import { isError, ERR_BAD_CURRENT_PWD, ERR_INVALID_NEW_PWD } from '../../utils/error';
import { AppContext } from '../../contexts/AppContext';


const PwdMin = 6;
const PwdMax = 100;

class Password extends Component {

    initState = {
        data: null,
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
        errors: null
    };

    constructor(props) {
        super(props);

        this.state = this.initState;
    }

    componentDidMount() {
        this.loadPassword();
    }

    async loadPassword() {
        let { translate } = this.context;

        try {
            let response = await api.getPasswordInfo();
            this.setState({ data: response.data });
        } catch (error) {
            console.log(error);
            toastr.error(translate('Password.Err.Load'));
        }
    }

    async changePwd() {
        if (this.validate()) {
            let { currentPassword, newPassword } = this.state;
            let { translate } = this.context;

            try {
                let input = {
                    currentPassword,
                    newPassword
                };
                await api.changePassword(input);
                toastr.success(translate('Password.Changed'));
                this.setState(this.initState);
                this.loadPassword();
            } catch (error) {
                if (isError(error, ERR_BAD_CURRENT_PWD)) {
                    toastr.warning(translate('Password.Err.PasswordMismatch'));
                } else if (isError(error, ERR_INVALID_NEW_PWD)) {
                    toastr.warning(translate('Password.Err.InvalidPwd'));
                } else {
                    console.log(error);
                    toastr.error(translate('Err.System'));
                }
            }
        }
    }

    async newPwd() {
        if (this.validate()) {
            let { newPassword } = this.state;
            let { translate } = this.context;

            try {
                let input = {
                    newPassword
                };
                await api.setPassword(input);
                toastr.success(translate('Password.Set'));
                this.setState(this.initState);
                this.loadPassword();
            } catch (error) {
                if (isError(error, ERR_INVALID_NEW_PWD)) {
                    toastr.warning(translate('Password.Err.InvalidPwd'));
                } else {
                    console.log(error);
                    toastr.error(translate('Err.System'));
                }
            }
        }
    }

    validate() {
        let { currentPassword, newPassword, confirmPassword, data } = this.state;

        let errors = {};

        apply(errors, 'CurrentPwd', 'Required', !data || !data.hasPassword || !!currentPassword);
        apply(errors, 'NewPwd', 'Required', !!newPassword);
        apply(errors, 'NewPwd', 'Invalid', newPassword && newPassword.length >= PwdMin && newPassword.length <= PwdMax);
        apply(errors, 'ConfirmPwd', 'Invalid', newPassword === confirmPassword);

        let result = !Object.keys(errors).length;

        this.setState({ errors });
        return result;
    }

    render() {
        let { translate } = this.context;
        let { data } = this.state;

        //console.log('render', errors);

        if (data) {
            if (data.hasPassword) {
                return this.renderChange();
            } else {
                return this.renderNew();
            }
        } else {
            return (
                <div className="pwd-container">
                    <div className="panel-header">{translate('Password.Title')}</div>
                    <div className="text">{translate('Password.Loading')}</div>
                </div>
            );
        }
    }

    renderNew() {
        let { translate } = this.context;
        let { newPassword, confirmPassword, errors } = this.state;

        return (
            <div className="pwd-container">
                <div className="panel-header">{translate('Password.TitleNew')}</div>
                <div className="text">{translate('Password.NewInfo')}</div>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        this.newPwd();
                    }}
                >
                    <FormGroup>
                        <Label>{translate('Password.NewPwd')}</Label>
                        <Input
                            type="password"
                            value={newPassword}
                            onChange={e => this.setState({ newPassword: e.target.value }, () => { if (errors) this.validate() })}
                            invalid={errors && !!errors.NewPwd}
                            autoFocus
                        />
                        <FormFeedback>{translate(`Password.Err.NewPwd${(errors || {}).NewPwd}`).replace('{min}', PwdMin).replace('{max}', PwdMax)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Password.ConfirmPwd')}</Label>
                        <Input
                            type="password"
                            value={confirmPassword}
                            onChange={e => this.setState({ confirmPassword: e.target.value }, () => { if (errors) this.validate() })}
                            invalid={errors && !!errors.ConfirmPwd}
                        />
                        <FormFeedback>{translate(`Password.Err.ConfirmPwd${(errors || {}).ConfirmPwd}`)}</FormFeedback>
                    </FormGroup>
                    <div className="buttons">
                        <Button
                            color="primary"
                            className="wide"
                        >{translate('Password.BtnSet')}</Button>
                    </div>
                </Form>
            </div>
        );
    }

    renderChange() {
        let { translate } = this.context;
        let { currentPassword, newPassword, confirmPassword, errors } = this.state;

        return (
            <div className="pwd-container">
                <div className="panel-header">{translate('Password.TitleChange')}</div>
                <Form
                    onSubmit={e => {
                        e.preventDefault();
                        this.changePwd();
                    }}
                >
                    <FormGroup>
                        <Label>{translate('Password.CurrentPwd')}</Label>
                        <Input
                            type="password"
                            value={currentPassword}
                            onChange={e => this.setState({ currentPassword: e.target.value }, () => { if (errors) this.validate() })}
                            invalid={errors && !!errors.CurrentPwd}
                            autoFocus
                        />
                        <FormFeedback>{translate(`Password.Err.CurrentPwd${(errors || {}).CurrentPwd}`)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Password.NewPwd')}</Label>
                        <Input
                            type="password"
                            value={newPassword}
                            onChange={e => this.setState({ newPassword: e.target.value }, () => { if (errors) this.validate() })}
                            invalid={errors && !!errors.NewPwd}
                        />
                        <FormFeedback>{translate(`Password.Err.NewPwd${(errors || {}).NewPwd}`).replace('{min}', PwdMin).replace('{max}', PwdMax)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Password.ConfirmPwd')}</Label>
                        <Input
                            type="password"
                            value={confirmPassword}
                            onChange={e => this.setState({ confirmPassword: e.target.value }, () => { if (errors) this.validate() })}
                            invalid={errors && !!errors.ConfirmPwd}
                        />
                        <FormFeedback>{translate(`Password.Err.ConfirmPwd${(errors || {}).ConfirmPwd}`)}</FormFeedback>
                    </FormGroup>
                    <div className="buttons">
                        <Button
                            color="primary"
                            className="wide"
                        >{translate('Password.Btn')}</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

Password.contextType = AppContext;

export default Password;
