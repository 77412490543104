import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-virtualized';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';

import api from '../../utils/api';
import { defaultStyle } from '../../utils/style';
import { AppContext } from '../../contexts/AppContext';
import FoodParamsControl from '../FoodParamsControl/FoodParamsControl';
import TranslationControl, { getTranslationValue } from '../TranslationControl/TranslationControl';
import { getRTValue } from '../../utils/ritchText';

import './recipeDetail.scss';
import ImgControl from '../ImgControl/ImgControl';
import { getImgUrl } from '../../utils/Img';


function initTranslations(translations) {
    translations = translations || [];
    translations.forEach(o => {
        o.preparation = getRTValue(o.preparation);
    });
    return translations;
}

// Vytvoří pole obrázků pro ImgControl z detailu receptu
function getImages(recipe) {
    let res = [];

    if (recipe && recipe.images && recipe.images.length) {
        for (let i = 0; i < recipe.images.length; i++) {
            const img = recipe.images[i];
            res.push({ id: img, imgUrl: getImgUrl(img)});
        }
    }

    return res;
}

export default class RecipeDetail extends Component {

    static contextType = AppContext;

    static propTypes = {
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        data: PropTypes.object
    }

    constructor(props, context) {
        super(props);

        let data = props.data || {};
        this.state = {
            translations: initTranslations(data.translations),
            duration: data.duration || 0,
            difficulty: this.getDifficulty(context.translate, data.difficulty || 1),
            servings: data.servings || 1,
            energy: data.energy || 0,
            weight: data.weight || 0,
            items: data.items || [],
            foods: [],
            languages: ['cs', 'en'],
            images: getImages(data)
        }

        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let { translate } = this.context;
        let { data } = this.props;

        try {
            let actions = [api.getLanguages(), api.getFoodCodes()];
            if (data.id) {
                actions.push(api.GetRecipe(data.id));
            }
            let [langResponse, foodsResponse, recipeResponse] = await Promise.all(actions);

            let state = {
                languages: langResponse.data || [],
                foods: foodsResponse.data || []
            };
            if (recipeResponse) {
                let { translations, duration, difficulty, servings, energy, weight, items } = recipeResponse.data || {};
                state = {
                    ...state,
                    translations: initTranslations(translations),
                    duration: duration || 0,
                    difficulty: this.getDifficulty(translate, difficulty),
                    servings: servings || 1,
                    energy: energy || 0,
                    weight: weight || 0,
                    items: (items || []).map(o => ({ ...o, value: o.quantity })),
                    images: getImages(recipeResponse.data)
                };
            }
            this.setState(state);
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    toggle() {
        let { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    async save() {
        let { translations, duration, difficulty, servings, energy, weight, items, images } = this.state;
        let { onSubmit, data } = this.props;
        let { translate } = this.context;

        try {
            translations = translations.map(o => {
                let preparation = o.preparation;
                if (preparation) {
                    preparation = preparation.toString('html');
                    if (preparation === '<p><br></p>') {
                        preparation = '';
                    }
                }
                return { ...o, preparation };
            }).filter(o => !!o.name || !!o.preparation);
            let requestData = {
                name: getTranslationValue(translations, 'name'),
                preparation: getTranslationValue(translations, 'preparation'),
                translations: translations,
                duration: duration ? parseInt(duration) : 0,
                difficulty: difficulty.value,
                servings: servings ? parseInt(servings) : 0,
                energy,
                weight,
                items: items.filter(o => !!o.id).map(o => {
                    o.quantity = parseFloat(o.value);
                    return o;
                })
            };
            let response = data.id
                ? await api.updateRecipe(data.id, requestData)
                : await api.addRecipe(requestData);
            let recipeId = data.id || response.data.id;

            // Add or remove images
            // console.log('add images', images, recipeId);
            if (images && images.length) {
                for (let i = 0; i < images.length; i++) {
                    const img = images[i];
                    if (img) {
                        if (img.new) {
                            let input = new FormData();
                            input.append('file', img.file);
                            await api.addRecipeImg(recipeId, input);
                        } else if (img.remove && img.id) {
                            await api.remRecipeImg(recipeId, img.id);
                        }
                    }
                }
            }

            if (onSubmit) {
                onSubmit(response.data);
            }
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    getDifficultyOptions(translate) {
        let result = [
            this.getDifficulty(translate, 1),
            this.getDifficulty(translate, 2),
            this.getDifficulty(translate, 3),
            this.getDifficulty(translate, 4),
            this.getDifficulty(translate, 5)
        ];
        return result;
    }

    getDifficulty(translate, value) {
        if (value >= 1 && value <= 5) {
            return { value, label: translate(`Difficulty.${value}`) };
        }
        return null;
    }

    render() {
        let { translate } = this.context;
        //let { translations, languages, duration, difficulty, servings, energy, weight, items, foods } = this.state;
        let { translations, languages, duration, difficulty, servings, items, foods, images } = this.state;

        let difOptions = this.getDifficultyOptions(translate);
        let parOptions = foods || [];
        //weight = (items || []).reduce((r, o) => r + parseFloat(o.value), 0);

        return (
            <Modal isOpen={true} toggle={this.toggle} autoFocus={false}>
                <ModalHeader toggle={this.toggle}>{translate('RecipeAdmin.RecipeDetail')}</ModalHeader>
                <ModalBody>
                    <Form>
                        <TranslationControl
                            label={translate('RecipeAdmin.Name')}
                            name="name"
                            languages={languages}
                            translations={translations}
                            onChange={translations => this.setState({ translations })}
                            autoFocus
                        />
                        <FormGroup>
                            <Label>{translate('RecipeAdmin.Duration')}</Label>
                            <Input
                                type="number"
                                value={duration}
                                onChange={e => this.setState({ duration: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('RecipeAdmin.Servings')}</Label>
                            <Input
                                type="number"
                                value={servings}
                                onChange={e => this.setState({ servings: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('RecipeAdmin.Difficulty')}</Label>
                            <Select
                                options={difOptions}
                                value={difficulty}
                                onChange={difficulty => this.setState({ difficulty })}
                                placeholder=" "
                                isClearable={false}
                                isSearchable={true}
                                styles={defaultStyle()}
                                className="recipe-diff-select"
                                classNamePrefix="recipe-diff-select"
                            />
                        </FormGroup>
                        <TranslationControl
                            label={translate('RecipeAdmin.Preparation')}
                            name="preparation"
                            languages={languages}
                            translations={translations}
                            onChange={translations => this.setState({ translations })}
                            ritchText
                        />
                        <FoodParamsControl
                            label={translate('RecipeAdmin.Items')}
                            options={parOptions}
                            params={items}
                            onChange={items => this.setState({ items })}
                        />
                        <ImgControl
                            label={translate('RecipeAdmin.Photo')}
                            images={images}
                            maxCount={1}
                            onChange={images => this.setState({ images })}
                        />
                        {/*
                        <FormGroup>
                            <Label>{translate('RecipeAdmin.Energy')}</Label>
                            <Input
                                type="number"
                                value={energy}
                                onChange={() => { }}
                                disabled
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('RecipeAdmin.Weight')}</Label>
                            <Input
                                type="number"
                                value={weight}
                                onChange={() => { }}
                                disabled
                            />
                        </FormGroup>
                        */}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>{translate('Btn.Save')}</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>{translate('Btn.Cancel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
