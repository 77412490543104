import React, { Component, Fragment } from 'react';
import { Route } from 'react-router';
import { Login } from './Login'
import { Logout } from './Logout'
import { ApplicationPaths, LoginActions, LogoutActions } from './ApiAuthorizationConstants';
import { AppContext } from '../../contexts/AppContext';

export default class ApiAuthorizationRoutes extends Component {

    static contextType = AppContext;

    render() {
        let { translate } = this.context;

        //console.log('render ApiAuthorizationRoutes');
        return (
            <Fragment>
                <div className="authorization-container">
                    <div className="logo-part">
                        <div className="logo-content">
                            <div className="logo-f4f">
                                <img alt="food4fit" src="/img/logo-food4fit.svg" />
                            </div>
                            <div className="logo-mze">
                                <img alt="MZe" src="/img/logo-mze.svg" />
                            </div>
                            <div className="logo-text">{translate('Logo.Text')}</div>
                        </div>
                    </div>
                    <div className="content-part">
                        <Route path={ApplicationPaths.Login} render={() => loginAction(LoginActions.Login)} />
                        <Route path={ApplicationPaths.LoginFailed} render={() => loginAction(LoginActions.LoginFailed)} />
                        <Route path={ApplicationPaths.LoginCallback} render={() => loginAction(LoginActions.LoginCallback)} />
                        <Route path={ApplicationPaths.Profile} render={() => loginAction(LoginActions.Profile)} />
                        <Route path={ApplicationPaths.Register} render={() => loginAction(LoginActions.Register)} />
                        <Route path={ApplicationPaths.LogOut} render={() => logoutAction(LogoutActions.Logout)} />
                        <Route path={ApplicationPaths.LogOutCallback} render={() => logoutAction(LogoutActions.LogoutCallback)} />
                        <Route path={ApplicationPaths.LoggedOut} render={() => logoutAction(LogoutActions.LoggedOut)} />
                    </div>
                </div>
                <div className="fill"></div>
                <div className="footer-container">
                    <div>&copy; 2020 - Spolek Sportvital pro bono</div>
                </div>
                <div className="footer-logo-container">
                    <div className="logo-content">
                        <div className="logo-mze">
                            <img alt="MZe" src="/img/logo-mze.svg" />
                        </div>
                        <div className="logo-text">{translate('Logo.Text')}</div>
                    </div>
                </div>
            </Fragment>);
    }
}

function loginAction(name) {
    return (<Login action={name}></Login>);
}

function logoutAction(name) {
    return (<Logout action={name}></Logout>);
}
