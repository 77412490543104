import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu/NavMenu';

export class Layout extends Component {
    static displayName = Layout.name;

    static propsTypes = {
        isAdmin: PropTypes.bool,
        selectedShare: PropTypes.object
    }

    render() {
        let { isAdmin, selectedShare } = this.props;

        return (
            <div className="app-layout">
                <NavMenu
                    isAdmin={isAdmin}
                    selectedShare={selectedShare}
                />
                <Container>
                    {this.props.children}
                </Container>
            </div>
        );
    }
}
