import _ from 'lodash';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';

import api from '../../utils/api';
import { AppContext } from '../../contexts/AppContext';

import './reportDetail.scss';


export default class RecipeDetail extends Component {

    static contextType = AppContext;

    static propTypes = {
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        data: PropTypes.object
    }

    constructor(props, context) {
        super(props);

        let data = props.data || {};
        this.state = {
            name: data.name || '',
            texts: [],
            languages: ['cs', 'en']
        }

        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let { translate } = this.context;
        let { data } = this.props;

        try {
            let response = await api.getReportTextDetail(data.name);
            let texts = response.data || [];
            this.setState({ texts });
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    toggle() {
        let { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    async save() {
        let { translate } = this.context;
        let { onSubmit } = this.props;
        let { texts } = this.state;

        try {
            let input = { texts };
            let response = api.updateReportTexts(input);
            if (onSubmit) {
                onSubmit(response.data);
            }
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    render() {
        let { translate } = this.context;
        let { name, languages, texts } = this.state;

        return (
            <Modal isOpen={true} toggle={this.toggle} autoFocus={false} className="report-detail-modal">
                <ModalHeader toggle={this.toggle}>{translate('ReportAdmin.Title')}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label>{translate('ReportAdmin.Name')}</Label>
                            <Input
                                value={name}
                                onChange={() => { }}
                                readOnly
                            />
                        </FormGroup>
                        {languages.map(lang => {
                            return texts.map(text => {
                                let translation = _.find(text.translations, { lang }) || { lang };
                                let value = translation.text;
                                return (
                                    <FormGroup key={`${text.type}_${lang}`}>
                                        <Label>{`[${lang}] ${translate(`ReportType.${text.type}`)}`}</Label>
                                        <Input
                                            type="textarea"
                                            value={value}
                                            onChange={e => {
                                                let t = { ...translation, text: e.target.value };
                                                let ts = _.mapKeys(text.translations, 'lang');
                                                ts[lang] = t;
                                                let txt = { ...text, translations: _.map(ts) };
                                                let txts = texts.map(o => {
                                                    if (o.type === txt.type) {
                                                        return txt;
                                                    } else {
                                                        return o;
                                                    }
                                                });
                                                this.setState({ texts: txts });
                                            }}
                                            rows="4"
                                        />
                                    </FormGroup>
                                );
                            })
                        })}
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>{translate('Btn.Save')}</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>{translate('Btn.Cancel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}