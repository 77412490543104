import _ from 'lodash';
import React, { Component } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import api from '../utils/api';
import Home from './Home';
import Report from './Report/Report';
import { AppContext } from '../contexts/AppContext';
import { Layout } from './Layout';
import Education from './Education/Education';
import Settings from './Settings/Settings';
import Admin from './Admin/Admin';
import { ROLE_ADMIN } from '../constants/role';
import { arraySort } from '../utils/sort';


function getSelectedShareFromStorage() {
    let item = sessionStorage.getItem('selectedShare');
    let result = item ? JSON.parse(item) : null;
    return result;
}

const getDisplayName = user => {
    if (!user) {
        return null;
    }

    if (user.userName && user.name) {
        return `${user.name} (${user.userName})`;
    } else if (user.userName) {
        return user.userName;
    } else if (user.id) {
        return user.id;
    }

    return null;
}

export class AuthorizedContext extends Component {
    static displayName = AuthorizedContext.name;
    static contextType = AppContext;

    constructor() {
        super();

        this.state = {
            profile: null,
            shares: [],
            selectedShare: getSelectedShareFromStorage(),
            showMessage: false
        }

        this.onShareSelect = this.onShareSelect.bind(this);
    }

    componentDidMount() {
        this.loadProfile();

        window.setTimeout(() => {
            this.setState({ showMessage: true });
        }, 500);

        // skrytí pozadí
        //this.bodyBkgImg = document.body.style.backgroundColor;
        //document.body.style.backgroundImage = 'none';
    }

    componentWillUnmount() {
        // obnovení pozadí
        //document.body.style.backgroundImage = this.bodyBkgImg;
    }

    async loadProfile() {
        //let { translate } = this.context;

        try {
            let [profileResponse, shareResponse] = await Promise.all([
                api.getProfile(),
                api.getShareList()
            ]);
            let profile = profileResponse.data || {};
            console.log('profil uživatele', profile);
            let { selectedShare } = this.state;
            let shares = shareResponse.data || [];
            if (shares.length) {
                shares = [
                    { id: '', userName: profile.userName, name: profile.name, value: 'none', label: getDisplayName(profile) },
                    ...(shares.map(o => ({ ...o, value: o.id, label: getDisplayName(o) })))
                ];
                arraySort(shares, 'name', 'userName');
                if (selectedShare) {
                    selectedShare = _.find(shares, { userName: selectedShare.userName });
                    if (!selectedShare) {
                        selectedShare = shares[0];
                    }
                } else {
                    selectedShare = shares[0];
                }
            }
            this.setState({ profile, shares, selectedShare });
        } catch (error) {
            console.log('chyba načtení profilu', error);
            //toastr.error(translate('Err.System'));
        }
    }

    onShareSelect(selectedShare) {
        //console.log('onShareSelect', selectedShare);
        sessionStorage.setItem('selectedShare', selectedShare ? JSON.stringify(selectedShare) : '');
        this.setState({ selectedShare });
    }

    render() {
        let { translate } = this.context;
        let { profile, showMessage, shares, selectedShare } = this.state;

        let showContent = !!profile;
        let showMsg = !profile && showMessage;
        let isAdmin = !profile ? null : (profile.roles && profile.roles.length && profile.roles.includes(ROLE_ADMIN)) === true;

        //console.log('selectedShare', { selectedShare, ss: sessionStorage.getItem('selectedShare') });

        return (
            <Layout isAdmin={isAdmin} selectedShare={selectedShare}>
                {showContent &&
                    <Switch>
                        <Route exact path='/'>
                            <Home
                                key={(selectedShare || {}).id || 'i'}
                                shares={shares}
                                selectedShare={selectedShare}
                                onShareSelect={this.onShareSelect}
                            />
                        </Route>
                        <Route path='/report'>
                            <Report
                                key={(selectedShare || {}).id || 'i'}
                                shares={shares}
                                selectedShare={selectedShare}
                                onShareSelect={this.onShareSelect}
                                isAdmin={isAdmin}
                            />
                        </Route>
                        <Route path='/edu'>
                            <Education
                                isAdmin={isAdmin}
                            />
                        </Route>
                        <Route path='/settings'>
                            <Settings
                                key={(selectedShare || {}).id || 'i'}
                                selectedShare={selectedShare}
                            />
                        </Route>
                        <Route path='/admin'>
                            <Admin isAdmin={isAdmin} />
                        </Route>
                        <Redirect to="/" />
                    </Switch>
                }
                {showMsg &&
                    <div>{translate('Profile.Loading')}</div>
                }
            </Layout>
        );
    }
}
