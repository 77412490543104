import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import RichTextEditor from 'react-rte';
import { FormGroup, Label, Input } from 'reactstrap';

import { RteToolbarConfig } from '../../utils/ritchText';

import './translationControl.scss';


export function getTranslationValue(translations, name) {
    for (let i = 0; i < translations.length; i++) {
        if (translations[i][name]) {
            return translations[i][name];
        }
    }

    return '';
}

export default class TranslationControl extends Component {

    static propsTypes = {
        label: PropTypes.string,
        name: PropTypes.string,
        languages: PropTypes.array,
        translations: PropTypes.array,
        onChange: PropTypes.func,
        autoFocus: PropTypes.bool,
        ritchText: PropTypes.bool
    }

    static defaultProps = {

    }

    constructor() {
        super();

        this.state = {
            label: '',
            languages: [],
            translations: []
        };
    }

    render() {
        let { label, name, languages, translations, onChange, autoFocus, ritchText } = this.props;

        let data = {};
        languages.forEach(o => {
            data[o] = {
                lang: o,
                ...(_.find(translations, { lang: o }) || {})
            };
        });

        if (ritchText) {
            return (
                <Fragment>
                    {languages.map((lang, i) => {
                        return (
                            <FormGroup
                                key={i}
                                className="tc-container"
                            >
                                <Label>{label ? `${label} [${lang}]` : `[${lang}]`}</Label>
                                <RichTextEditor
                                    value={data[lang][name] || RichTextEditor.createEmptyValue()}
                                    onChange={value => {
                                        if (onChange) {
                                            let translation = { ...data[lang] };
                                            translation[name] = value;
                                            let result = { ...data };
                                            result[lang] = translation;
                                            onChange(_.map(result));
                                        }
                                    }}
                                    toolbarConfig={RteToolbarConfig}
                                />
                            </FormGroup>
                        );
                    })}
                </Fragment>
            );
        } else {
            return (
                <FormGroup className="tc-container">
                    <Label>{label || ''}</Label>
                    {languages.map((lang, i) => {
                        return (
                            <div className="tc-panel lng-line" key={i}>
                                <Input
                                    className="lang"
                                    value={lang}
                                    onChange={() => { }}
                                    disabled
                                />
                                <Input
                                    value={data[lang][name] || ''}
                                    onChange={e => {
                                        if (onChange) {
                                            let translation = { ...data[lang] };
                                            translation[name] = e.target.value;
                                            let result = { ...data };
                                            result[lang] = translation;
                                            onChange(_.map(result));
                                        }
                                    }}
                                    autoFocus={autoFocus && i === 0}
                                />
                            </div>
                        );
                    })}
                </FormGroup>
            );
        }
    }
}