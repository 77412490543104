import authService, { AuthenticationResultStatus } from '../components/api-authorization/AuthorizeService'
import axios from 'axios';
import { logErrors } from './error';
import qs from 'qs';


export class ApiService {

    // #region /info

    async getInfo() {
        return await this.callAnonymous('get', `api/info`);
    }

    // #endregion

    // #region /activity

    async getActivities(params) {
        return await this.callAuthorized('get', `activity`, params);
    }

    async GetActivity(id) {
        return await this.callAuthorized('get', `activity/${id}`);
    }

    async addActivity(data) {
        return await this.callAuthorized('post', `activity`, data);
    }

    async updateActivity(id, data) {
        return await this.callAuthorized('put', `activity/${id}`, data);
    }

    // #endregion

    // #region /energy

    // #region /energy/in

    async getEnergyInputs(params) {
        return await this.callAuthorized('get', `energy/in`, params);
    }

    async addEnergyInput(data) {
        return await this.callAuthorized('post', `energy/in`, data);
    }

    async updateEnergyInput(id, data) {
        return await this.callAuthorized('put', `energy/in/${id}`, data);
    }

    async deleteEnergyInput(id, data) {
        return await this.callAuthorized('post', `energy/in/${id}/del`, data);
    }

    // #endregion

    // #region /energy/out

    async getEnergyOutputs(params) {
        return await this.callAuthorized('get', `energy/out`, params);
    }

    async addEnergyOutput(data) {
        return await this.callAuthorized('post', `energy/out`, data);
    }

    async updateEnergyOutput(id, data) {
        return await this.callAuthorized('put', `energy/out/${id}`, data);
    }

    async deleteEnergyOutput(id, data) {
        return await this.callAuthorized('post', `energy/out/${id}/del`, data);
    }

    // #endregion

    // #endregion

    // #region /feature

    async getFeatures() {
        return await this.callAuthorized('get', `feature`);
    }

    async getFeatureList() {
        return await this.callAuthorized('get', `feature/list`);
    }

    async updateFeatureList(data) {
        return await this.callAuthorized('put', `feature/list`, data);
    }

    async exportFoods(params) {
        return await this.callAuthorized('get', `feature/foods`, params, { responseType: 'blob' });
    }

    // #endregion

    // #region /suitable

    async getSuitables() {
        return await this.callAuthorized('get', `suitable`);
    }

    async getSuitableList() {
        return await this.callAuthorized('get', `suitable/list`);
    }

    async updateSuitableList(data) {
        return await this.callAuthorized('put', `suitable/list`, data);
    }

    async loadMenuDesign(params) {
        return await this.callAuthorized('get', `food/menu`, params, { responseType: 'blob' });
    }

    // #endregion

    // #region /food

    // #region /food/category

    async getFoodCategories(params) {
        return await this.callAuthorized('get', `food/category`, params);
    }

    // #endregion

    // #region /food/item

    async getFoodItems(params) {
        return await this.callAuthorized('get', `food/item`, params);
    }

    async getAdminFoods() {
        return await this.callAuthorized('get', `food/item/admin`);
    }

    async GetFood(id) {
        return await this.callAuthorized('get', `food/item/${id}`);
    }

    async updateFood(id, data) {
        return await this.callAuthorized('put', `food/item/${id}`, data);
    }

    async addFood(data) {
        return await this.callAuthorized('post', `food/item`, data);
    }

    async getFoodCodes() {
        return await this.callAuthorized('get', `food/item/codes`);
    }

    async exportFood(params) {
        return await this.callAuthorized('get', `food/item/export`, params, { responseType: 'blob' });
    }

    // #endregion

    // #region /food/type

    async getFoodTypes() {
        return await this.callAuthorized('get', `food/type`);
    }

    // #endregion

    // #region /food/param

    async getFoodParams() {
        return await this.callAuthorized('get', `food/param`);
    }

    // #endregion

    // #endregion

    // #region /plan

    async getPlan(params) {
        return await this.callAuthorized('get', `api/plan`, params);
    }

    async setPlan(data) {
        return await this.callAuthorized('post', `api/plan`, data);
    }

    // #region /preview

    async getPreview(params) {
        return await this.callAuthorized('get', `api/preview`, params);
    }

    // #endregion

    // #region /recipe

    async getRecipes() {
        return await this.callAuthorized('get', `api/recipe`);
    }

    async GetRecipe(id) {
        return await this.callAuthorized('get', `api/recipe/${id}`);
    }

    async updateRecipe(id, data) {
        return await this.callAuthorized('put', `api/recipe/${id}`, data);
    }

    async addRecipe(data) {
        return await this.callAuthorized('post', `api/recipe`, data);
    }

    async addRecipeImg(id, data) {
        return await this.callAuthorized('post', `api/recipe/${id}/img`, data);
    }

    async remRecipeImg(id, imgId) {
        return await this.callAuthorized('delete', `api/recipe/${id}/img/${imgId}`);
    }

    // #endregion

    // #region /user

    async getProfile(params) {
        return await this.callAuthorized('get', `user/profile`, params);
    }

    async getUserWeight(params) {
        return await this.callAuthorized('get', `user/weight`, params);
    }

    async setProfile(data) {
        return await this.callAuthorized('post', `user/profile`, data);
    }

    async getPasswordInfo() {
        return await this.callAuthorized('get', `user/pwd`);
    }

    async changePassword(input) {
        return await this.callAuthorized('put', `user/pwd`, input);
    }

    async setPassword(input) {
        return await this.callAuthorized('post', `user/pwd`, input);
    }

    async deletePersonalData() {
        return await this.callAuthorized('delete', `user/personal`);
    }

    async getExternalLogins() {
        return await this.callAuthorized('get', `user/external`);
    }

    async removeExternalLogin(data) {
        return await this.callAuthorized('post', `user/external/rem`, data);
    }

    async getUsers() {
        return await this.callAuthorized('get', `user`);
    }

    async updateUser(id, data) {
        return await this.callAuthorized('put', `user/${id}`, data);
    }

    async getShareSettings() {
        return await this.callAuthorized('get', `user/share`);
    }

    async setShareSettings(data) {
        return await this.callAuthorized('put', `user/share`, data);
    }

    async getShareList() {
        return await this.callAuthorized('get', `user/share/list`);
    }

    // #endregion

    // #region /report

    async getReport(params) {
        return await this.callAuthorized('get', `api/report`, params);
    }

    async exportReport(params) {
        return await this.callAuthorized('get', `api/report/export`, params, { responseType: 'blob' });
    }

    async exportDailyReport(params) {
        return await this.callAuthorized('get', `api/report/daily`, params, { responseType: 'blob' });
    }

    async getReportTextList() {
        return await this.callAuthorized('get', `api/report/name`);
    }

    async getReportTextDetail(name) {
        return await this.callAuthorized('get', `api/report/name/${name}`);
    }

    async updateReportTexts(input) {
        return await this.callAuthorized('post', `api/report/name`, input);
    }

    // #endregion

    async getLanguages() {
        return await this.callAuthorized('get', `lang/getLanguages`);
    }

    async setLangAsync(lang) {
        return await this.callAnonymous('post', `lang/setlang?lang=${lang}`);
    }

    async callAuthorized(method, path, data, options) {
        let reqId = new Date().getTime();
        let token = await authService.getAccessToken();
        console.log(`call ${method} ${path}`, reqId);
        return this._callApi(token, method, path, data, options).catch(async error => {
            if (!logErrors(error, method, path, reqId)) {
                console.log('chyba api', reqId, error);
            }
            if (error.response && error.response.status === 401) {
                //return this.authService.renewToken().then(renewedUser => {
                //    return this._callApi(renewedUser.access_token, method, path, data, options);
                //});
                console.log('401', reqId);
                let signInResult = await authService.signIn();
                if (signInResult.status === AuthenticationResultStatus.Success) {
                    token = await authService.getAccessToken();
                    console.log('znovu volám api', reqId);
                    return this._callApi(token, method, path, data, options);
                } else {
                    console.log('přihlášení nedopadlo dobře', reqId);
                }
            }
            throw error;
        });;
    }

    async callAnonymous(method, path, data, options) {
        console.log(`call ${method} ${path}`);
        return this._callApi(null, method, path, data, options);
    }

    _callApi(token, method, path, data, options) {
        const headers = {
            Accept: 'application/json'
        };
        if (token) {
            headers.Authorization = 'Bearer ' + token;
        }
        options = { ...(options || {}) };
        options.headers = { ...(options.headers || {}), ...headers };
        switch (method) {
            case 'put':
                return axios.put(path, data, options);
            case 'post':
                return axios.post(path, data, options);
            case 'delete':
                if (data) {
                    return axios.delete(path, { ...options, data });
                } else {
                    return axios.delete(path, options);
                }
            default:
                if (data) {
                    options.params = {
                        ...(options.params || {}),
                        ...data
                    };
                    options.paramsSerializer = params => {
                        return qs.stringify(params)
                    }
                }
                return axios.get(path, options);
        }
    }
}

const apiService = new ApiService();

export default apiService;
