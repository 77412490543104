import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import RS from 'react-select';
import Select from 'react-select-virtualized';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, ModalFooter, Button } from 'reactstrap';

import api from '../../utils/api';
import { defaultStyle } from '../../utils/style';
import { AppContext } from '../../contexts/AppContext';
import { getYesNo, getYesNoOptions } from '../../utils/codelist';
import FoodParamsControl from '../FoodParamsControl/FoodParamsControl';
import TranslationControl, { getTranslationValue } from '../TranslationControl/TranslationControl';
import FeatureModal from './FeatureModal';

import './foodDetail.scss';
import SuitableModal from './SuitableModal';
import { getEnumOption, getEnumOptions, orderByOptions } from '../../utils/options';
import { foodTypes, FT_OTHER } from '../../constants/foodType';
import { arraySort } from '../../utils/sort';


const paramsFilter = [
    3, // BÍLKOVINY CELKOVÉ(g) 
    51, // DRASLÍK(mg) 
    70, // ENERGIE(kJ) 
    367, // ENERGIE(kcal)
    302, // ETYLALKOHOL(g) 
    47, // HOŘČÍK(mg) 
    42, // CHOLESTEROL(mg) 
    88, // KYS.LISTOVÁ(ug) 
    39, // MONONENASYCENÉ MK(g) 
    38, // NASYCENÉ MK(g) 
    40, // POLYNENASYCENÉ MK(g) 
    44, // SACHARIDY CELKOVÉ(g) 
    119, // SACHAROZA(g) 
    46, // SODÍK(mg) 
    127, // TUKY(g) 
    377, // Tuky rostlinného původu(g) 
    376, // Tuky živočišného původu(g) 
    52, // VÁPNÍK(mg) 
    61, // VITAMIN A(ug) 
    62, // VITAMIN B1(mg) 
    65, // VITAMIN B12(ug) 
    63, // VITAMIN B2(mg) 
    67, // VITAMIN B6(mg) 
    110, // VITAMIN C(mg) 
    341, // VITAMIN D(ug) 
    363, // VITAMIN E(mg) 
    118, // VLÁKNINA(g) 
    2, // VODA CELKOVÁ(ml) 
    54 // ŽELEZO(mg) 
];

const toOption = item => {
    if (item) {
        return {
            ...item,
            value: item.id,
            label: item.name
        };
    }
    return null;
}

const optToItem = option => {
    if (!option) {
        return null;
    }
    let result = {
        id: option.value,
        name: option.label
    };
    return result;
}

const decodeTypes = (value, translate) => {
    return [...(value || '')].reduce((r, c) => {
        if (foodTypes.includes(c)) {
            return [...r, getEnumOption(translate, 'FoodTypes', c)];
        } else {
            return r;
        }
    }, []);
}

const encodeTypes = (types) => {
    return (types || []).reduce((r, c) => r + c.value, '');
}

export default class FoodDetail extends Component {

    static contextType = AppContext;

    static propTypes = {
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        data: PropTypes.object
    }

    constructor(props, context) {
        super(props);

        let data = props.data || {};
        let typeOptions = getEnumOptions(context.translate, 'FoodTypes', foodTypes);
        this.state = {
            translations: data.translations || [],
            unit: data.unit ? { value: data.unit, label: data.unit } : null,
            healthyIndex: this.getHealthyIndex(context.translate, data.healthyIndex || 0),
            deleted: getYesNo(context.translate, data.deleted || false),
            categories: (data.categories || []).map(toOption),
            features: arraySort((data.features || []).map(toOption), 'label'),
            params: data.params || [],
            languages: ['cs', 'en'],
            catOptions: [],
            parOptions: [],
            fetOptions: [],
            suitableOptions: [],
            featureModal: null,
            suitableModal: null,
            typeOptions,
            types: orderByOptions(decodeTypes(data.types, context.translate), typeOptions)
        }

        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let { translate } = this.context;
        let { data } = this.props;
        let { typeOptions } = this.state;

        try {
            let actions = [api.getLanguages(), api.getFoodCategories(), api.getFoodParams(), api.getFeatures(), api.getSuitables()];
            if (data.id) {
                actions.push(api.GetFood(data.id));
            }
            let [langResponse, categoryResponse, paramResponse, featureResponse, suitableResponse, foodResponse] = await Promise.all(actions);
            //console.log('responses', { langResponse, categoryResponse, paramResponse, foodResponse });

            let state = {
                languages: langResponse.data || [],
                catOptions: (categoryResponse.data || []).map(toOption),
                parOptions: paramResponse.data || [],
                fetOptions: arraySort((featureResponse.data || []).map(toOption), 'label'),
                suitableOptions: arraySort((suitableResponse.data || []).map(toOption), 'label')
            };
            if (foodResponse) {
                let { translations, deleted, unit, healthyIndex, categories, params, features, suitables, types } = foodResponse.data || {};
                state = {
                    ...state,
                    translations,
                    deleted: getYesNo(translate, deleted === true),
                    unit: unit ? { value: unit, label: unit } : null,
                    healthyIndex: this.getHealthyIndex(translate, healthyIndex),
                    categories: (categories || []).map(toOption),
                    params,
                    features: arraySort((features || []).map(toOption), 'label'),
                    suitables: arraySort((suitables || []).map(toOption), 'label'),
                    types: orderByOptions(decodeTypes(types, translate), typeOptions)
                };
            }
            this.setState(state);
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    toggle() {
        let { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    async save() {
        let { translations, unit, healthyIndex, categories, params, deleted, features, suitables, types } = this.state;
        let { onSubmit, data } = this.props;
        let { translate } = this.context;

        try {
            translations = translations.filter(o => !!o.name || !!o.note);
            let requestData = {
                name: getTranslationValue(translations, 'name'),
                note: getTranslationValue(translations, 'note'),
                translations: translations,
                unit: (unit || {}).value,
                healthyIndex: healthyIndex.value,
                categories: (categories || []).map(optToItem),
                params: params.filter(o => !!o.id).map(o => {
                    o.value = parseFloat(o.value);
                    return o;
                }),
                deleted: deleted.value,
                features: (features || []).map(optToItem),
                suitables: (suitables || []).map(optToItem),
                types: encodeTypes(types)
            };
            let response = data.id
                ? await api.updateFood(data.id, requestData)
                : await api.addFood(requestData);
            if (onSubmit) {
                onSubmit(response.data);
            }
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    getHealthyIndexOptions(translate) {
        let result = [
            this.getHealthyIndex(translate, 0),
            this.getHealthyIndex(translate, 1),
            this.getHealthyIndex(translate, 2)
        ];
        return result;
    }

    getHealthyIndex(translate, value) {
        let result = { value, label: translate(`HealthIndex.${value}`) };
        return result;
    }

    render() {
        let { translate } = this.context;
        let { translations, languages, deleted, unit, healthyIndex, catOptions,
            categories, parOptions, params, features, fetOptions, featureModal,
            suitables, suitableOptions, suitableModal, typeOptions, types } = this.state;

        let hiOptions = this.getHealthyIndexOptions(translate);

        //console.log('render', { catOptions, categories });

        return (
            <Modal
                isOpen={true}
                toggle={this.toggle}
                autoFocus={false}
                className='food-detail-modal'
            >
                <ModalHeader toggle={this.toggle}>{translate('FoodAdmin.FoodDetail')}</ModalHeader>
                <ModalBody>
                    <Form>
                        <div className='wrap-container'>
                            <div className='wrap-item'>
                                <TranslationControl
                                    label={translate('FoodAdmin.Name')}
                                    name="name"
                                    languages={languages}
                                    translations={translations}
                                    onChange={translations => this.setState({ translations })}
                                    autoFocus
                                />
                                <FormGroup>
                                    <Label>{translate('FoodAdmin.Unit')}</Label>
                                    <Select
                                        options={[
                                            { value: 'g', label: 'g' },
                                            { value: 'ml', label: 'ml' }
                                        ]}
                                        value={unit}
                                        onChange={unit => this.setState({ unit })}
                                        placeholder=" "
                                        isClearable={false}
                                        isSearchable={true}
                                        styles={defaultStyle()}
                                    />
                                </FormGroup>
                            </div>
                            <div className='wrap-item'>
                                <TranslationControl
                                    label={translate('FoodAdmin.Note')}
                                    name="note"
                                    languages={languages}
                                    translations={translations}
                                    onChange={translations => this.setState({ translations })}
                                />
                                <FormGroup>
                                    <Label>{translate('FoodAdmin.Health')}</Label>
                                    <Select
                                        options={hiOptions}
                                        value={healthyIndex}
                                        onChange={healthyIndex => this.setState({ healthyIndex })}
                                        placeholder=" "
                                        isClearable={false}
                                        isSearchable={false}
                                        styles={defaultStyle()}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className='wrap-container'>
                            <div className='wrap-item'>
                                <FormGroup>
                                    <Label>{translate('FoodAdmin.Category')}</Label>
                                    <RS
                                        options={catOptions}
                                        value={categories}
                                        onChange={categories => this.setState({ categories })}
                                        placeholder=" "
                                        isMulti
                                        isClearable={false}
                                        styles={defaultStyle()}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{translate('FoodAdmin.Feature')}</Label>
                                    <RS
                                        options={fetOptions}
                                        value={features}
                                        onChange={features => this.setState({ features: arraySort(features, 'label') })}
                                        placeholder=" "
                                        isMulti
                                        isClearable={false}
                                        styles={defaultStyle()}
                                    />
                                    <div className="another-panel">
                                        <div
                                            className="feature-btn"
                                            onClick={() => this.setState({ featureModal: new Date().getTime() })}
                                        >{translate('Feature.Title')}</div>
                                    </div>
                                </FormGroup>
                            </div>
                            <div className='wrap-item'>
                                <FormGroup>
                                    <Label>{translate('FoodAdmin.Suitable')}</Label>
                                    <RS
                                        options={suitableOptions}
                                        value={suitables}
                                        onChange={suitables => this.setState({ suitables: arraySort(suitables, 'label') })}
                                        placeholder=" "
                                        isMulti
                                        isClearable={false}
                                        styles={defaultStyle()}
                                    />
                                    <div className="another-panel">
                                        <div
                                            className="feature-btn"
                                            onClick={() => this.setState({ suitableModal: new Date().getTime() })}
                                        >{translate('Suitable.Title')}</div>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>{translate('FoodAdmin.FoodType')}</Label>
                                    <RS
                                        options={typeOptions}
                                        value={types}
                                        onChange={types => this.setState({ types: orderByOptions(types, typeOptions) })}
                                        placeholder={getEnumOption(translate, 'FoodTypes', FT_OTHER).label}
                                        isMulti
                                        isClearable={false}
                                        styles={defaultStyle()}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <FoodParamsControl
                            label={translate('FoodAdmin.Params')}
                            options={parOptions}
                            params={params}
                            onChange={params => this.setState({ params })}
                            paramsFilter={paramsFilter}
                        />
                        <FormGroup>
                            <Label>{translate('ActivityAdmin.Deleted')}</Label>
                            <Select
                                options={getYesNoOptions(translate)}
                                value={deleted}
                                onChange={deleted => this.setState({ deleted })}
                                placeholder=" "
                                isClearable={false}
                                isSearchable={false}
                                styles={defaultStyle()}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>{translate('Btn.Save')}</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>{translate('Btn.Cancel')}</Button>
                </ModalFooter>
                {featureModal &&
                    <FeatureModal
                        key={featureModal}
                        languages={languages}
                        onClose={() => this.setState({ featureModal: null })}
                        onSubmit={data => {
                            let fetOptions = arraySort((data || []).map(toOption), 'label');
                            this.setState({ fetOptions, featureModal: null });
                        }}
                        params={parOptions}
                    />
                }
                {suitableModal &&
                    <SuitableModal
                        key={suitableModal}
                        languages={languages}
                        onClose={() => this.setState({ suitableModal: null })}
                        onSubmit={data => {
                            let suitableOptions = arraySort((data || []).map(toOption), 'label');
                            this.setState({ suitableOptions, suitableModal: null });
                        }}
                    />
                }
            </Modal>
        );
    }
}
