import classnames from 'classnames';
import { isNumber } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { PlanEnergy } from '../../constants/energy';
import { DayMiliseconds } from '../../constants/time';
import { AppContext } from '../../contexts/AppContext';

import './energyProgressBar.scss';


class EnergyProgressBar extends Component {

    static propTypes = {
        input: PropTypes.number,
        output: PropTypes.number,
        basalOutput: PropTypes.number,
        showDiff: PropTypes.bool,
        plan: PropTypes.number,
        date: PropTypes.object,
        lifeStyleOutput: PropTypes.number,
        loaded: PropTypes.bool
    }

    render() {
        let { translate, language } = this.context;
        let { input, output, showDiff, basalOutput, plan, date, lifeStyleOutput, loaded } = this.props;

        let isToday = date && date.isSame(moment().startOf('date'));

        let energy = 0;
        if (showDiff) {
            energy = Math.round((input - output) || 0);
        }
        energy = `${energy > 0 ? '+' : ''}${energy.toLocaleString(language)} kJ`;

        let inputPercent = 0;
        let lineType = 'bad';
        let showPlan = loaded && plan !== 0;
        let planRate = 0;
        let placeholderWidth = 50;
        let planTip = '';
        if (output > 0) {
            inputPercent = Math.round((input || 0) * 100 / output) / 2;
            let val = (input || 0) / output;
            if (val >= 0.5) {
                lineType = 'good';
            } else if (val >= 0.3) {
                lineType = 'warn';
            }
            let planEnergy = Math.max(-PlanEnergy, isNumber(plan) ? plan : -PlanEnergy);
            planTip = translate(`EnergyProgressBar.PlanTip${isNumber(plan) && plan >= -PlanEnergy ? 'Up' : 'Down'}`).replace('{value}', Math.round(planEnergy).toLocaleString(language));
            if (isToday) {
                planEnergy = Math.round(planEnergy * moment().diff(date) / DayMiliseconds);
            }
            planRate = Math.max(Math.min((output + planEnergy) * 50 / output, 100), 0);
            placeholderWidth = Math.max(placeholderWidth, planRate);
            //console.log('planRate', { planRate, output, planEnergy });
        }
        let totalInput = Math.round(input || 0).toLocaleString(language);
        let totalOutput = Math.round(output || 0).toLocaleString(language);
        let basalOutputText = Math.round(basalOutput || 0).toLocaleString(language);
        let lifeStyleOutputText = Math.round(lifeStyleOutput || 0).toLocaleString(language);

        return (
            <div className="energy-progress-bar-container f4f-panel">
                <div className="progress-title">{translate('EnergyProgressBar.Title')}</div>
                <div className="progress-part">
                    <div className="progress-line">
                        <div className="placeholder-line" style={{ width: `${placeholderWidth}%` }}></div>
                        <div className={classnames('input-line', lineType)} style={{ width: `${Math.min(inputPercent, 50)}%` }}></div>
                        <div className="input-line warn" style={{ left: '50%', width: `${Math.min(Math.max(inputPercent - 50, 0), 12.5)}%` }}></div>
                        <div className="input-line bad" style={{ left: '62.5%', width: `${Math.min(Math.max(inputPercent - 62.5, 0), 37.5)}%` }}></div>
                        <div className="middle-point"></div>
                        {showPlan &&
                            <div className="plan-point" style={{ left: `${planRate}%` }}></div>
                        }
                    </div>
                </div>
                <div className="label-part">
                    <div className="label">{translate('EnergyProgressBar.Lack')}</div>
                    <div className="energy">{energy}</div>
                    <div className="label ta-r">{translate('EnergyProgressBar.Surplus')}</div>
                </div>
                <div className="progress-info"><i className="fa fa-info-circle" aria-hidden="true"></i></div>
                <div className="progress-tooltip">
                    <div className="tip-item">{translate('EnergyProgressBar.' + (isToday ? 'TodayInput' : 'TotalInput'))}: {totalInput} kJ</div>
                    <div className="tip-item">{translate('EnergyProgressBar.' + (isToday ? 'TodayOutput' : 'TotalOutput'))}: {totalOutput} kJ</div>
                    <div className="tip-item">{translate('EnergyProgressBar.BasalOutput')}: {basalOutputText} kJ</div>
                    <div className="tip-item invisible" style={{ height: 0 }}>{translate('EnergyProgressBar.LifeStyleOutput')}: {lifeStyleOutputText} kJ</div>
                    <div className="tip-note">{translate('EnergyProgressBar.TipNote')}</div>
                    {showPlan &&
                        <div className="tip-item">{planTip}</div>
                    }
                </div>
            </div>
        );
    }
}

EnergyProgressBar.contextType = AppContext;

export default EnergyProgressBar;