import React from 'react';

import './PanelTooltip.js.scss';


export const PanelTooltip = ({ pos, value }) => {

    return (
        <div className="panel-tooltip">
            <div className="panel-tooltip-arrow" style={{ left: `${pos}px` }} />
            {value}
        </div >
    );
}