import _ from 'lodash';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-virtualized';
import { FormGroup, Label, Input } from 'reactstrap';
import { defaultStyle } from '../../utils/style';
import ReactTooltip from 'react-tooltip';

import './foodParamsControl.scss';


export default class FoodParamsControl extends Component {

    static propsTypes = {
        label: PropTypes.string,
        options: PropTypes.array,
        params: PropTypes.array,
        onChange: PropTypes.func,
        paramsFilter: PropTypes.array
    }

    constructor() {
        super();

        this.state = {

        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.options !== this.props.options || prevProps.params !== this.props.params) {
            ReactTooltip.rebuild();
        }
    }

    render() {
        let { label, params, options, onChange, paramsFilter } = this.props;
        //console.log('fpc', { label, params, options });

        options = (options || []).map(o => ({ ...o, value: o.id, label: `${o.name} (${o.unit})` }));

        paramsFilter = paramsFilter || [];
        let filteredParams = params.filter(o => o.id === 0 || paramsFilter.length === 0 || paramsFilter.includes(o.id));
        let filteredOptions = options.filter(o => paramsFilter.length === 0 || paramsFilter.includes(o.id));

        let ids = filteredParams.map(o => o.id);
        let unusedOptions = filteredOptions.filter(o => !ids.includes(o.id));
        let showAdd = unusedOptions.length > 0 && !ids.includes(0);
        
        return (
            <FormGroup className="fpc-container">
                <Label className="fpc-label">{label || ''}</Label>
                {filteredParams.map((par, i) => {
                    let value = par.id ? { value: par.id, label: par.name, unit: par.unit } : null;
                    let { note } = par.id ? _.find(options, { value: par.id }) || {} : {};
                    let opts = [...unusedOptions];
                    if (value) {
                        opts.unshift(value);
                    }
                    return (
                        <div className="fpc-item fpc-panel" key={i}>
                            <Select
                                options={opts}
                                value={value}
                                onChange={opt => {
                                    if (onChange) {
                                        par.id = opt.id;
                                        par.name = opt.name;
                                        par.unit = opt.unit;
                                        let result = [...params];
                                        onChange(result);
                                    }
                                }}
                                placeholder=""
                                isClearable={false}
                                styles={defaultStyle()}
                                isSearchable={true}
                                autoFocus={!value}
                            />
                            <Input
                                className="fpc-value"
                                type="number"
                                value={par.value}
                                onChange={e => {
                                    if (onChange) {
                                        par.value = e.target.value;
                                        let result = [...params];
                                        onChange(result);
                                    }
                                }}
                            />
                            <div
                                className={classnames('fpc-unit', { note: !!note })}
                                data-tip={note}
                            >{par.unit}</div>
                            <div
                                className="fpc-btn"
                                onClick={() => {
                                    if (onChange) {
                                        let result = [
                                            ...params.filter(o => o.id !== par.id)
                                        ];
                                        onChange(result);
                                    }
                                }}
                            ><i className="fa fa-minus" aria-hidden="true"></i></div>
                        </div>
                    );
                })}
                {showAdd &&
                    <div className="fpc-panel">
                        <div
                            className="fpc-btn"
                            onClick={() => {
                                if (onChange) {
                                    let result = [
                                        ...params,
                                        { id: 0, name: '', unit: '', value: 0 }
                                    ];
                                    onChange(result);
                                }
                            }}
                        ><i className="fa fa-plus" aria-hidden="true"></i></div>
                    </div>
                }
                <ReactTooltip
                    className="multiline fpc-tooltip"
                    html={true}
                />
            </FormGroup>
        );
    }
}