import * as toastr from 'toastr';
import React, { Component } from 'react';
import { AppContext } from '../../contexts/AppContext';
import { Button, Form } from 'reactstrap';
import api from '../../utils/api';
import { Redirect } from 'react-router-dom';
import { ApplicationPaths } from '../api-authorization/ApiAuthorizationConstants';

class Personal extends Component {

    constructor() {
        super();

        this.state = {
            logout: false
        }
    }

    async delete() {
        let { translate } = this.context;

        try {
            await api.deletePersonalData();
            toastr.success(translate('Personal.DeleteSuccess'));
            this.setState({ logout: true });
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    render() {
        let { translate } = this.context;
        let { logout } = this.state;

        if (logout) {
            return <Redirect push to={{ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } }} />;
        }

        return (
            <div className="personal-settings">
                <div className="panel-header">{translate('Personal.Title')}</div>
                <Form>
                    <p>{translate('Personal.Text1')}</p>
                    <p><strong>{translate('Personal.Text2')}</strong></p>
                    <div className="buttons">
                        <Button color="primary" className="wide" onClick={() => this.delete()}>{translate('Personal.Delete')}</Button>
                    </div>
                </Form>
            </div>
        );
    }
}

Personal.contextType = AppContext;

export default Personal;