import _ from 'lodash';

export function filterCiAi(obj, pattern) {
    if (obj && obj.label && pattern) {
        let label = obj.label.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        let filter = pattern.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

        if (filter.includes(' ')) {
            let words = filter.split(' ').filter(o => o.length > 0);
            let res = _.every(words, w => label.indexOf(w) >= 0);
            return res;
        } else {
            let res = label.indexOf(filter) >= 0;
            return res;
        }
    }
    return true;
}