import _ from 'lodash';
import * as toastr from 'toastr';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-virtualized';

import api from '../utils/api';
import DataList from './DataList/DataList';
import { transparentStyle } from '../utils/style';
import { AppContext } from '../contexts/AppContext';
import DateNavigator from './DateNavigator/DateNavigator';
import EnergyProgressBar from './EnergyProgressBar/EnergyProgressBar';
import ElementGraph from './ElementGraph/ElementGraph';
import DayOverview from './DayOverview/DayOverview';
import EnergyOutputDialog from './EnergyOutputDialog/EnergyOutputDialog';
import EnergyInputDialog from './EnergyInputDialog/EnergyInputDialog';
import { getFoodCategory, FC_ALL, FC_FAVORITE, FC_OTHER } from '../constants/foodCategory';
import { arraySort } from '../utils/sort';
import { LT_ACTIVITY, LT_MENU } from '../constants/listType';


const FoodsKey = 'f4f.foods';

class Home extends Component {

    static displayName = Home.name;

    static propsTypes = {
        shares: PropTypes.array,
        selectedShare: PropTypes.object,
        onShareSelect: PropTypes.func
    }

    constructor(props, context) {
        super(props);

        this.state = {
            activities: [],
            date: moment().startOf('date'),
            energyOutputs: [],
            eOutputModalKey: undefined,
            eOutputModalIsOpen: false,
            eOutputModalItem: null,
            eInputs: [],
            eInputModalKey: undefined,
            eInputModalIsOpen: false,
            eInputModalItem: null,
            foodItems: {},
            foodTypes: [],
            foodCategories: [getFoodCategory(FC_ALL, context.translate)],
            preview: null,
            progressDate: moment().startOf('date'),
            pok: moment('2021-05-07T22:00:00.000Z')
        };

        this.openEnergyInputDialog = this.openEnergyInputDialog.bind(this);
        this.closeEnergyInputDialog = this.closeEnergyInputDialog.bind(this);
        this.openEnergyOutputDialog = this.openEnergyOutputDialog.bind(this);
        this.closeEnergyOutputDialog = this.closeEnergyOutputDialog.bind(this);
        this.dayTypeClick = this.dayTypeClick.bind(this);
    }

    componentDidMount() {
        this.loadActivities();
        this.loadCategories();
        this.loadFoodItems();
        this.loadFoodTypes();

        this.loadData();
    }

    // #region load functions

    async loadData() {
        let { date } = this.state;
        let { selectedShare } = this.props;

        try {
            let asUser = (selectedShare || {}).id || '';
            let [previewResponse, outputResponse, inputResponse] = await Promise.all([
                api.getPreview({ date: moment(`${date.format('YYYY-MM-DD')}T${moment().format('HH:mm:ss')}`).toISOString(true), asUser }),
                api.getEnergyOutputs({ date: date.format('YYYY-MM-DD'), asUser }),
                api.getEnergyInputs({ date: date.format('YYYY-MM-DD'), asUser })
            ]);

            let preview = previewResponse.data || {};
            let energyOutputs = outputResponse.data || [];
            let eInputs = inputResponse.data || [];
            let progressDate = date;

            this.setState({ preview, energyOutputs, eInputs, progressDate });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    async loadCategories() {
        try {
            let response = await api.getFoodCategories();
            let foodCategories = [
                getFoodCategory(FC_ALL, this.context.translate),
                ...arraySort((response.data || []).map(o => ({ ...o, value: o.id, label: o.name })), 'name')
            ];
            this.setState({ foodCategories });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    async loadFoodTypes() {
        try {
            let response = await api.getFoodTypes();
            let foodTypes = arraySort((response.data || []).map(o => ({
                ...o,
                label: o.time ? `${o.name} (${moment(o.time, 'HH:mm:ss').format('H:mm')})` : o.name,
                value: o.id
            })), 'time');
            this.setState({ foodTypes });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    async loadActivities() {
        try {
            let response = await api.getActivities();
            let activities = response.data || [];
            this.setState({ activities });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    async loadFoodItems() {
        let ctx = {
            time: 0,
            data: {}
        };

        this.loadFromStorage(ctx);
        this.setState({ foodItems: ctx.data });

        if (!(await this.isValidData(ctx))) {
            await this.loadFromApi(ctx);
            let foodCategories = this.updateCategories(this.state.foodCategories, ctx.data, this.context.translate);
            this.setState({ foodItems: ctx.data, foodCategories });
            this.saveToStorage(ctx);
        }
    }

    saveToStorage(ctx) {
        window.localStorage.setItem(FoodsKey, JSON.stringify(ctx));
    }

    async loadFromApi(ctx, quick = false) {
        try {
            let response = await api.getFoodItems({ quick });
            let { lastChange, data } = response.data || {};
            data = _.mapKeys(data || [], 'id');
            this.updateFoods(ctx, data, moment(lastChange).toDate().getTime());
        } catch (error) {
            console.log(error);
            //toastr.error(this.context.translate('Err.System'));
        }
    }

    isSomeData(ctx) {
        return ctx.time > 0;
    }

    async isValidData(ctx) {
        let response = await api.getFoodItems({ timeOnly: true });
        let time = moment((response.data || {}).lastChange).toDate().getTime();
        let result = time === ctx.time;
        //console.log('isValidData', ctx.time, time);
        return result;
    }

    loadFromStorage(ctx) {
        try {
            let item = JSON.parse(window.localStorage.getItem(FoodsKey));
            //console.log('item', item);
            if (item) {
                ctx.time = item.time || 0;
                ctx.data = item.data || {};
            }
        } catch (error) {
            console.log('Error load foods from storage.', error);
        }
    }

    updateFoods(context, upgrade, time) {
        let current = context.data || {};
        upgrade = upgrade || {};

        let result = { ...upgrade };

        for (let id in result) {
            if (current[id]) {
                result[id] = { ...current[id], ...upgrade[id] }
            }
        }

        context.data = result;
        context.time = time;
    }

    // #endregion

    updateCategories(categories, foods, translate) {
        let noCategory = false;
        let favorite = false;

        _.forEach(foods || [], o => {
            if (o.isFavorite) {
                favorite = true;
            }
            if (!o.categories || !o.categories.length) {
                noCategory = true;
            }
        });

        if (favorite) {
            categories = [
                getFoodCategory(FC_FAVORITE, translate),
                ...categories
            ];
        }
        if (noCategory) {
            categories = [
                ...categories,
                getFoodCategory(FC_OTHER, translate)
            ];
        }

        return categories;
    }

    changeDate(date) {
        this.setState({ date }, () => {
            this.loadData();
        });
    }

    openEnergyInputDialog(item) {
        let eInputModalKey = new Date().getTime();
        if (item && item.id) {
            eInputModalKey = `${item.id}_${eInputModalKey}`;
        }
        this.setState({
            eInputModalIsOpen: true,
            eInputModalKey,
            eInputModalItem: item
        });
    }

    dayTypeClick(e, type) {
        let eInputModalItem = { foodTypeId: type };

        this.setState({
            eInputModalIsOpen: true,
            eInputModalKey: new Date().getTime(),
            eInputModalItem
        });
    }

    openEnergyOutputDialog(item) {
        let eOutputModalKey = new Date().getTime();
        if (item && item.id) {
            eOutputModalKey = `${item.id}_${eOutputModalKey}`;
        }
        this.setState({
            eOutputModalIsOpen: true,
            eOutputModalKey,
            eOutputModalItem: item
        });
    }

    closeEnergyInputDialog() {
        this.setState({ eInputModalIsOpen: false });
    }

    closeEnergyOutputDialog() {
        this.setState({ eOutputModalIsOpen: false });
    }

    getElementData(preview) {
        let { fatValue, carbohydratValue, proteinValue, fiberValue, sodiumValue, satFatValue,
            fatLevel, carbohydratLevel, proteinLevel, fiberLevel, sodiumLevel, satFatLevel,
            fatRate, carbohydratRate, proteinRate, fiberRate, sodiumRate, satFatRate,
            carbohydratGoodLow, carbohydratGoodHigh, fatGoodLow, fatGoodHigh, proteinGoodLow,
            proteinGoodHigh } = preview || {};
        let result = {
            fat: {
                value: fatValue,
                level: fatLevel,
                rate: fatRate,
                gl: fatGoodLow,
                gh: fatGoodHigh
            },
            carbohydrat: {
                value: carbohydratValue,
                level: carbohydratLevel,
                rate: carbohydratRate,
                gl: carbohydratGoodLow,
                gh: carbohydratGoodHigh
            },
            protein: {
                value: proteinValue,
                level: proteinLevel,
                rate: proteinRate,
                gl: proteinGoodLow,
                gh: proteinGoodHigh
            },
            fiber: {
                value: fiberValue,
                level: fiberLevel,
                rate: fiberRate
            },
            sodium: {
                value: sodiumValue,
                level: sodiumLevel,
                rate: sodiumRate
            },
            satFat: {
                value: satFatValue,
                level: satFatLevel,
                rate: satFatRate
            }
        };
        return result;
    }

    getDayData(eInputs) {
        let result = [1, 1, 1, 1, 1].map(_ => ({ value: false, energy: 0 }));
        (eInputs || []).forEach(o => {
            if (o.foodTypeId > 0 && o.foodTypeId < 6) {
                result[o.foodTypeId - 1].value = true;
                result[o.foodTypeId - 1].energy += o.foodEnergy * o.quantity / 100;
            }
        })
        return result;
    }

    render() {
        //let { translate } = this.context;
        let { activities, date, energyOutputs, foodItems, foodTypes, eInputs, foodCategories,
            eInputModalIsOpen, eInputModalKey, eInputModalItem,
            eOutputModalIsOpen, eOutputModalKey, eOutputModalItem, preview, progressDate } = this.state;
        let { shares, selectedShare, onShareSelect } = this.props;

        let { energyInput, energyOutput, hasSomeItems, basalOutput, plan, lifeStyleOutput } = preview || {};
        let elementData = this.getElementData(preview);
        let dayData = this.getDayData(eInputs);

        let shareOptions = arraySort(shares, 'label');
        let showShares = !!(shares && shares.length);
        let asUser = (selectedShare || {}).id || '';

        // console.log('shares', shares);

        return (
            <div className="overview-page">
                <div className="wrap-container reverse">
                    <div className="overview-col">
                        <DateNavigator date={date} onChange={date => this.changeDate(date)} />
                    </div>
                    <div className="overview-col">
                        {showShares &&
                            <div className="share-select-container">
                                <Select
                                    classNamePrefix="share-select"
                                    options={shareOptions}
                                    value={selectedShare}
                                    onChange={item => onShareSelect(item)}
                                    isSearchable={true}
                                    styles={transparentStyle}
                                    isClearable={false}
                                    components={{
                                        IndicatorSeparator: () => null
                                    }}
                                />
                            </div>
                        }
                    </div>
                </div>
                <div className="wrap-container">
                    <div className="overview-col">
                        <EnergyProgressBar
                            input={energyInput}
                            output={energyOutput}
                            basalOutput={basalOutput}
                            lifeStyleOutput={lifeStyleOutput}
                            showDiff={hasSomeItems}
                            plan={plan}
                            date={progressDate}
                            loaded={!!preview}
                        />
                        <ElementGraph data={elementData} />
                        <DayOverview
                            data={dayData}
                            onClick={this.dayTypeClick}
                        />
                    </div>
                    <div className="overview-col">
                        <DataList
                            type={LT_MENU}
                            eInputs={eInputs}
                            foodTypes={foodTypes}
                            onEnergyInput={this.openEnergyInputDialog}
                            showSum
                        />
                        <DataList
                            type={LT_ACTIVITY}
                            eOutputs={energyOutputs}
                            onEnergyOutput={this.openEnergyOutputDialog}
                        />
                        <EnergyOutputDialog
                            key={eOutputModalKey}
                            isOpen={eOutputModalIsOpen}
                            activities={activities}
                            date={date}
                            data={eOutputModalItem}
                            onClose={this.closeEnergyOutputDialog}
                            onSubmit={() => {
                                this.loadData();
                                this.closeEnergyOutputDialog();
                            }}
                            asUser={asUser}
                        />
                        <EnergyInputDialog
                            key={eInputModalKey}
                            isOpen={eInputModalIsOpen}
                            foodTypes={foodTypes}
                            foodItems={foodItems}
                            categories={foodCategories}
                            date={date}
                            data={eInputModalItem}
                            onClose={this.closeEnergyInputDialog}
                            onSubmit={() => {
                                this.loadData();
                                this.closeEnergyInputDialog();
                            }}
                            asUser={asUser}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

Home.contextType = AppContext;

export default Home;