import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-virtualized';
import Tippy from '@tippyjs/react';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import api from '../../utils/api';
import { apply } from '../../utils/validation';
import { defaultStyle } from '../../utils/style';
import { isApiError } from '../../utils/error';
import { tippyPopperOptions } from '../../utils/tippy';
import { AppContext } from '../../contexts/AppContext';

import 'tippy.js/dist/tippy.css';
import { rsGetNullValue } from '../../utils/reactSelect';


const BT_CALC = 1;
const BT_VAL = 2;

class Profile extends Component {

    static propsTypes = {
        selectedShare: PropTypes.object
    }

    constructor(props, context) {
        super(props);

        this.state = {
            data: null,
            userName: '',
            name: '',
            lifeStyle: null,
            basalType: this.getBasalType(context.translate, BT_CALC),
            basalVal: '',
            weight: '',
            height: '',
            birthYear: '',
            gender: null,
            errors: {}
        };

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadProfile();
    }

    async loadProfile() {
        let { translate } = this.context;
        let { selectedShare } = this.props;

        try {
            let asUser = (selectedShare || {}).id || '';
            let response = await api.getProfile({ asUser });
            let data = response.data || {};
            this.applyData(data);
        } catch (error) {
            if (!isApiError(error)) {
                console.log(error);
            }
            toastr.error(translate('Err.System'));
        }
    }

    applyData(data) {
        let { translate } = this.context;
        let { userName, lifeStyle, weight, height, birdYear, gender, basalType, basalValue, name } = data;

        this.setState({
            data,
            userName: userName || '',
            lifeStyle: lifeStyle ? this.getLifeStyle(translate, lifeStyle) : null,
            basalType: this.getBasalType(translate, basalType || BT_CALC),
            basalVal: basalValue || '',
            weight: weight || '',
            height: height || '',
            birthYear: birdYear || '',
            gender: gender ? this.getGender(translate, gender) : null,
            name: name || ''
        });
    }

    validate() {
        let { weight, height, birthYear, basalVal, basalType } = this.state;

        let errors = {};

        apply(errors, 'Weight', 'Invalid', !weight || weight > 0);
        apply(errors, 'Height', 'Invalid', !height || height > 0);
        apply(errors, 'Birth', 'Invalid', !birthYear || birthYear > 0);
        apply(errors, 'Basal', 'Required', basalType.value !== BT_VAL || !!basalVal);
        apply(errors, 'Basal', 'Invalid', !basalVal || basalVal > 0);

        let result = !Object.keys(errors).length;

        this.setState({ errors });
        return result;
    }

    validateIfError() {
        if (this.state.errors) {
            this.validate();
        }
    }

    async save() {
        if (this.validate()) {
            let { birthYear, gender, height, lifeStyle, weight, basalType, basalVal, name } = this.state;
            let { translate } = this.context;
            let { selectedShare } = this.props;

            try {
                let asUser = (selectedShare || {}).id || '';
                let requestData = {
                    birthYear: birthYear ? parseFloat(birthYear) : null,
                    gender: gender ? gender.value : null,
                    height: height ? parseFloat(height) : null,
                    lifeStyle: lifeStyle ? lifeStyle.value : null,
                    weight: weight ? parseFloat(weight) : null,
                    basalType: basalType ? basalType.value : null,
                    basalValue: basalVal ? parseFloat(basalVal) : null,
                    asUser,
                    name: name || null
                };
                let response = await api.setProfile(requestData);
                let data = response.data || {};
                this.applyData(data);
            } catch (error) {
                if (!isApiError(error)) {
                    console.log(error);
                }
                toastr.error(translate('Err.System'));
            }
        }
    }

    getLifeStyle(translate, value) {
        return { label: translate(`Profile.LifeStyle.${value}`), value };
    }

    getLifeStyleOptions(translate) {
        let result = [
            rsGetNullValue(),
            this.getLifeStyle(translate, 1),
            this.getLifeStyle(translate, 2),
            this.getLifeStyle(translate, 3),
            this.getLifeStyle(translate, 4)
        ];
        return result;
    }

    getGender(translate, value) {
        return { label: translate(`Profile.Gender.${value}`), value };
    }

    getGenderOptions(translate) {
        let result = [
            this.getGender(translate, 1),
            this.getGender(translate, 2)
        ];
        return result;
    }

    getBasalType(translate, value) {
        return { label: translate(`Profile.Basal.${value}`), value };
    }

    getBasalOptions(translate) {
        let result = [
            this.getBasalType(translate, BT_CALC),
            this.getBasalType(translate, BT_VAL)
        ];
        return result;
    }

    render() {
        let { translate } = this.context;
        let { basalType, userName, lifeStyle, weight, height, birthYear, gender, basalVal, errors, name } = this.state;

        let lifestyleOptions = this.getLifeStyleOptions(translate);
        let basalOptions = this.getBasalOptions(translate);
        let genderOptions = this.getGenderOptions(translate);

        let showBasalVal = (basalType || {}).value === BT_VAL;
        let showCalcVal = (basalType || {}).value === BT_CALC;

        return (
            <div className="profile-settings">
                <div className="panel-header">{translate('Profile.Title')}</div>
                <Form>
                    <FormGroup>
                        <Label>{translate('Profile.Email')}</Label>
                        <Input
                            value={userName}
                            onChange={() => { }}
                            disabled
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Profile.Username')}</Label>
                        <Input
                            value={name}
                            onChange={e => this.setState({ name: e.target.value })}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Tippy
                            content={
                                <div className="indent-list">
                                    <div>{translate('Profile.LifeStyle.NoneTooltip')}</div>
                                    <div>{translate('Profile.LifeStyle.SedentaryTooltip')}</div>
                                    <div>{translate('Profile.LifeStyle.LowActiveTooltip')}</div>
                                    <div>{translate('Profile.LifeStyle.ActiveTooltip')}</div>
                                    <div>{translate('Profile.LifeStyle.VeryActiveTooltip')}</div>
                                </div>
                            }
                            popperOptions={tippyPopperOptions}
                            allowHTML={true}
                        >
                            <span>
                                <Label>
                                    <span>{translate('Profile.LifeStyle.Label')}</span>
                                    <span> <i className="fa fa-info-circle" aria-hidden="true"></i></span>
                                </Label>
                            </span>
                        </Tippy>
                        <Select
                            options={lifestyleOptions}
                            value={lifeStyle}
                            onChange={lifeStyle => this.setState({ lifeStyle })}
                            placeholder={''}
                            styles={defaultStyle()}
                            noOptionsMessage={() => translate('Selectbox.NoOptions')}
                            isClearable={false}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Tippy
                            content={translate('Profile.BasalTooltip')}
                            popperOptions={tippyPopperOptions}
                        >
                            <span>
                                <Label>
                                    <span>{translate('Profile.Basal.Label')}</span>
                                    <span> <i className="fa fa-info-circle" aria-hidden="true"></i></span>
                                </Label>
                            </span>
                        </Tippy>
                        <Select
                            options={basalOptions}
                            value={basalType}
                            onChange={basalType => this.setState({ basalType }, () => this.validateIfError())}
                            placeholder={''}
                            styles={defaultStyle()}
                            noOptionsMessage={() => translate('Selectbox.NoOptions')}
                            isClearable={false}
                        />
                    </FormGroup>
                    {showBasalVal &&
                        <FormGroup>
                            <Label>{translate('Profile.BasalValue')}</Label>
                            <Input
                                type="number"
                                value={basalVal}
                                onChange={e => this.setState({ basalVal: e.target.value }, () => this.validateIfError())}
                                invalid={errors && !!errors.Basal}
                            />
                            <FormFeedback>{translate(`Profile.Err.Basal${(errors || {}).Basal}`)}</FormFeedback>
                        </FormGroup>
                    }
                    <FormGroup>
                        <Tippy
                            content={translate('Profile.WeightTooltip')}
                            popperOptions={tippyPopperOptions}
                        >
                            <span>
                                <Label>
                                    <span>{translate('Profile.Weight')}</span>
                                    <span> <i className="fa fa-info-circle" aria-hidden="true"></i></span>
                                </Label>
                            </span>
                        </Tippy>
                        <Input
                            type="number"
                            value={weight}
                            onChange={e => this.setState({ weight: e.target.value }, () => this.validateIfError())}
                            invalid={errors && !!errors.Weight}
                        />
                        <FormFeedback>{translate(`Profile.Err.Weight${(errors || {}).Weight}`)}</FormFeedback>
                    </FormGroup>
                    {showCalcVal &&
                        <FormGroup>
                            <Label>{translate('Profile.Height')}</Label>
                            <Input
                                type="number"
                                value={height}
                                onChange={e => this.setState({ height: e.target.value }, () => this.validateIfError())}
                                invalid={errors && !!errors.Height}
                            />
                            <FormFeedback>{translate(`Profile.Err.Height${(errors || {}).Height}`)}</FormFeedback>
                        </FormGroup>
                    }
                    {showCalcVal &&
                        <FormGroup>
                            <Label>{translate('Profile.Year')}</Label>
                            <Input
                                type="number"
                                value={birthYear}
                                onChange={e => this.setState({ birthYear: e.target.value }, () => this.validateIfError())}
                                invalid={errors && !!errors.Birth}
                            />
                            <FormFeedback>{translate(`Profile.Err.Birth${(errors || {}).Birth}`)}</FormFeedback>
                        </FormGroup>
                    }
                    {showCalcVal &&
                        <FormGroup>
                            <Label>{translate('Profile.Gender.Label')}</Label>
                            <Select
                                options={genderOptions}
                                value={gender}
                                onChange={gender => this.setState({ gender })}
                                placeholder={''}
                                styles={defaultStyle()}
                                noOptionsMessage={() => translate('Selectbox.NoOptions')}
                            />
                        </FormGroup>
                    }
                    <div className="buttons">
                        <Button color="primary" className="wide" onClick={this.save}>{translate('Btn.Save')}</Button>
                    </div>
                </Form>
            </div >
        );
    }
}

Profile.contextType = AppContext;

export default Profile;