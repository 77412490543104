import classnames from 'classnames';
import React, { useState } from 'react';


const NavSubMenu = ({ title, children }) => {
    const [collapse, setCollapse] = useState(false);

    return (
        <div className="submenu-container">
            <div
                className="menu-title nav-link text-dark"
                onClick={() => setCollapse(!collapse)}
            >{title}</div>
            <ul className={classnames('submenu-items navbar-nav flex-grow', { collapse })} onClick={() => setCollapse(!collapse)}>{children}</ul>
        </div>
    );
}

export default NavSubMenu;