import * as toastr from 'toastr';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from "react";
import Select from 'react-select';

import api from '../../utils/api';
import EduItem from "./EduItem";
import MenuDesign from './MenuDesign';
import { toOption } from '../../utils/options';
import { transparentStyle } from "../../utils/style";
import { AppContext } from "../../contexts/AppContext";

import './education.scss';


class Education extends Component {

    static propsTypes = {
        isAdmin: PropTypes.bool
    }

    constructor() {
        super()

        this.state = {
            features: [],
            featureOptions: []
        }
    }

    componentDidMount() {
        this.loadFeatures();
    }

    async loadFeatures() {
        try {
            let response = await api.getFeatures();
            let featureOptions = (response.data || []).map(toOption);
            this.setState({ featureOptions });
        } catch (error) {
            console.log(error);
        }
    }

    async downloadList() {
        let { translate } = this.context;
        let { features } = this.state;

        let featureList = (features || []).map(o => o.value).join(',');
        try {
            let params = { features: featureList || undefined };
            let response = await api.exportFoods(params);

            var type = response.headers['content-type'] || 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([response.data], { type });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = featureList
                ? `${translate('Edu.FoodFeature.Title')} ${features.map(o => o.label).join(', ')}.xlsx`
                : `${translate('Edu.FoodFeature.Title')}.xlsx`;
            document.body.appendChild(link);
            link.click();

        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    render() {
        const { translate } = this.context;
        const { isAdmin } = this.props;
        let { features, featureOptions } = this.state;

        return (
            <div className="education-container">
                <div className="education-content">
                    <div className="panel-header">{translate('Edu.Recomend.Title')}</div>
                    <div className="edu-items">
                        <div className="doc-item"><a href="/doc/doc001.pdf" target="_blank" rel="noopener noreferrer" download="Obsah draslíku v potravinách.pdf">{'Obsah draslíku v potravinách.pdf'}</a></div>
                        <div className="doc-item"><a href="/doc/doc002.pdf" target="_blank" rel="noopener noreferrer" download="Obsah kalcia v potravinách.pdf">{'Obsah kalcia v potravinách.pdf'}</a></div>
                        <div className="doc-item"><a href="/doc/doc003.pdf" target="_blank" rel="noopener noreferrer" download="Obsah kyseliny listové v potravinách.pdf">{'Obsah kyseliny listové v potravinách.pdf'}</a></div>
                        <div className="doc-item"><a href="/doc/doc004.pdf" target="_blank" rel="noopener noreferrer" download="Obsah vitaminu D v potravinách.pdf">{'Obsah vitaminu D v potravinách.pdf'}</a></div>
                        <div className="doc-item"><a href="/doc/doc005.pdf" target="_blank" rel="noopener noreferrer" download="Obsah železa v potravinách.pdf">{'Obsah železa v potravinách.pdf'}</a></div>
                    </div>
                    {isAdmin &&
                        <Fragment>
                            <div className="panel-header">{translate('Edu.FoodFeature.Title')}</div>
                            <div className="edu-items">
                                <div className="input-container">
                                    <div className="input-label">{translate('Edu.FoodFeature.Feature')}:</div>
                                    <Select
                                        className="select"
                                        classNamePrefix="share-select"
                                        options={featureOptions}
                                        value={features}
                                        onChange={features => this.setState({ features })}
                                        styles={transparentStyle}
                                        isClearable={false}
                                        components={{
                                            IndicatorSeparator: () => null
                                        }}
                                        isMulti
                                        placeholder={translate('Select.Placeholder')}
                                    />
                                </div>
                                <div className="export-report">
                                    <div
                                        className='report-btn'
                                        onClick={() => this.downloadList()}
                                    >{translate('Edu.FoodFeature.Download')}</div>
                                </div>
                            </div>
                            <MenuDesign
                                featureOptions={featureOptions}
                            />
                        </Fragment>
                    }
                    <div className="panel-header">{translate('Edu.Title')}</div>
                    <div className="edu-items">
                        <EduItem
                            title={translate('Edu.Sportv.Title')}
                            description={translate('Edu.Sportv.Description')}
                            link={'https://www.sportvitalpro.cz/'}
                        />
                        <EduItem
                            title={translate('Edu.Mze.Title')}
                            description={translate('Edu.Mze.Description')}
                            link={'http://eagri.cz/public/web/mze/potraviny/'}
                        />
                        <EduItem
                            title={translate('Edu.Bezpo.Title')}
                            description={translate('Edu.Bezpo.Description')}
                            link={'https://bezpecnostpotravin.cz/'}
                        />
                        <EduItem
                            title={translate('Edu.Cojis.Title')}
                            description={translate('Edu.Cojis.Description')}
                            link={'https://www.viscojis.cz/'}
                        />
                        <EduItem
                            title={translate('Edu.Nutrib.Title')}
                            description={translate('Edu.Nutrib.Description')}
                            link={'https://www.nutridatabaze.cz/'}
                        />
                        <EduItem
                            title={translate('Edu.Spolpv.Title')}
                            description={translate('Edu.Spolpv.Description')}
                            link={'http://www.vyzivaspol.cz/'}
                        />
                        <EduItem
                            title={translate('Edu.Usda.Title')}
                            description={translate('Edu.Usda.Description')}
                            link={'https://fdc.nal.usda.gov/'}
                        />
                        <EduItem
                            title={translate('Edu.Pubmed.Title')}
                            description={translate('Edu.Pubmed.Description')}
                            link={'https://pubmed.ncbi.nlm.nih.gov/'}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

Education.contextType = AppContext;

export default Education;