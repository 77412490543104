import _ from 'lodash';
import * as toastr from 'toastr';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Select from 'react-select-virtualized';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';

import api from '../../utils/api';
import { defaultStyle } from '../../utils/style';
import { AppContext } from '../../contexts/AppContext';
import { getYesNo, getYesNoOptions } from '../../utils/codelist';
import TranslationControl, { getTranslationValue } from '../TranslationControl/TranslationControl';


export default class ActivityDetail extends Component {

    static contextType = AppContext;

    static propTypes = {
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        data: PropTypes.object
    }

    constructor(props, context) {
        super(props);

        let data = props.data || {};
        this.state = {
            translations: data.translations || [],
            energyExpenditure50kg: _.get(props, 'data.energyExpenditure50kg') || null,
            energyExpenditure71kg: _.get(props, 'data.energyExpenditure71kg') || null,
            energyExpenditure92kg: _.get(props, 'data.energyExpenditure92kg') || null,
            deleted: getYesNo(context.translate, _.get(props, 'data.deleted') || false),
            languages: ['cs', 'en']
        }

        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadData();
    }

    async loadData() {
        let { translate } = this.context;
        let { data } = this.props;

        try {
            let actions = [api.getLanguages()];
            if (data.id) {
                actions.push(api.GetActivity(data.id));
            } 
            let [langResponse, activityResponse] = await Promise.all(actions);

            let state = {
                languages: langResponse.data || []
            };
            if (activityResponse) {
                let { translations, deleted, energyExpenditure50kg, energyExpenditure71kg, energyExpenditure92kg } = activityResponse.data || {};
                deleted = getYesNo(translate, deleted === true);
                state = { ...state, translations, energyExpenditure50kg, energyExpenditure71kg, energyExpenditure92kg, deleted}
            }
            this.setState(state);
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    toggle() {
        let { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    async save() {
        let { translations, energyExpenditure50kg, energyExpenditure71kg, energyExpenditure92kg, deleted } = this.state;
        let { onSubmit, data } = this.props;
        let { translate } = this.context;

        try {
            translations = translations.filter(o => !!o.name);
            let requestData = {
                name: getTranslationValue(translations, 'name'),
                translations: translations,
                energyExpenditure50kg: parseFloat(energyExpenditure50kg) || 0,
                energyExpenditure71kg: parseFloat(energyExpenditure71kg) || 0,
                energyExpenditure92kg: parseFloat(energyExpenditure92kg) || 0,
                deleted: deleted.value
            };
            let response = data.id
                ? await api.updateActivity(data.id, requestData)
                : await api.addActivity(requestData);
            if (onSubmit) {
                onSubmit(response.data);
            }
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    render() {
        let { translate } = this.context;
        let { translations, languages, deleted, energyExpenditure50kg, energyExpenditure71kg, energyExpenditure92kg } = this.state;

        //console.log('names', names);

        return (
            <Modal isOpen={true} toggle={this.toggle} autoFocus={false}>
                <ModalHeader toggle={this.toggle}>{translate('ActivityAdmin.ActivityDetail')}</ModalHeader>
                <ModalBody>
                    <Form>
                        <TranslationControl
                            label={translate('ActivityAdmin.Name')}
                            name="name"
                            languages={languages}
                            translations={translations}
                            onChange={translations => this.setState({ translations })}
                            autoFocus
                        />
                        <FormGroup>
                            <Label>{translate('ActivityAdmin.Energy50')}</Label>
                            <Input
                                type="number"
                                value={energyExpenditure50kg}
                                onChange={e => this.setState({ energyExpenditure50kg: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('ActivityAdmin.Energy71')}</Label>
                            <Input
                                type="number"
                                value={energyExpenditure71kg}
                                onChange={e => this.setState({ energyExpenditure71kg: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('ActivityAdmin.Energy92')}</Label>
                            <Input
                                type="number"
                                value={energyExpenditure92kg}
                                onChange={e => this.setState({ energyExpenditure92kg: e.target.value })}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('ActivityAdmin.Deleted')}</Label>
                            <Select
                                options={getYesNoOptions(translate)}
                                value={deleted}
                                onChange={deleted => this.setState({ deleted })}
                                placeholder=" "
                                isClearable={false}
                                isSearchable={false}
                                styles={defaultStyle()}
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>{translate('Btn.Save')}</Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>{translate('Btn.Cancel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}
