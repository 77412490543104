export const tippyPopperOptions = {
    strategy: 'fixed',
    modifiers: [
        {
            name: 'computeStyles',
            options: {
                adaptive: false,
            },
        },
    ],
}