import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { AppContext } from '../../contexts/AppContext';


class SimpleTab extends Component {

    static contextType = AppContext;

    static propTypes = {
        cols: PropTypes.array,
        data: PropTypes.array,
        onEdit: PropTypes.func,
        onAdd: PropTypes.func,
        onCopy: PropTypes.func,
        onExport: PropTypes.func,
        exporting: PropTypes.bool
    }

    render() {
        let { translate, language } = this.context;
        let { cols, data, onEdit, onAdd, onCopy, canCopy, onExport, exporting } = this.props;

        cols = cols || [];
        data = data || [];

        let showEdit = !!onEdit;
        let showCopy = !!onCopy;
        let showAdd = !!onAdd;
        let showExport = !!onExport;
        let showToolText = !showAdd && cols.length && !cols[cols.length - 1].key;

        return (
            <div className="simple-tab">
                <table>
                    <colgroup>
                        {cols.map((o, i) => {
                            return (
                                <col key={i} style={o.style || {}} />
                            );
                        })}
                    </colgroup>
                    <thead>
                        <tr>
                            {cols.filter(o => !!o.key).map((o, i) => {
                                return (
                                    <th key={i}>{o.name}</th>
                                );
                            })}
                            <th className="tool-cell">
                                <div className="tools">
                                    {showAdd &&
                                        <div
                                            className="tool"
                                            onClick={() => onAdd()}
                                        ><i className="fa fa-plus" aria-hidden="true"></i></div>
                                    }
                                    {showExport &&
                                        <div
                                            className="tool"
                                            onClick={() => onExport()}
                                        ><i className={exporting ? 'fa fa-spinner fa-spin' : 'fa fa-file-export'} aria-hidden="true"></i></div>
                                    }
                                    {showToolText &&
                                        <div className={classnames({ nowrap: cols[cols.length - 1].nowrap })}>{cols[cols.length - 1].name}</div>
                                    }
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, i) => {
                            let copyDisabled = canCopy && !canCopy(item);

                            return (
                                <tr key={i}>
                                    {cols.filter(o => !!o.key).map((col, j) => {
                                        let value = item[col.key];
                                        if (col.valueType) {
                                            switch (col.valueType) {
                                                case 'bool':
                                                    value = item[col.key] === true ? translate('Global.Yes') : translate('Global.No')
                                                    break;
                                                case 'number':
                                                    if (typeof value === 'number') {
                                                        if (value !== null && value !== undefined) {
                                                            if (col.round) {
                                                                value = Math.round(value);
                                                            }
                                                            value = value.toLocaleString(language);
                                                        }
                                                    }
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        if (col.getValue) {
                                            value = col.getValue(item);
                                        }

                                        return (
                                            <td className={classnames(col.valueType, col.cellClass)} key={j}>{value}</td>
                                        );
                                    })}
                                    {(showEdit || showCopy) &&
                                        <td className="tool-cell">
                                            <div className="tools">
                                                {showEdit &&
                                                    <div
                                                        className="tool"
                                                        onClick={() => onEdit(item)}
                                                    ><i className="fa fa-pencil" aria-hidden="true"></i></div>
                                                }
                                                {showCopy &&
                                                    <div
                                                        className={classnames('tool', { disabled: copyDisabled })}
                                                        onClick={() => { if (!copyDisabled) onCopy(item); }}
                                                    ><i className="fa fa-copy" aria-hidden="true"></i></div>
                                                }
                                            </div>
                                        </td>
                                    }
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div >
        );
    }
}

export default SimpleTab;