import _ from 'lodash';
import * as toastr from 'toastr';
import classnames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, Form, FormGroup, Label, Input, ModalFooter, Button, FormFeedback } from 'reactstrap';
import Select from 'react-select-virtualized';

import api from '../../utils/api';
import { apply } from '../../utils/validation';
import { arraySort } from '../../utils/sort';
import { /*categoryStyle,*/ defaultStyle } from '../../utils/style';
//import { filterCiAi } from '../../utils/filter';
import { isError, ERR_WEIGHT_NOT_FOUND } from '../../utils/error';
import { AppContext } from '../../contexts/AppContext';
import { DefaultWeight, EnergyWeigthCoefValue, EnergyKjKcalCoef } from '../../constants/energy';
import { filterCiAi } from '../../utils/filter';


class EnergyOutputDialog extends Component {

    static propTypes = {
        isOpen: PropTypes.bool,
        activities: PropTypes.array,
        onClose: PropTypes.func,
        onSubmit: PropTypes.func,
        date: PropTypes.object,
        data: PropTypes.object,
        asUser: PropTypes.string
    }

    constructor(props) {
        super(props);

        this.state = {
            activity: this.initActivity(props.data, props.activities),
            time: moment(`${moment(props.date).startOf('date').format('YYYY-MM-DD')}T${moment().startOf('minute').format('HH:mm:ss')}`),
            duration: (props.data || {}).duration || 30,
            category: null,
            errors: null,
            weight: DefaultWeight
        }

        this.toggle = this.toggle.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
    }

    initActivity(eOutput, activities) {
        let result = null;
        console.log('initActivity', { eOutput, activities });
        if (eOutput && eOutput.activityId && activities) {
            result = this.getOption(_.find(activities, { id: eOutput.activityId }));

            if (!result) {
                result = {
                    id: eOutput.activityId,
                    value: eOutput.activityId,
                    name: eOutput.activityName,
                    label: eOutput.activityName
                };
            }
        }

        return result;
    }

    componentDidMount() {
        this.loadWeight();
    }

    async loadWeight() {
        const { asUser } = this.props;

        try {
            let response = await api.getUserWeight({ date: this.state.time.format('YYYY-MM-DD'), asUser });
            let weight = response.data.weight || DefaultWeight;
            this.setState({ weight });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    getActivityOptions(activities, activity) {
        let result = (activities || []).filter(o => o.deleted !== true).map(this.getOption);
        // console.log('activity', activities);
        if (activity && !_.some(result, { value: activity.activityId })) {
            result.push({
                id: activity.activityId,
                value: activity.activityId,
                name: activity.activityName,
                label: activity.activityName
            });
        }
        arraySort(result, 'name');
        return result;
    }

    getOption(item) {
        if (item) {
            return {
                ...item,
                value: item.id,
                label: item.name
            };
        }

        return item;
    }

    toggle() {
        let { onClose } = this.props;

        if (onClose) {
            onClose();
        }
    }

    async save() {
        if (this.validate()) {
            let { activity, time, duration } = this.state;
            let { onSubmit, data, asUser } = this.props;
            let { translate } = this.context;

            try {
                let requestData = {
                    activityId: (activity || {}).id,
                    time: time.toISOString(true),
                    duration,
                    asUser
                };
                let response = data
                    ? await api.updateEnergyOutput(data.id, requestData)
                    : await api.addEnergyOutput(requestData);
                if (onSubmit) {
                    onSubmit(response.data);
                }
            } catch (error) {
                if (isError(error, ERR_WEIGHT_NOT_FOUND)) {
                    toastr.warning(translate('Err.NoWeight'));
                } else {
                    console.log(error);
                    toastr.error(translate('Err.System'));
                }
            }
        }
    }

    async delete() {
        let { translate } = this.context;
        let { onSubmit, data, asUser } = this.props;

        if (!data) {
            return;
        }

        let confirm = window.confirm(translate('EnergyOutput.DeleteConfirm'));
        if (confirm) {
            try {
                let response = await api.deleteEnergyOutput(data.id, { asUser });
                toastr.success(translate('EnergyOutput.DeleteSuccess'));

                if (onSubmit) {
                    onSubmit(response.data);
                }
            } catch (error) {
                console.log(error);
                toastr.error(translate('Err.System'));
            }
        }
    }

    validate() {
        let { activity, duration, errors } = this.state;

        errors = {};

        apply(errors, 'Activity', 'Required', !!activity);
        apply(errors, 'Duration', 'Required', duration > 0);

        let result = !Object.keys(errors).length;
        //console.log('validation', { errors, foodItemId, amount, result });

        this.setState({ errors });
        return result;
    }

    getCategoryOptions(activities) {
        return [];
    }

    changeCategory(category) {

    }

    getEnergyText(activity, duration, weight, language) {
        let result = '';

        if (activity) {
            let coef = weight <= EnergyWeigthCoefValue ? activity.coefLower : activity.coefUpper;
            // console.log('activita', weight);
            let energy = (activity.energyExpenditure71kg + (weight - EnergyWeigthCoefValue) * coef) * EnergyKjKcalCoef;
            if (energy >= 0) {
                let hEnergy = Math.round(energy * 60);
                result = `${hEnergy.toLocaleString(language)} kJ/h`;

                let resEnergy = Math.round(energy * duration);
                if (resEnergy >= 0) {
                    result = `${resEnergy.toLocaleString(language)} kJ | ${result}`;
                }
            }
        }

        return result;
    }

    render() {
        let { isOpen, activities, data } = this.props;
        let { translate, language } = this.context;
        let { activity, duration, weight, /*category,*/ errors } = this.state;

        let activityOptions = this.getActivityOptions(activities, data);
        //let categoryOptions = this.getCategoryOptions(activities);
        let energyText = this.getEnergyText(activity, duration, weight, language);
        let showDelete = !!data;
        let autoFocus = !data;
        let isActivityInvalid = errors && errors.Activity;

        return (
            <Modal isOpen={isOpen} toggle={this.toggle} className="" autoFocus={false}>
                <ModalHeader toggle={this.toggle}>{translate('EnergyOutput.Title')}</ModalHeader>
                <ModalBody>
                    <Form>
                        {/*
                        <FormGroup>
                            <Label>{translate('EnergyOutput.Category')}</Label>
                            <Select
                                options={categoryOptions}
                                value={category}
                                onChange={this.changeCategory}
                                placeholder={translate('ActivityCategory.All')}
                                styles={categoryStyle}
                                isClearable={false}
                                noOptionsMessage={() => translate('Selectbox.NoOptions')}
                                filterOption={filterCiAi}
                            />
                        </FormGroup>
                        */}
                        <FormGroup>
                            <Label>{translate('EnergyOutput.Activity')}</Label>
                            <Select
                                options={activityOptions}
                                value={activity}
                                onChange={activity => this.setState({ activity }, () => {
                                    if (errors) {
                                        this.validate();
                                    }
                                })}
                                placeholder=" "
                                autoFocus={autoFocus}
                                isClearable={false}
                                className={classnames({ 'is-invalid': isActivityInvalid })}
                                styles={defaultStyle({ isInvalid: isActivityInvalid })}
                                noOptionsMessage={() => translate('Selectbox.NoOptions')}
                                filterOption={filterCiAi}
                            />
                            <FormFeedback>{translate(`EnergyOutput.Err.Activity${(errors || {}).Activity}`)}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('EnergyOutput.Duration')}</Label>
                            <Input
                                type="number"
                                value={duration}
                                onChange={e => this.setState({ duration: parseInt(e.target.value) }, () => {
                                    if (errors) {
                                        this.validate();
                                    }
                                })}
                                invalid={errors && !!errors.Duration}
                            />
                            <FormFeedback>{translate(`EnergyOutput.Err.Duration${(errors || {}).Duration}`)}</FormFeedback>
                        </FormGroup>
                        <FormGroup>
                            <Label>{translate('EnergyOutput.Energy')}</Label>
                            <Input
                                value={energyText || ''}
                                onChange={() => { }}
                                disabled
                            />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.save}>{translate('Btn.Save')}</Button>{' '}
                    {showDelete &&
                        <Button color="warning" onClick={this.delete}>{translate('Btn.Delete')}</Button>
                    }
                    <Button color="secondary" onClick={this.toggle}>{translate('Btn.Cancel')}</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

EnergyOutputDialog.contextType = AppContext;

export default EnergyOutputDialog;