var typeOf = require('kind-of');
var get = require('get-value');

/**
 * Sort an array of objects by one or more properties.
 *
 * @param  {Array} `arr` The Array to sort.
 * @param  {String|Array|Function} `props` One or more object paths or comparison functions.
 * @param  {Object} `opts` Pass `{ reverse: true }` to reverse the sort order.
 * @return {Array} Returns a sorted array.
 * @api public
 */

export function arraySort(arr, props) {
    if (arr == null) {
        return [];
    }

    if (!Array.isArray(arr)) {
        throw new TypeError('array-sort expects an array.');
    }

    if (arguments.length === 1) {
        return arr.sort();
    }

    var args = flatten([].slice.call(arguments, 1));

    // if the last argument appears to be a plain object,
    // it's not a valid `compare` arg, so it must be options.
    //   if (typeOf(args[args.length - 1]) === 'object') {
    //     opts = args.pop();
    //   }
    return arr.sort(sortBy(args, {}));
}

/**
 * Iterate over each comparison property or function until `1` or `-1`
 * is returned.
 *
 * @param  {String|Array|Function} `props` One or more object paths or comparison functions.
 * @param  {Object} `opts` Pass `{ reverse: true }` to reverse the sort order.
 * @return {Array}
 */

function sortBy(props, opts) {
    opts = opts || {};

    return function compareFn(a, b) {
        var len = props.length, i = -1;
        var result;

        while (++i < len) {
            result = compare(props[i], a, b);
            if (result !== 0) {
                break;
            }
        }

        return result;
    };
}

/**
 * Compare `a` to `b`. If an object `prop` is passed, then
 * `a[prop]` is compared to `b[prop]`
 */

function compare(prop, a, b) {
    var opts = {}
    if (typeof prop === 'object') {
        opts = { ...prop, prop: null };
        prop = prop.prop;
    }

    if (typeof prop === 'function') {
        // expose `compare` to custom function
        return prop(a, b, compare.bind(null, null));
    }

    // compare object values
    if (prop && typeOf(a) === 'object' && typeOf(b) === 'object') {
        return compare(opts, get(a, prop), get(b, prop));
    }

    // compare property array values
    if (prop && typeOf(a) === 'array' && typeOf(b) === 'array') {
        return compare(opts, getFromPropArray(a, prop), getFromPropArray(b, prop));
    }

    const result = defaultCompare(a, b, opts);
    return result;
}

export function getFromPropArray(propArray, key, keyPropName = 'key', valuePropName = 'value') {
    if (!Array.isArray(propArray))
        return undefined;
    for (var i = 0; i < propArray.length; i++) {
        if (propArray[i][keyPropName] === key)
            return propArray[i][valuePropName];
    }

    return undefined;
}

/**
 * Flatten the given array.
 */

function flatten(arr) {
    return [].concat.apply([], arr);
}

function defaultCompare(a, b, prop) {
    var typeA = typeOf(a);
    var typeB = typeOf(b);

    if (typeA === 'string' && a.trim() === '') {
        typeA = 'emptyString';
    }
    if (typeB === 'string' && b.trim() === '') {
        typeB = 'emptyString';
    }

    const order = prop.order === 'desc' ? -1 : 1;
    const norder = prop.nullAlign === 'top' ? -1 : 1;
    const uorder = prop.undefAlign === 'top' ? -1 : 1;
    const esorder = prop.emptyStringAlign === 'top' ? -1 : 1;

    switch (typeA) {
        case 'emptyString':
            switch (typeB) {
                case 'emptyString': return 0;
                case 'null': return -norder;
                case 'undefined': return -uorder;
                default: return esorder;
            }
        case 'null':
            switch (typeB) {
                case 'null': return 0;
                case 'undefined': return -uorder;
                default: return norder;
            }
        case 'undefined':
            switch (typeB) {
                case 'undefined': return 0;
                default: return uorder;
            }
        default:
            switch (typeB) {
                case 'null': return -norder;
                case 'undefined': return -uorder;
                case 'emptyString': return -esorder;
                default:
                    const res = typeA === 'string' ? a.localeCompare(b) : (a < b ? -1 : (a > b ? 1 : 0));
                    return res * order;
            }
    }
};
