import i18next from 'i18next';
import _ from 'lodash';

import csTranslation from './cs';
import enTranslation from './en';

function merge(original, extension) {
	if (original && extension && typeof original == 'object' && typeof extension == 'object') {
		return _.merge(original, extension);
	}

	return original;
}

export default function init(language) {
	let cs = csTranslation;

	let en = merge(JSON.parse(JSON.stringify(cs)), enTranslation);
	//console.log('init i18n', { csTranslation, cs, en, language });
	i18next.init({
		lng: language || window.language,
		resources: {
			cs: { translation: cs },
			en: { translation: en }
		}
	});

	return i18next;
}