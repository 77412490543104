import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link, Redirect, Route } from 'react-router-dom';
import { AppContext } from '../../contexts/AppContext';
import UserAdmin from "./UserAdmin";
import ActivityAdmin from './ActivityAdmin';
import FoodAdmin from './FoodAdmin';
//import RuleAdmin from './RuleAdmin';
import RecipeAdmin from './RecipeAdmin';
import ReportAdmin from './ReportAdmin';


export const ActivityAdminPath = '/admin/activity';
export const FoodAdminPath = '/admin/food';
export const RecipeAdminPath = '/admin/recipe';
export const RuleAdminPath = '/admin/rule';
export const UserAdminPath = '/admin/user';
export const ReportAdminPath = '/admin/report';

class Admin extends Component {

    static propsTypes = {
        isAdmin: PropTypes.bool
    }

    render() {
        let { translate } = this.context;
        let { pathname } = window.location;
        let { isAdmin } = this.props;

        if (isAdmin === false) {
            return (
                <Redirect to="/" />
            );
        }

        return (
            <div className="page-content section-container">
                <div className="section-menu">
                    <div className="panel-header">{translate('Admin.Title')}</div>
                    <ul className="nav nav-pills flex-column">
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === ActivityAdminPath })}
                                to={ActivityAdminPath}
                            >{translate('ActivityAdmin.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === FoodAdminPath })}
                                to={FoodAdminPath}
                            >{translate('FoodAdmin.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === RecipeAdminPath })}
                                to={RecipeAdminPath}
                            >{translate('RecipeAdmin.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === ReportAdminPath })}
                                to={ReportAdminPath}
                            >{translate('ReportAdmin.Link')}</NavLink>
                        </NavItem>
                        {/*
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === RuleAdminPath })}
                                to={RuleAdminPath}
                            >{translate('RuleAdmin.Link')}</NavLink>
                        </NavItem>*/}
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className={classnames({ active: pathname === UserAdminPath })}
                                to={UserAdminPath}
                            >{translate('UserAdmin.Link')}</NavLink>
                        </NavItem>
                    </ul>
                </div>
                <div className="section-content">
                    <Route path={ActivityAdminPath} component={ActivityAdmin} />
                    <Route path={FoodAdminPath} component={FoodAdmin} />
                    <Route path={RecipeAdminPath} component={RecipeAdmin} />
                    <Route path={ReportAdminPath} component={ReportAdmin} />
                    {/*
                    <Route path={RuleAdminPath} component={RuleAdmin} />
                    */}
                    <Route path={UserAdminPath} component={UserAdmin} />
                </div>
            </div >
        );
    }
}

Admin.contextType = AppContext;

export default Admin;