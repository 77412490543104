import moment from 'moment';
import React, { forwardRef } from 'react';
import { FormFeedback, Input } from 'reactstrap';

const CustomDpInput = ({ onClick, value, invalid, error }, ref) => (
    <>
        <Input
            className="date-input"
            value={value ? moment(value).format('DD. MM. YYYY') : ''}
            onChange={() => { }}
            onClick={onClick}
            ref={ref}
            invalid={invalid}
            readOnly={true}
        />
        <FormFeedback>{error}</FormFeedback>
    </>
);

export default forwardRef(CustomDpInput);