import * as toastr from 'toastr';
import React, { useContext, useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input } from 'reactstrap';
import Select from 'react-select';

import api from '../../utils/api';
import { AppContext } from '../../contexts/AppContext';

import './featureModal.scss';
import * as _ from 'lodash';
import { defaultStyle } from '../../utils/style';
import { toOption } from '../../utils/options';


const paramsFilter = [
    3, // BÍLKOVINY CELKOVÉ(g) 
    51, // DRASLÍK(mg) 
    70, // ENERGIE(kJ) 
    302, // ETYLALKOHOL(g) 
    47, // HOŘČÍK(mg) 
    42, // CHOLESTEROL(mg) 
    88, // KYS.LISTOVÁ(ug) 
    39, // MONONENASYCENÉ MK(g) 
    38, // NASYCENÉ MK(g) 
    40, // POLYNENASYCENÉ MK(g) 
    44, // SACHARIDY CELKOVÉ(g) 
    119, // SACHAROZA(g) 
    46, // SODÍK(mg) 
    127, // TUKY(g) 
    377, // Tuky rostlinného původu(g) 
    376, // Tuky živočišného původu(g) 
    52, // VÁPNÍK(mg) 
    61, // VITAMIN A(ug) 
    62, // VITAMIN B1(mg) 
    65, // VITAMIN B12(ug) 
    63, // VITAMIN B2(mg) 
    67, // VITAMIN B6(mg) 
    110, // VITAMIN C(mg) 
    341, // VITAMIN D(ug) 
    363, // VITAMIN E(mg) 
    118, // VLÁKNINA(g) 
    2, // VODA CELKOVÁ(ml) 
    54 // ŽELEZO(mg) 
];

const FeatureModal = ({ languages, onClose, onSubmit, params }) => {

    const { translate } = useContext(AppContext);

    const [features, setFeatures] = useState([]);
    const [compositionOptions, setCompositionOptions] = useState((params || []).filter(o => paramsFilter.includes(o.id)).map(toOption));

    useEffect(() => {
        loadFeatures();
    }, []);

    const loadFeatures = async () => {
        try {
            let response = await api.getFeatureList();
            let features = (response.data || []).map(o => ({ ...o, compositions: (o.compositions || []).reduce((r, c) => {
                let co = _.find(compositionOptions, { value: c });
                console.log('co', { co, c, compositionOptions });
                return co ? [...r, co] : r;
            }, []) }));
            console.log('features', features);
            setFeatures(features);
        } catch (error) {
            console.log(error);
        }
    }

    const toggle = () => {
        if (onClose) {
            onClose();
        }
    }

    const save = async () => {
        try {
            let data = features
                .filter(feature => !!feature.id || !!(feature.translations || []).filter(translation => !!translation.name).length)
                .map(o => ({ ...o, compositions: (o.compositions || []).map(c => c.id)}));
            let response = await api.updateFeatureList(data);
            if (onSubmit) {
                onSubmit(response.data);
            }
        } catch (error) {
            console.log(error);
            toastr.error(translate('Err.System'));
        }
    }

    return (
        <Modal isOpen={true} toggle={toggle} autoFocus={false} className="feature-modal">
            <ModalHeader toggle={toggle}>{translate('Feature.Title')}</ModalHeader>
            <ModalBody>
                <table className="feature-table">
                    <thead>
                        <tr>
                            {languages.map(lang => (<th key={lang}>{lang}</th>))}
                            <th>{translate('Feature.Composition')}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {features.map((feature, i) => {
                            return (
                                <tr key={i}>
                                    {languages.map((lang, j) => {
                                        let name = (_.find(feature.translations || [], { code: lang }) || {}).name || '';
                                        return (
                                            <td key={lang}>
                                                <Input
                                                    value={name}
                                                    onChange={e => {
                                                        console.log(e.target.value);
                                                        feature.translations = feature.translations || [];
                                                        let item = _.find(feature.translations, { code: lang });
                                                        if (!item) {
                                                            item = { code: lang, name: e.target.value };
                                                            feature.translations.push(item);
                                                        } else {
                                                            item.name = e.target.value;
                                                        }
                                                        setFeatures([...features]);
                                                    }}
                                                    autoFocus={j === 0}
                                                />
                                            </td>
                                        );
                                    })}
                                    <td>
                                        <Select
                                            classNamePrefix="composition-select"
                                            options={compositionOptions}
                                            value={feature.compositions || []}
                                            onChange={compositions => setFeatures([...(features.map((o, j) => i === j ? { ...o, compositions } : o))])}
                                            styles={defaultStyle()}
                                            isClearable={false}
                                            isMulti
                                            placeholder=' '
                                        />
                                    </td>
                                    <td>
                                        <div
                                            className="btn-plus"
                                            onClick={() => {
                                                let confirm = window.confirm(translate('Feature.RemoveConfirm'));
                                                if (confirm) {
                                                    features.splice(i, 1);
                                                    setFeatures([...features]);
                                                }
                                            }}
                                        ><i className="fa fa-minus" aria-hidden="true"></i></div>
                                    </td>
                                </tr>
                            );
                        })}
                        <tr>
                            <td colSpan="3">
                                <div
                                    className="btn-plus"
                                    onClick={() => setFeatures([...features, { translations: [] }])}
                                ><i className="fa fa-plus" aria-hidden="true"></i></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={save}>{translate('Btn.Save')}</Button>{' '}
                <Button color="secondary" onClick={toggle}>{translate('Btn.Cancel')}</Button>
            </ModalFooter>
        </Modal>
    );
}

export default FeatureModal;