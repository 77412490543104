import * as toastr from 'toastr';
import React, { Component } from 'react';

import api from '../../utils/api';
import { AppContext } from '../../contexts/AppContext';
import SimpleTab from '../SimpleTab/SimpleTab';
import UserDetail from './UserDetail';
import { sub } from '../../utils/sub';


class UserAdmin extends Component {

    constructor() {
        super();
        this.state = {
            users: [],
            detail: null
        }

        this.onEdit = this.onEdit.bind(this);
    }

    componentDidMount() {
        this.loadUsers();
    }

    async loadUsers() {
        let { translate } = this.context;

        try {
            let response = await api.getUsers();
            let users = (response.data || []).map(o => ({
                ...o,
                role: o.isAdmin ? translate('UserAdmin.RoleAdmin') : translate('UserAdmin.RoleUser')
            }));
            this.setState({ users });
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    onEdit(item) {
        let detail = { ...item, ts: new Date().getTime() };
        this.setState({ detail });
    }

    render() {
        let { translate } = this.context;
        let { users, detail } = this.state;

        let showDetail = !!detail;
        let shareCount = users.filter(o => o.isShared).length;
        let subOptions = {
            count: users.length,
            shared: shareCount
        };

        return (
            <div className="user-admin">
                <div className="panel-header">{translate('UserAdmin.Title')}</div>
                <SimpleTab
                    cols={[
                        { key: 'email', name: translate('UserAdmin.Email'), style: { width: '40%' } },
                        { key: 'name', name: translate('UserAdmin.Name'), style: { width: '40%' } },
                        { key: 'role', name: translate('UserAdmin.Role'), style: { width: '20%' } },
                        { key: '', name: sub(translate('UserAdmin.Count'), subOptions), nowrap: true }
                    ]}
                    data={users}
                    onEdit={this.onEdit}
                />

                {showDetail &&
                    <UserDetail
                        key={detail.ts}
                        data={detail}
                        onClose={() => this.setState({ detail: null })}
                        onSubmit={() => {
                            this.loadUsers();
                            this.setState({ detail: null });
                        }}
                    />
                }
            </div>
        );
    }
}

UserAdmin.contextType = AppContext;

export default UserAdmin;