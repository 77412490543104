import PropTypes from 'prop-types';
import React, { Component } from "react";
import { AppContext } from "../../contexts/AppContext";
import classnames from 'classnames';

export const ICT_TEXT = 'text';
export const ICT_TIME = 'time';
export const ICT_CELL = 'cell';
export const ICT_CELL_TEXT = 'cete';
export const ICT_ICO = 'ico';

class ItemCell extends Component {

    static propTypes = {
        value: PropTypes.any,
        type: PropTypes.string,
        name: PropTypes.string,
        unit: PropTypes.string
    }

    getTimeText(value) {
        let min = value || 0;
        let hour = 0;

        if (min >= 60) {
            hour = Math.floor(min / 60);
            min = min % 60;
        }

        let result = [hour ? this.getUnitText(hour, 'h') : '', min ? this.getUnitText(min, 'm') : ''].filter(o => !!o).join(' ');
        return result;
    }

    getUnitText(value, unit) {
        if (value === null || value === undefined || value === '') {
            return '';
        }

        let result = unit ? `${value} ${unit}` : `${value}`;
        return result;
    }

    render() {
        let { translate } = this.context;
        let { type, value, name, unit, customName } = this.props;
        //console.log('name', name);

        switch (type) {
            case ICT_CELL:
                return (
                    <div className="item-cell named-cell">
                        <div className="item-cell-name">{(customName || translate(name)).toUpperCase()}</div>
                        <div className="item-cell-value">{this.getUnitText(value, unit)}</div>
                    </div>
                );
            case ICT_TIME:
                return (
                    <div className="item-cell named-cell">
                        <div className="item-cell-name">{translate('DataList.Time').toUpperCase()}</div>
                        <div className="item-cell-value">{this.getTimeText(value)}</div>
                    </div>
                );
            case ICT_CELL_TEXT:
                return (
                    <div className="item-cell named-cell text-cell">
                        <div className="item-cell-name">{(customName || translate(name)).toUpperCase()}</div>
                        <div className="item-cell-value">{value}</div>
                    </div>
                );
            case ICT_ICO:
                return (
                    <div
                        className={classnames('item-cell ico-cell', value)}
                        title={translate(name)}
                    ><i className={classnames(`fa fa-${value}`)} aria-hidden="true"></i></div>
                );
            case ICT_TEXT:
            default:
                return (
                    <div className="item-cell simple-cell">{value}</div>
                );
        }
    }
}

ItemCell.contextType = AppContext;

export default ItemCell;