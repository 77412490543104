import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import { AppContext } from '../../contexts/AppContext';
import NavSubMenu from '../NavMenu/NavSubMenu';
import { ExternalPath, PasswordPath, PersonalPath, PlanPath, ProfilePath, SharePath } from '../Settings/Settings';
import { ActivityAdminPath, FoodAdminPath, RecipeAdminPath, ReportAdminPath, UserAdminPath } from '../Admin/Admin';

export class LoginMenu extends Component {
    static contextType = AppContext;

    static propsTypes = {
        isAdmin: PropTypes.bool,
        selectedShare: PropTypes.object
    }

    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        this.setState({
            isAuthenticated,
            userName: user && user.name
        });
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    authenticatedView(userName, profilePath, logoutPath) {
        let { translate } = this.context;
        let { isAdmin, selectedShare } = this.props; // collapsed

        let showNotShared = !selectedShare || !selectedShare.id;

        return (<Fragment>
            <NavItem className="for-mobile">
                <NavSubMenu
                    //key={collapsed}
                    className="text-dark"
                    title={translate('Settings.Link')}
                >
                    <NavItem>
                        <NavLink
                            tag={Link}
                            className="text-dark"
                            to={ProfilePath}
                            onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                        >{translate('Profile.Link')}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            tag={Link}
                            className="text-dark"
                            to={PlanPath}
                            onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                        >{translate('Plan.Link')}</NavLink>
                    </NavItem>
                    {showNotShared &&
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={PasswordPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('Password.Link')}</NavLink>
                        </NavItem>
                    }
                    {showNotShared &&
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={SharePath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('Share.Link')}</NavLink>
                        </NavItem>
                    }
                    {showNotShared &&
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={ExternalPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('ExtLogin.Link')}</NavLink>
                        </NavItem>
                    }
                    {showNotShared &&
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={PersonalPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('Personal.Link')}</NavLink>
                        </NavItem>
                    }
                </NavSubMenu>
                {isAdmin &&
                    <NavSubMenu
                        //key={collapsed}
                        className="text-dark"
                        title={translate('Admin.Link')}
                    >
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={ActivityAdminPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('ActivityAdmin.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={FoodAdminPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('FoodAdmin.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={RecipeAdminPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('RecipeAdmin.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={ReportAdminPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('ReportAdmin.Link')}</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                className="text-dark"
                                to={UserAdminPath}
                                onClick={o => { if (this.props.onClick) this.props.onClick(); }}
                            >{translate('UserAdmin.Link')}</NavLink>
                        </NavItem>
                    </NavSubMenu>
                }
            </NavItem>
            <NavItem className="for-desktop">
                <NavLink tag={Link} className="text-dark" to={ProfilePath} onClick={o => { if (this.props.onClick) this.props.onClick(); }}>{translate('Settings.Link')}</NavLink>
                {isAdmin &&
                    <NavLink tag={Link} className="text-dark" to={ActivityAdminPath} onClick={o => { if (this.props.onClick) this.props.onClick(); }}>{translate('Admin.Link')}</NavLink>
                }
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath} onClick={o => { if (this.props.onClick) this.props.onClick(); }}>{translate('Logout.Link')}</NavLink>
            </NavItem>
        </Fragment>);

    }

    anonymousView(registerPath, loginPath) {
        let { translate } = this.context;

        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>{translate('Login.Link')}</NavLink>
            </NavItem>
        </Fragment>);
    }
}
