import React, { forwardRef } from 'react';

const DateInput = ({ onClick, text }, ref) => (
    <div className="date-input-container">
        <div
            className="date-input-value"
            onClick={onClick}
        >{text}</div>
    </div>
);

export default forwardRef(DateInput);