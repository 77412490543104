//import _ from 'lodash';
import classnames from 'classnames';
import moment from 'moment'
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import DateInput from './DateInput';
import { AppContext } from '../../contexts/AppContext';

import 'moment/min/locales'

import './dateNavigator.scss';


import DatePicker, { registerLocale } from "react-datepicker";
import cs from "date-fns/locale/cs"; // the locale you want
registerLocale('cs', cs); // register it with the name you want


class DateNavigator extends Component {

    static propTypes = {
        date: PropTypes.object,
        onChange: PropTypes.func
    }

    constructor() {
        super();

        this.prev = this.prev.bind(this);
        this.next = this.next.bind(this);
        this.today = this.today.bind(this);
    }

    prev() {
        let { date } = this.props;

        this.change(moment(date).startOf('date').subtract(1, 'days'));
    }

    next() {
        let { date } = this.props;

        this.change(moment(date).startOf('date').add(1, 'days'));
    }

    today() {
        this.change(moment().startOf('date'));
    }

    change(value) {
        let { onChange } = this.props;

        if (onChange) {
            onChange(value);
        }
    }

    getDateText(date, now, today, language) {
        date.locale(language);
        let result = date.format('ddd D. M. YYYY');
        if (date.isSame(today)) {
            result = `${this.context.translate('DateNavigator.TodayPrefix')}${now.format('HH:mm')}`;
        }
        return result;
    }

    render() {
        let { date } = this.props;
        let { translate, language } = this.context;

        date = moment(date).startOf('date');
        let now = moment();
        let today = moment().startOf('date');
        let disableNext = date.isSameOrAfter(today);
        let showToday = date.isBefore(today);
        let dateText = this.getDateText(date, now, today, language);

        //console.log('locale', language);

        return (
            <div className="date-navigator-containder">
                <div
                    className="date-navigator-btn"
                    onClick={this.prev}
                ><i className="fa fa-chevron-left" aria-hidden="true"></i></div>
                <DatePicker
                    selected={date.toDate()}
                    onChange={date => this.change(moment(date).startOf('date'))}
                    customInput={
                        <DateInput text={dateText} />
                    }
                    maxDate={today.toDate()}
                    locale={language}
                />
                {showToday &&
                    <div
                        className="date-navigator-btn"
                        onClick={this.today}
                    >{translate('DateNavigator.Today')}</div>
                }
                <div
                    className={classnames('date-navigator-btn', { disabled: disableNext })}
                    onClick={() => {
                        if (!disableNext) {
                            this.next();
                        }
                    }}
                ><i className="fa fa-chevron-right" aria-hidden="true"></i></div>
            </div>
        );
    }
}

DateNavigator.contextType = AppContext;

export default DateNavigator;