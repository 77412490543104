import * as toastr from 'toastr';
import moment from 'moment'
import DatePicker, { registerLocale } from "react-datepicker";
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Button, Form, FormFeedback, FormGroup, Input, Label } from 'reactstrap';

import api from '../../utils/api';
import { apply } from '../../utils/validation';
import { AppContext } from '../../contexts/AppContext';

import cs from "date-fns/locale/cs"; // the locale you want
import CustomDpInput from './CustomDpInput';
import { ERR_INVALID_PLAN_DATE, isError } from '../../utils/error';
import { isNumber } from 'lodash';

registerLocale('cs', cs); // register it with the name you want


class Plan extends Component {

    static propsTypes = {
        selectedShare: PropTypes.object
    }

    constructor() {
        super();

        this.state = {
            lastDate: null,
            curWeight: null,
            planWeight: null,
            planDate: null,
            energy: null,
            errors: null,
            data: null
        };

        this.save = this.save.bind(this);
    }

    componentDidMount() {
        this.loadPlan();
    }

    async loadPlan() {
        let { selectedShare } = this.props;

        try {
            let asUser = (selectedShare || {}).id || '';
            let response = await api.getPlan({ asUser });
            let data = response.data || {};
            this.applyData(data);
        } catch (error) {
            console.log(error);
            toastr.error(this.context.translate('Err.System'));
        }
    }

    applyData(data) {
        let { lastWeightDate, currentWeight, planWeight, planDate, planBalance } = data;
        this.setState({
            data,
            lastDate: lastWeightDate ? moment(lastWeightDate) : null,
            curWeight: currentWeight || null,
            planWeight: planWeight || null,
            planDate: planDate ? moment(planDate) : null,
            energy: isNumber(planBalance) ? planBalance : null
        });
    }

    calculateEnergy(curWeight, planWeight, planDate) {
        if (curWeight > 0 && planWeight > 0 && planDate) {
            let duration = moment.duration(planDate.diff(moment().startOf('day'))).asDays();
            if (duration > 0) {
                return (planWeight - curWeight) * 38780 / duration;
            }
        }

        return null;
    }

    validate() {
        let { curWeight, planWeight, planDate, errors } = this.state;

        errors = {};

        //energy = this.calculateEnergy(curWeight, planWeight, planDate);
        //apply(errors, 'Energy', 'Bad', !(energy < -2770 || energy > 2770));

        apply(errors, 'CurWeight', 'Required', curWeight > 0);
        apply(errors, 'PlanWeight', 'Required', planWeight > 0);
        apply(errors, 'PlanDate', 'Required', !!planDate);

        let result = !Object.keys(errors).length;

        this.setState({ errors });
        return result;
    }

    validateIfError() {
        if (this.state.errors) {
            this.validate();
        }
    }

    async save() {
        if (this.validate()) {
            let { curWeight, planWeight, planDate } = this.state;
            let { translate } = this.context;
            let { selectedShare } = this.props;

            try {
                let asUser = (selectedShare || {}).id || '';
                let requestData = {
                    setWeight: null,
                    currentWeight: parseFloat(curWeight) > 0 ? parseFloat(curWeight) : null,
                    planWeight: parseFloat(planWeight) > 0 ? parseFloat(planWeight) : null,
                    planDate: planDate ? planDate.toISOString(true) : null,
                    asUser
                };
                let response = await api.setPlan(requestData);
                let data = response.data || {};
                this.applyData(data);
            } catch (error) {
                if (isError(error, ERR_INVALID_PLAN_DATE)) {
                    toastr.warning(translate('Plan.Err.InvalidDate'));
                } else {
                    console.log(error);
                    toastr.error(translate('Err.System'));
                }
            }
        }
    }

    render() {
        let { translate, language } = this.context;
        let { lastDate, curWeight, planWeight, planDate, energy, errors, data } = this.state;

        let energyText = energy == null ? '' : `${Math.round(energy).toLocaleString(language)} kJ`;
        let energyError = energy < -2770;
        let lastDateValue = lastDate && curWeight === data.currentWeight ? lastDate.format('DD. MM. YYYY') : '';

        //console.log('energy', energy, energyText);

        return (
            <div className="plan-settings">
                <div className="panel-header">{translate('Plan.Title')}</div>
                <Form>
                    <FormGroup>
                        <Label>{translate('Plan.LastWeightDate')}</Label>
                        <Input
                            value={lastDateValue}
                            onChange={() => { }}
                            disabled
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Plan.CurrentWeight')}</Label>
                        <Input
                            type="number"
                            value={curWeight || ''}
                            onChange={e => this.setState({ curWeight: e.target.value }, () => this.validateIfError())}
                            invalid={errors && !!errors.CurWeight}
                        />
                        <FormFeedback>{translate(`Plan.Err.CurWeight${(errors || {}).CurWeight}`)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Plan.PlanWeight')}</Label>
                        <Input
                            type="number"
                            value={planWeight || ''}
                            onChange={e => this.setState({ planWeight: e.target.value }, () => this.validateIfError())}
                            invalid={errors && !!errors.PlanWeight}
                        />
                        <FormFeedback>{translate(`Plan.Err.PlanWeight${(errors || {}).PlanWeight}`)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Plan.PlanDate')}</Label>
                        <DatePicker
                            selected={planDate ? planDate.toDate() : null}
                            onChange={date => this.setState({ planDate: moment(date).startOf('date') }, () => this.validateIfError())}
                            customInput={
                                <CustomDpInput
                                    invalid={errors && !!errors.PlanDate}
                                    error={translate(`Plan.Err.PlanDate${(errors || {}).PlanDate}`)}
                                />
                            }
                            wrapperClassName="form-control"
                            minDate={moment().startOf('day').add(1, 'days').toDate()}
                            locale={language}
                        />
                        <FormFeedback>{translate(`Plan.Err.PlanDate${(errors || {}).PlanDate}`)}</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label>{translate('Plan.EnergyBalance')}</Label>
                        <Input
                            value={energyText}
                            onChange={() => { }}
                            disabled
                            invalid={energyError}
                        />
                        <FormFeedback>{translate('Plan.Err.EnergyBadMinus')}</FormFeedback>
                    </FormGroup>
                    <div className="buttons">
                        <Button color="primary" className="wide" onClick={this.save}>{translate('Plan.Save')}</Button>
                    </div>
                </Form>
            </div >
        );
    }
}

Plan.contextType = AppContext;

export default Plan;