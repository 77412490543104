import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Label } from 'reactstrap';
import Resizer from "react-image-file-resizer"

import './imgControl.scss';


export default class ImgControl extends Component {

    static propsTypes = {
        label: PropTypes.string,
        images: PropTypes.array,
        maxCount: PropTypes.number,
        onChange: PropTypes.func,
        maxSize: PropTypes.number // Maximální velikost fotky [MB] (default 6MB)
    }

    static defaultProps = {
        maxSize: 6
    }

    loadPhoto(e) {
        let { disabled, readonly, maxSize, images } = this.props;

        if (disabled || readonly) {
            return;
        }

        let files = e.target.files || e.dataTransfer.files;
        if (files && files.length) {
            let maxInsertImageFileSize = maxSize * 1024 * 1024; // 6MB
            let allowedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp', 'image/gif'];

            let newPhotos = [];
            for (let i = 0; i < 1; i++) { // for (let i = 0; i < files.length; i++) {
                let file = files[i];

                if (allowedTypes.indexOf(file.type) < 0) {
                    console.log('Unsupported file type', file);
                    alert('Unsupported file type');
                    continue;
                }
                if (file.size > maxInsertImageFileSize) {
                    console.log('Max file size exceeded', file);
                    alert('Max file size exceeded');
                    continue;
                }

                Resizer.imageFileResizer(
                    file, // the file from input
                    2016, // width
                    2016, // height
                    "JPEG", // compress format WEBP, JPEG, PNG
                    50, // quality
                    0, // rotation
                    (uri) => {
                        console.log(`Image compressed: ${file.size} -> ${uri.size}`);

                        let photo = {
                            file: uri,
                            imgUrl: URL.createObjectURL(uri),
                            new: true,
                            orig: file,
                            previewUrl: URL.createObjectURL(file)
                        };
                        this.invokeChange([...images, photo]);
                    },
                    "blob" // blob or base64 default base64
                );
            }
        }
    }

    remove(img) {
        let { images } = this.props;

        if (img) {
            if (img.new) {
                this.invokeChange(images.filter(o => o != img));
            } else {
                this.invokeChange(images.map(o => {
                    if (o == img) {
                        return { ...o, remove: true };
                    } else {
                        return o;
                    }
                }))
            }
        }
    }

    invokeChange(images) {
        const { onChange } = this.props;

        if (onChange) {
            onChange(images);
        }
    }

    render() {
        let { label, maxCount, images } = this.props;

        let imgs = (images || []).filter(o => !o.remove);

        let canAdd = maxCount ? imgs.length < maxCount : true;

        return (
            <FormGroup className="img-control-container">
                <Label>{label || ''}</Label>
                {imgs.map((img, i) => {
                    return (
                        <div className="img-item-container" key={i}>
                            <div className="img-container">
                                <img
                                    src={img.imgUrl}
                                    alt={img.alt || 'Image'}
                                    onClick={() => {
                                        window.open(img.previewUrl || img.imgUrl, "_blank");
                                    }}
                                // onContextMenu={e => this.onContextMenu(e, i)}
                                />
                            </div>
                            <div
                                className="rem-btn"
                                onClick={() => this.remove(img)}
                            ><i className="fa fa-minus" aria-hidden="true"></i></div>
                        </div>
                    );
                })}
                {canAdd &&
                    <div className="btn-container">
                        <div
                            className='add-btn'
                            onClick={() => {
                                if (this.photoInput) {
                                    this.photoInput.click();
                                    this.photoInput.value = null;
                                }
                            }}
                        >
                            <input
                                name="photo"
                                type="file"
                                ref={e => this.photoInput = e}
                                accept="image/gif,image/jpeg,image/png,image/bmp"
                                onChange={e => this.loadPhoto(e)}
                                onClick={e => e.stopPropagation()}
                            />
                            <i className="fa fa-plus" />
                        </div>
                    </div>
                }
            </FormGroup>
        );
    }
}