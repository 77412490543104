export const ERR_SYSTEM = "err_system";
export const ERR_WEIGHT_NOT_FOUND = "err_weight_not_found";
export const ERR_ACTIVITY_NOT_FOUND = "err_activity_not_found";
export const ERR_INVALID_ID = "err_invalid_id";
export const ERR_INVALID_USER_ID = "err_invalid_user_id";
export const ERR_USER_NOT_FOUND = "err_user_not_found";
export const ERR_INVALID_CURRENT_WEIGHT = "err_invalid_current_weight";
export const ERR_INVALID_PLAN_WEIGHT = "err_invalid_plan_weight";
export const ERR_INVALID_PLAN_DATE = "err_invalid_plan_date";
export const ERR_BAD_CURRENT_PWD = "err_bad_current_pwd";
export const ERR_INVALID_NEW_PWD = "err_invalid_new_pwd";
export const ERR_EXT_LOGIN_NOT_REMOVED = "err_ext_login_not_removed";

export function logErrors(error, method, path, id) {
    let result = isApiError(error);
    if (result) {
        console.log(`error ${method} ${path}`, id, error.response.data.errors);
    }
    return result;
}

export function isError(error, code) {
    let result = isApiError(error) &&
        error.response.data.errors.includes(code);
    return result;
}

export function isApiError(error) {
    let result = error &&
        error.response &&
        error.response.status === 400 &&
        error.response.data &&
        error.response.data.errors &&
        error.response.data.errors.length;
    return result;
}
