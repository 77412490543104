import moment from 'moment';
import React, { forwardRef } from 'react';

const DateInput = ({ value, onClick }, ref) => (
    <div
        className="date-input-container"
        onClick={onClick}
    >
        <span><i className="fa fa-calendar" aria-hidden="true"></i></span>
        <span className="date-input-value">{moment(value).format('D. M. YYYY')}</span>
    </div>
);

export default forwardRef(DateInput);