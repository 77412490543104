export const FC_ALL = 0;
export const FC_FAVORITE = -1;
export const FC_OTHER = -2;

export function getFoodCategory(id, translate) {
    let name = 'Unknown';
    switch (id) {
        case FC_ALL:
            name = 'FoodCategory.All';
            break;
        case FC_FAVORITE:
            name = 'FoodCategory.Favorite';
            break;
        case FC_OTHER:
            name = 'FoodCategory.Other';
            break;
        default:
            break;
    }
    if (translate) {
        name = translate(name);
    }
    let result = { id, name, value: id, label: name };
    return result;
}